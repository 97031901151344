import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { LoadingService } from 'projects/shared/src/app/common/loading/services/loading.service';
import { PERMISSIONS } from 'projects/shared/src/app/constants/constants';


import { zip } from 'rxjs';
import { UserModalTab } from 'projects/shared/src/app/constants/user/user-modal-tabs';
import { UserSecurity } from 'projects/shared/src/app/models/user/user/user-security.model';
import { UserService } from 'projects/shared/src/app/services/user/user/user.service';
import { SelectedUserService } from 'projects/shared/src/app/services/user/user/selected-user.service';

@Component({
  selector: 'app-study-security-tab',
  templateUrl: './study-security-tab.component.html',
  styleUrls: ['./study-security-tab.component.css']
})
export class StudySecurityTabComponent implements OnInit {
  TAB = UserModalTab.StudySecurity;

  currentUserSecurityInfo: any;
  isEditMode: boolean = false;
  permissionsList: any;
  studySecurityForm: FormGroup;
  userSecurityList: UserSecurity[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private userService: UserService,
    private selectedUserService: SelectedUserService) {

    this.permissionsList = PERMISSIONS;
    this.studySecurityForm = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.setUpUserSecurity();
  }

  onCancel(): void {
    this.studySecurityForm.setValue(this.currentUserSecurityInfo);
    this.toggleEditMode(false);
  }

  onSave(): void {
    const formValue = this.studySecurityForm.value;

    const newUserSecurityInfo = this.userSecurityList.filter(u => u.IsModified).map(user => {
      return {
        SponsorContactUserSecurityId: user.SponsorContactUserSecurityId,
        ContactId: this.selectedUserService.UserId,
        OtherSponsorContactId: user.OtherSponsorContactId,
        Permission: formValue[user.OtherSponsorContactId],
      };
    });

    this.saveUserSecurity(newUserSecurityInfo);
  }
  onRadioChange(security: UserSecurity): void {
    security.IsModified = true;
  }
  cleanModified(): void {
    this.userSecurityList.forEach(userSecurity => {
      userSecurity.IsModified = false;
    });
  }
  toggleEditMode(enabled: boolean): void {
    this.isEditMode = enabled;
    enabled ? this.enableStudySecurityForm() : this.studySecurityForm.disable();
  }

  private buildStudySecurityForm(): void {
    this.userSecurityList.forEach(userPermission => {
      this.studySecurityForm.addControl(
        userPermission.OtherSponsorContactId.toString(),
        this.formBuilder.control(userPermission.Permission));
    });

    this.currentUserSecurityInfo = this.studySecurityForm.value;
  }

  private enableStudySecurityForm(): void {
    this.userSecurityList.forEach(userPermission => {
      (userPermission.RoleId > 1 && userPermission.OtherSponsorContactId !== this.selectedUserService.UserId) &&
        this.studySecurityForm.get(userPermission.OtherSponsorContactId.toString()).enable();
    });
  }

  private saveUserSecurity(newUserSecurityInfo: any[]): void {
    this.loadingService.waitObservableWithSpinner(
      zip(
         this.userService.SaveUserSecurityInfo(this.selectedUserService.UserId,newUserSecurityInfo),
         this.userService.SaveUserSecurityInfoForAnyStudy(newUserSecurityInfo , this.selectedUserService.UserId))
      ).subscribe (_ => {
        this.studySecurityForm.enable();
        this.currentUserSecurityInfo = this.studySecurityForm.value;

        this.userSecurityList.forEach(userPermission => {
          userPermission.Permission = this.currentUserSecurityInfo[userPermission.OtherSponsorContactId.toString()]
        });
        this.toggleEditMode(false);
      });
      this.cleanModified();
  }

  private setUpUserSecurity(): void {
    this.loadingService.waitObservableWithSpinner(
      this.userService.GetUserSecurityById(this.selectedUserService.UserId))
      .subscribe(response => {
        this.userSecurityList = response.data;
        this.buildStudySecurityForm();
        this.toggleEditMode(false);
      });
  }
}
