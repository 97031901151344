import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {

  @Input () numbersOfRecordsToShow:  Array<string>=[];
  @Input () recordslength:number;
  @Input () currentPage:number;
  @Output() paramsToGetUsers = new EventEmitter<any>();

  totalPages = 1;
  pageRangeMin = 1
  pageRangeMax = 1
  currentNumberOfRows = 1


  constructor() { }

  ngOnInit() {
    this.recordslength = this.recordslength == null ? 0 : this.recordslength
    this.currentNumberOfRows = this.numbersOfRecordsToShow[0] == null ? 1 : parseInt(this.numbersOfRecordsToShow[0])
    this.pageRangeMax = this.numbersOfRecordsToShow[0] == null ? 1 : parseInt(this.numbersOfRecordsToShow[0])
    
  }
  ngOnChanges(): void {
    this.setPaginationNumbers(this.currentPage, false);
  }
  onChangeNumberOfRowsSelect(event){
    const value = event.target.value;
    this.totalPages = Math.ceil(this.recordslength / this.currentNumberOfRows)
    this.currentNumberOfRows = value
    this.currentPage = 1
    this.setPaginationNumbers(this.currentPage)
  }

  onEnterGoToPageInput(event){
    let value = event.target.value;
    this.totalPages = Math.ceil(this.recordslength / this.currentNumberOfRows)
    value = value === null || value === "" ?  this.pageRangeMin : value
    value = value > this.totalPages ? this.totalPages : value
    value = value <= 0 ? 1 : value
    this.currentPage = value

    this.setPaginationNumbers(this.currentPage)
  }

  onAddPage(){
    this.totalPages = Math.ceil(this.recordslength / this.currentNumberOfRows)
    if(this.currentPage < this.totalPages){
      this.currentPage++
      this.setPaginationNumbers(this.currentPage)
    }  
  }

  onReducePage(){
    this.totalPages = Math.ceil(this.recordslength / this.currentNumberOfRows)
    if(this.currentPage > 1){
      this.currentPage--
      this.setPaginationNumbers(this.currentPage)
    }  
  }

  setPaginationNumbers(newGoToPage, sendPaginatorParams = true){
    this.totalPages = Math.ceil(this.recordslength / this.currentNumberOfRows)

    var rangeMaxValue = (this.currentNumberOfRows * newGoToPage)

    if(rangeMaxValue >= this.recordslength){
      this.pageRangeMax = this.recordslength
      this.pageRangeMin = this.totalPages * this.currentNumberOfRows - this.currentNumberOfRows + 1
    }else{
      this.pageRangeMax = rangeMaxValue
      this.pageRangeMin = this.pageRangeMax - this.currentNumberOfRows + 1
    }
    sendPaginatorParams && this.sendPaginatorParams();
  }

  sendPaginatorParams(){
    const obj = {
      newPage: this.currentPage,
      newSize: this.currentNumberOfRows
    }

    this.paramsToGetUsers.emit(obj)
  }

  get total(){
    return Math.ceil(this.recordslength / this.currentNumberOfRows)
  }


}
