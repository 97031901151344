import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, publishReplay, refCount} from 'rxjs/operators';
import { Observable } from 'rxjs';

import { HttpErrorHandlerService } from 'projects/shared/src/app/common/general/services/http-error-handler.service'; 
import {CurrencyTypes} from '../../../../../study-list/src/app/constants';
import { HttpService } from 'projects/shared/src/app/common/general/services/http.service'; 

@Injectable(
  {
    providedIn: 'root'
  })
export class StudyService {
  _benchmarks: Observable<any> = null;

    constructor(private http: HttpClient, private httpErrorService: HttpErrorHandlerService, private httpService: HttpService) { }

  clearBenchmarks() {
    this._benchmarks = null;
  }

  getStudySecurityPermission() {
    const url: string = './StudySessionSafe/GetStudySecurityPermission';

      return this.http.get(url).pipe(catchError(this.httpErrorService.handleError));
    }

  getCountries() {
    const url: string = './Study/GetCountries';

      return this.http.get(url).pipe(catchError(this.httpErrorService.handleError));
    }

  getSubStudies() {
    const url: string = './Study/GetSubStudies';

      return this.http.get(url).pipe(catchError(this.httpErrorService.handleError));
    }

  getRegions() {
    const url: string = './Study/GetRegions';

      return this.http.get(url).pipe(catchError(this.httpErrorService.handleError));
    }

  getCurrencies() {
    const url: string = './Study/GetCurrencies';

      return this.http.get(url).pipe(catchError(this.httpErrorService.handleError));
    }

  switchCountry(newCountryCode: string) {
    const url: string = './Study/SwitchCountry';
    const httpOptions = {
      params: new HttpParams().set('countryCode', newCountryCode)
      };

      return this.http.get(url, httpOptions).pipe(catchError(this.httpErrorService.handleError));
    }

  populateDropdowns() {
    const url: string = './StudySessionSafe/PopulateDropDowns';

      return this.http.get(url).pipe(catchError(this.httpErrorService.handleError));
    }

  switchSubstudy(subStudyId: string) {
    const url: string = './Study/SwitchSubstudy';
    const httpOptions = {
      params: new HttpParams().set('subStudyId', subStudyId)
      };

      return this.http.get(url, httpOptions).pipe(catchError(this.httpErrorService.handleError));
    }

  switchRegion(newCountryCode: string, newRegionCode: string) {
    const url: string = './Study/SwitchRegion';
    const httpOptions = {
      params: new HttpParams().set('countryCode', newCountryCode).set('regionCode', newRegionCode)
      };

      return this.http.get(url, httpOptions).pipe(catchError(this.httpErrorService.handleError));
    }

  switchCurrency(newCurrencyType: CurrencyTypes, newCurrencyCode: string) {
    const url: string = './Study/SwitchCurrency';
    const httpOptions = {
      params: new HttpParams().set('typeCurrency', newCurrencyType.toString()).set('currencyCode', newCurrencyCode)
      };

      return this.http.get(url, httpOptions).pipe(catchError(this.httpErrorService.handleError));
    }

  getBenchmarkSettingsBySponsorId() {
    const url: string = './StudySessionSafe/GetBenchmarkSettingsBySponsorId';

    if (!this._benchmarks) {
      this._benchmarks = this.httpService.safeGet({url}).pipe(
        publishReplay(1),
        refCount()
      );
    }
    return this._benchmarks;
  }

  getComplexityApplied() {
    const url: string = './StudySessionSafe/GetComplexityApplied';
    return this.httpService.safeGet({url});
  }

  getIsCopiedStudy() {
    const url: string = './Study/GetIsCopiedStudy';
    return this.httpService.safeGet({ url });
  }

  changeIsCopiedStudy() {
    const url: string = './Study/ChangeStudyIsCopied';
    return this.httpService.safeGet({ url });
  }
  // TODO: Please Brian consider if we must remove this function. It is not in use.
  getPromises() {
    return 'Consulting...';
    }
  }
