import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { HttpService } from '../../common/general/services/http.service'; 
import { HttpParams } from '@angular/common/http';

import { IAddIndicationInfo, IndicationParam } from 'projects/study-details/src/app/models/study-indication';
import { IndicationInfo } from '../models/indication.model'; 


@Injectable({ providedIn: 'root' })
export class IndicationService {

  constructor(private httpService: HttpService) {}


  getIndicationInfo(Icd: string): Observable<IndicationInfo> {
    const url: string = './Indication/GetIndicationDetails';
    const options = { params: new HttpParams().set('Icd', Icd) };
    return this.httpService.safeGet({url, options});
  }

  getStudyIndications(): Observable<IAddIndicationInfo[]> {
    const url: string = './StudyDetailsSessionSafe/GetStudyIndications';
    return this.httpService.safeGet<IAddIndicationInfo[]>({url});
  }

  saveStudyIndication(ind: IAddIndicationInfo, primaryIndicationWasChanged: boolean) {
    const url: string = './StudyDetailsAPI/SaveStudyIndications';
    const body = { ind, primaryIndicationWasChanged };
    return this.httpService.safePost({url, body});
  }

  getIndicationList(p: IndicationParam) {
    const url: string = './StudyDetailsAPI/AjaxGetIndications';
    const body = { p };
    return this.httpService.safePost({url, body});
  }

  addStudyIndications(addLst: IAddIndicationInfo[], deleteLst: IAddIndicationInfo[], primaryIndicationWasChanged: boolean) {
    const url: string = './StudyDetailsAPI/AjaxStudyAddIndications';
    const body = { addLst, deleteLst, primaryIndicationWasChanged };
    return this.httpService.safePost({url, body});
  }

  deleteIndications(indications: IAddIndicationInfo[], isPrimaryIndicationChanged: boolean) {
    const url: string = './StudyDetailsAPI/DeleteStudyIndications';
    const body = { indications, isPrimaryIndicationChanged };
    return this.httpService.safePost({url, body});
  }

  hasProcedures() {
    const url: string = './StudyDetailsAPI/IsHasProcedures';
    return this.httpService.safePost({url, body: null});
  }

  getCostPerPatientIndicationsList(p: IndicationParam) {
      const url: string = './Indication/AjaxGetCostPerPatientIndications';
      const body = { p };
      return this.httpService.safePost({ url, body });
  }
}
