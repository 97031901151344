import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoggedUser } from '../../../models/user/user/logged-user.model';
import { LoggedUserService } from '../../../services/user/user/logged-user.service';
import { SelectedUserService } from '../../../services/user/user/selected-user.service';
import { UserModalTab } from '../../../constants/user/user-modal-tabs';

@Component({
  selector: 'app-toolbar-tab-component',
  templateUrl: './toolbar-tab-component.component.html',
  styleUrls: ['./toolbar-tab-component.component.css']
})
export class ToolbarTabComponentComponent implements OnInit {

  @Output()
  cancelEmitter = new EventEmitter();

  @Output()
  isEnableEmitter = new EventEmitter<boolean>();

  @Output()
  saveEmitter = new EventEmitter();

  @Input()
  allowSaving: boolean = true;

  @Input()
  allowEdit: boolean= true;

  @Input()
  set isInEditMode(isEnable: boolean) {
    this.editModeEnabled = isEnable;
  }

  @Input() tab: number;

  editModeEnabled: boolean = false;
  loggedUser: LoggedUser;

  constructor(
    private loggedUserService: LoggedUserService,
    private selectedUserService: SelectedUserService) {

    this.loggedUserService.getLoggedUser(this);
  }

  ngOnInit(): void {
    this.editModeEnabled = this.selectedUserService.IsActive && this.selectedUserService.IsNew;
  }

  cancel(): void {
    this.isInEditMode = false;
    this.cancelEmitter.emit(true);
  }

  enableEditMode(): void {
    this.isInEditMode = true;
    this.isEnableEmitter.emit(this.editModeEnabled);
  }

  save(): void {
    this.saveEmitter.emit(true);
  }

  showToolbar(): boolean {
    const selfSelected = this.loggedUser.UserId === this.selectedUserService.UserId;
    
    return (this.loggedUser.CanManageUsers && this.selectedUserService.IsActive
            && (this.loggedUser.IsAdmin || !this.selectedUserService.IsAdmin))
            ||
      (selfSelected && this.selectedUserService.IsAdmin) ||
      (selfSelected && (this.tab === UserModalTab.Options || this.tab === UserModalTab.StudySecurity));
  }
}
