import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResourcePermissionParam } from '../../models/resource-permission-validator/ResourcePermissionParam';
import { HttpService } from '../../common/general/services/http.service';
import { Resource } from '../../constants/Resource';
import { Permission } from '../../constants/Permission';


interface ResourcePermissionPair {
  resource: string,
  permission: string
};

@Injectable({
  providedIn: 'root'
})
export class ResourcePermissionValidator {

  resourcePermissions: ResourcePermissionParam[];
  url: string = './PermissionAPI/GetRolePermission';
  resourcePermissionsObservable: ReplaySubject<ResourcePermissionParam[]>;

  constructor(private httpService: HttpService,) {
    this.resourcePermissionsObservable = new ReplaySubject<ResourcePermissionParam[]>(1);

    this.httpService.safeGet<{ RolePermission: ResourcePermissionParam[] }>({ url: this.url })
      .subscribe(({ RolePermission }: { RolePermission: ResourcePermissionParam[] }) => {
        this.resourcePermissions = RolePermission;
        this.resourcePermissionsObservable.next(RolePermission);
      });
  }

  hasPermission(resourceName: string, permission: string): boolean {
    return this.resourcePermissions.some(rp => (rp.ResourceName === Resource.admin && rp.Permissions.includes(Permission.all) && (permission != Permission.specific)) ||
      (rp.ResourceName === resourceName && rp.Permissions.includes(permission)))
  }

  hasPermissions(pairs: ResourcePermissionPair[]): boolean[] {
    return pairs.map(pair => this.hasPermission(pair.resource, pair.permission));
  }

  hasPermissionAsync(resourceName: string, permission: string): Observable<boolean> {
    return this.resourcePermissionsObservable.pipe(map(_ => this.hasPermission(resourceName, permission)));
  }

  hasPermissionsAsync(pairs: ResourcePermissionPair []): Observable<boolean[]> {
    return this.resourcePermissionsObservable.pipe(map(_ => this.hasPermissions(pairs)));
  }

  checkIfIsAdmin() {
    return this.resourcePermissions.some(rp => rp.ResourceName === Resource.admin && rp.Permissions.includes(Permission.all));
  }

  isAdmin(): Observable<boolean> {
    return this.resourcePermissionsObservable.pipe(map(_ => this.checkIfIsAdmin()));
  }
}
