import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../common/general/services/http.service';
import { UnsavedChangesNotifierStateService } from '../../common/general/services/unsaved-changes-notifier.service';


@Injectable({
  providedIn: 'root'
})
export class CloseSesionWithoutRedirect {
  constructor(private httpService: HttpService, private unsavedChangesService: UnsavedChangesNotifierStateService) { }

  CloseSession(): Observable<any>{
    const url: string = './Account/LogOffWithOutRedirect';
    this.unsavedChangesService.notify(false);
    return this.httpService.safePost({ url, body:{}  });
  }
}
