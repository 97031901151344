import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VerticalScrollService {
  private visitDescriptionHeight : number = 0;
  private studyDetailsHeight : number = 0;
  private visitScheduleSectionsHeights: number[] = [];
  private currentSectionToSelectAll = new Subject<String>();
  private stikyHeaderHeight: number;



  updateVisitDescriptionHeight(height: number) {
    this.visitDescriptionHeight = height;
  }
 
  updateStudyDetailsHeight(height: number){
    this.studyDetailsHeight = height;
  }
  
  getVisitScheduleHeight(): number {
    return this.visitDescriptionHeight + this.studyDetailsHeight;
  }

  initializeVSHeightArray(){
    this.visitScheduleSectionsHeights = [];
  }

  setVisitScheduleSectionHeight(section : number, height: number){
    this.visitScheduleSectionsHeights[section]? 
    this.visitScheduleSectionsHeights[section] = height:
    this.visitScheduleSectionsHeights.push(height);
  }

  getCurrentSection(scrollY : number){
    const index = this.visitScheduleSectionsHeights.findIndex(element => element > scrollY);
    if (index === -1 && scrollY >= this.visitScheduleSectionsHeights[this.visitScheduleSectionsHeights.length - 1]) {
      return this.visitScheduleSectionsHeights.length -1;
    }
    return index - 1
  }

  sendSectionSelectAllEvent(message: String) {
    this.currentSectionToSelectAll.next(message);
  }

  getObservable(): Observable<String> {
    return this.currentSectionToSelectAll.asObservable();
  }

  setStickyheaderHeight(height: number): void{
    this.stikyHeaderHeight = height;
  }

  getStickyheaderHeight(){
    return this.stikyHeaderHeight;
  }
  
}