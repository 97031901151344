import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { faChevronCircleDown, faUserCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep, isNil } from 'lodash';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

import { HeaderNavbarData } from '../../models/header-navbar-data';
import { StudyDetailsComponent } from 'projects/shared/src/app/common/general/components/study-details-component/study-details.component';
import { CopyStudyComponent } from '../../../study/components/copy-study-component/copy-study.component';
import { SwitchEnvironmentService } from '../../services/switch-environment.service';
import { UtilService } from '../../../common/general/services/util.service';
import { EventBusService } from '../../../common/general/services/event-bus.service';
import { EventType } from '../../../common/general/models/events';
import { MessageService } from '../../../common/general/services/message.service';
import { AccountService } from '../../../common/security/services/account.service';
import { NavigationService } from '../../services/navigation.service';
import { BeforeunloadNotifierStateService } from '../../../common/general/services/beforeunload-notifier-state.service';
import { UnsavedChangesNotifierStateService } from '../../../common/general/services/unsaved-changes-notifier.service';
import { CloseSesionWithoutRedirect } from '../../../services/close-sesion-without-redirect/close-sesion-without-redirect.service';
import { HelpSiteService } from '../../services/help-site.service';
import { ResourcePermissionValidator } from 'projects/shared/src/app/services/resource-permission-validator/resource-permission-validator.service';
import { Resource } from 'projects/shared/src/app/constants/Resource';
import { Permission } from 'projects/shared/src/app/constants/Permission';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { InactivityInspectorService } from '../../../services/inactivity-inspector/inactivity-inspector.service';
import { UserModalComponent } from '../../../components/user-components/user-modal/user-modal.component';
import { LoggedUser } from '../../../models/user/user/logged-user.model';
import { SelectedUserService } from '../../../services/user/user/selected-user.service';
import { LoggedUserService } from '../../../services/user/user/logged-user.service';
import { RoleService } from '../../../services/user/role/role.service';
import { ROLES } from '../../../constants/constants';
import { ClassicNextMapping } from '../../../models/tabs/classic-next-mapping';
import { UserSponsorList } from '../../models/header-navbar-sponsordata';
import { HeaderTab, HEADER_TABS } from '../../models/header-tab';
import { Tab } from '../../../constants/Tab';
import { StudyCodeService } from '../../../common/general/services/study-code.service';
import { SharedDataService } from '../../../common/general/services/shared-data.service';

@Component(
  {
    selector: 'gp-ui-header-navbar-component',
    templateUrl: './header-navbar.component.html',
    styleUrls: ['./header-navbar.component.css']
  })
export class HeaderNavbarComponent implements OnInit, OnDestroy {

  readonly BTN_RETURN_CLASSNAME = 'RETURNNavTab';
  readonly BTN_MORE_CLASSNAME = 'MORENavTab';

  headerNavbarData: HeaderNavbarData = undefined;
  @Input() headerNavbarTitle: string = '';
  showTabs: boolean = false;
  showSwitchButton: boolean = false;
  chevronCircleDownIcon = faChevronCircleDown;
  infoCircleIcon = faInfoCircle;
  userCircleIcon = faUserCircle;
  unsavedChangesSubscription: Subscription;
  tabsMappingSubscription: Subscription;
  unsavedChanges: boolean = false;
  scrollStrategy: ScrollStrategy;
  studyCode: string = '';
  showExtraField: boolean = false;
  returnButtonEnabled: boolean = false;
  isSuperOrAdmin: boolean = false;
  userCanCreateStudy: boolean;
  userCanCopyStudy: boolean;
  sponsorlistcount: number;
  SponsorList: UserSponsorList[] = [];
  private modalRef: NgbModalRef;

  loggedUser: LoggedUser;
  classicNextMappingList: ClassicNextMapping[] = [];
  canAccessCppStudies: boolean = false;
  canAccessCpvStudies: boolean = false;
  canAccessSocQLTStudies: boolean = false;
  canAccessQLTStudies: boolean = false;
  activatedRouteParamsSubscription: Subscription;
  activatedRouteDataSubscription: Subscription;

  headerTabs: HeaderTab[] = HEADER_TABS;

  constructor(private materialDialog: MatDialog,
    private switchEnvironmentService: SwitchEnvironmentService,
    private utilService: UtilService,
    private eventBus: EventBusService,
    private messageService: MessageService,
    private sharedDataService: SharedDataService,
    private scrollStrategyOptions: ScrollStrategyOptions,
    private accountService: AccountService,
    private navigationService: NavigationService,
    private unsavedChangesStateService: UnsavedChangesNotifierStateService,
    private beforeUnloadStateService: BeforeunloadNotifierStateService,
    private helpSiteServide: HelpSiteService,
    private inactivityInspector: InactivityInspectorService,
    private resourcePermission: ResourcePermissionValidator,
    private modalService: NgbModal,
    private selectedUserService: SelectedUserService,
    private loggedUserService: LoggedUserService,
    private roleService: RoleService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private session: CloseSesionWithoutRedirect,
    private studyCodeService: StudyCodeService) {
    (<any>window.parent).navigateToPreviousPage = this.navigateToPreviousPage.bind(this);
    this.scrollStrategy = scrollStrategyOptions.reposition();
    this.initializeHeaderNavbarData((<any>window).headerNavbarData);
    const urlParams = new URLSearchParams(window.location.search);

    this.returnButtonEnabled = (urlParams.has('prevStudy'))
      ? false : this.utilService.toBoolean((<any>window).headerNavbarData.SessionData.HasPreviousStudy);
    this.canCreateStudy();
    this.canCopyStudy();
    this.loggedUserService.getLoggedUser(this);
  }

  ngOnInit(): void {
    this.subscribeToMapping();

    this.resourcePermission.hasPermissionsAsync([
      { resource: Resource.cpvStudy, permission: Permission.navigate },
      { resource: Resource.cppStudy, permission: Permission.navigate },
      { resource: Resource.socQLTStudy, permission: Permission.navigate },
      { resource: Resource.qltStudy, permission: Permission.navigate },
    ]).subscribe(([canAccessCpvStudies, canAccessCppStudies, canAccessSocQLTStudies, canAccessQLTStudies, hasSpecificTabAccess]) => {
      this.canAccessCpvStudies = canAccessCpvStudies;
      this.canAccessCppStudies = canAccessCppStudies;
      this.canAccessSocQLTStudies = canAccessSocQLTStudies;
      this.canAccessQLTStudies = canAccessQLTStudies;
    });

    this.accountService.getStudySponsorCustomization().subscribe(
      data => {
        this.showExtraField = data['ShowBMARCREF'] || data['ShowRevision'];
      },
      error => {
        console.log('An error occurred when retrieving sponsor customization.  ' + error.message);
      });

    this.initHeaderTabs();
    this.checkCurrentUserRoles();

    this.subscribeToRouteDataChanges();
    this.subscribeToQueryParamsChanges();
    // Required to fix an UI issue that appears sometimes.
    setTimeout(this.utilService.dispatchEvent, 10, 'resize');
    this.subscribeUnsavedChanges();
    this.subscribeToStudyCodeChanges();
    (<any>window).ResponsiveNavTabs.Init();
  }

  ngOnDestroy(): void {
    this.unsavedChangesSubscription && this.unsavedChangesSubscription.unsubscribe();
    this.activatedRouteParamsSubscription && this.activatedRouteParamsSubscription.unsubscribe();
    this.activatedRouteDataSubscription && this.activatedRouteDataSubscription.unsubscribe();
    this.tabsMappingSubscription && this.tabsMappingSubscription.unsubscribe();
  }

  initializeHeaderNavbarData(data: any) {
    if (!isNil(data)) {
      this.headerNavbarData = <HeaderNavbarData>cloneDeep(data);
      const { SessionData } = data;
      this.headerNavbarData.SessionData.IsCountryPayeeAllocationVisible =
        this.utilService.toBoolean(SessionData.IsCountryPayeeAllocationVisible);
      this.headerNavbarData.SessionData.IsSitePayeeAllocationVisible = this.utilService.toBoolean(SessionData.IsSitePayeeAllocationVisible);
      this.headerNavbarData.SessionData.IsInterfaceVisible = SessionData.IsInterfaceVisible;
      this.headerNavbarData.SessionData.IsShowPaymentFlag = this.utilService.toBoolean(SessionData.IsShowPaymentFlag);
      this.headerNavbarData.SessionData.GPYAccessOption = this.utilService.toBoolean(SessionData.GPYAccessOption);
      this.headerNavbarData.SessionData.QLTAccessOption = this.utilService.toBoolean(SessionData.QLTAccessOption);
      this.headerNavbarData.SessionData.IsCRO = this.utilService.toBoolean(SessionData.IsCRO);
      this.headerNavbarData.SessionData.ICFAccessOption = this.utilService.toBoolean(SessionData.ICFAccessOption);
      this.headerNavbarData.SessionData.CPVTAccessOption = this.utilService.toBoolean(SessionData.CPVTAccessOption);
      this.headerNavbarData.SessionData.SQLTAccessOption = this.utilService.toBoolean(SessionData.SQLTAccessOption);
      this.headerNavbarData.SessionData.CPPAccessOption = this.utilService.toBoolean(SessionData.CPPAccessOption);
      this.headerNavbarData.SessionData.IsSOCEnabled = this.utilService.toBoolean(SessionData.IsSOCEnabled);
      this.headerNavbarData.ShowSwitchButton = this.utilService.toBoolean(data.ShowSwitchButton);
      this.headerNavbarData.IsAdmin = this.utilService.toBoolean(data.IsAdmin);
      this.headerNavbarData.SessionData.StudyCode = window['getStudyCode']('');
      this.studyCode = window['getStudyCode']('');
      this.headerNavbarData.SessionData.IsSiteUser = this.utilService.toBoolean(SessionData.IsSiteUser);
      this.headerNavbarData.SessionData.StudyID = SessionData.StudyID;
      this.SponsorList = SessionData.UserSponsorList;
      this.sponsorlistcount = this.SponsorList.length;
    }
  }

  subscribeToQueryParamsChanges() {
    if (!this.headerNavbarTitle.length) {
      this.activatedRouteParamsSubscription = this.activatedRoute.queryParams.subscribe(params => {
        const studyListFilter = params.studyListFilter;
        const urlParameter = studyListFilter ? studyListFilter : params.menuTitle;
        this.headerNavbarTitle = urlParameter ? this.getMenuTitle(urlParameter) : 'Study List';
      });
    }
  }

  private subscribeToRouteDataChanges() {
    this.activatedRouteDataSubscription = this.activatedRoute.data.subscribe(data => {
      this.setCurrentUrl();
      const currentTab = this.getTabFromRouterUrl(this.router.url);
      this.headerNavbarTitle = data.title ? data.title : this.headerNavbarTitle;
      this.showTabs = data.showTabs;

      // Value used in ResponsiveNavTabs.js
      (<any>window).currentTab = currentTab;

      this.setShowSwitchButton();
      this.stopEditMode();
    });
  }

  private stopEditMode() {
    this.messageService.updateEditMode(false);
    this.sharedDataService.IsEditMode = false;
  }

  setShowSwitchButton() {
    const mapping = this.classicNextMappingList.find(mapping => mapping.url.includes((<any>window).currentTab));
    this.showSwitchButton = mapping && !!mapping.classicUrl;
  }


  private setCurrentUrl() {
    const currentUrl = sessionStorage.getItem('currentUrl');
    if (currentUrl && currentUrl != location.href) {
      sessionStorage.setItem('previousUrl', sessionStorage.getItem('currentUrl'));
    }
    sessionStorage.setItem('currentUrl', location.href);
  }

  private getTabFromRouterUrl(url: string): string {
    const urlArray = url.split('/');
    let currentTab = urlArray[1] == "Classic" ? urlArray[2] : urlArray[1];
    currentTab = currentTab.split('?')[0];
    return currentTab;
  }

  canCreateStudy() {
    this.resourcePermission.hasPermissionAsync(Resource.study, Permission.create).subscribe(
      x => this.userCanCreateStudy = x
    );
  }

  canCopyStudy() {
    this.resourcePermission.hasPermissionAsync(Resource.study, Permission.copy).subscribe(
      x => this.userCanCopyStudy = x
    );
  }

  getUrlVariables() {
    const vars: string[] = [];
    let hash: string[] = [];
    const params: string[] = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');

    for (let i = 0; i < params.length; ++i) {
      hash = params[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }

    return vars;
  }

  getMenuTitle(param: string) {
    let menuTitle: string = '';

    switch (param) {
      case '1':
        menuTitle = 'Study List';
        break;

      case '2':
        menuTitle = 'Archived Study List';
        break;

      case '3':
        menuTitle = 'Agreed Study List';
        break;

      default:
        menuTitle = decodeURI(param);
        break;
    }

    return menuTitle;
  }

  private subscribeToMapping(): void {
    this.tabsMappingSubscription = this.roleService.GetTabsMapping().subscribe(response => {
      this.classicNextMappingList = response.data;
      this.setShowSwitchButton();
    });
  }

  navigateToPreviousPage(): void {
    window.top.location.href = sessionStorage.getItem('previousUrl');
  }

  showNewStudyModal() {
    this.navigationService.validateUnSavedChanges().subscribe(response => response && this.buildNewStudyModal())
  }

  private buildNewStudyModal = () => {
    const dialogConfig: MatDialogConfig = {
      width: '950px',
      height: 'auto',
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'custom-modalbox',
      scrollStrategy: this.scrollStrategy
    };

    this.messageService.updateEditMode(false);
    const newStudyDialogRef = this.materialDialog.open(StudyDetailsComponent, dialogConfig);
    newStudyDialogRef.componentInstance.isNewStudy = true;
    newStudyDialogRef.componentInstance.paymentTermsCustomColumnName = this.headerNavbarData.PaymentTermsCustomColumnName;
    newStudyDialogRef.componentInstance.newStudyModalAccept.subscribe(() => this.beforeUnloadStateService.notify(false));
    newStudyDialogRef.componentInstance.newStudyModalCancel.subscribe(() => newStudyDialogRef.close());
    $('body').addClass('customScroll');
  }

  subscribeUnsavedChanges() {
    this.unsavedChangesSubscription = this.eventBus.on(EventType.UnsavedChanges, (data: boolean) => this.unsavedChanges = data);
  }

  showCopyStudyModal() {
    this.navigationService.validateUnSavedChanges().subscribe(response => response && this.buildCopyStudyModal());
  }

  private buildCopyStudyModal = () => {
    const dialogConfig: MatDialogConfig = {
      width: '450px',
      height: this.showExtraField ? '325px' : '285px',
      autoFocus: false,
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'custom-modalbox'
    };

    const copyStudyDialogRef = this.materialDialog.open(CopyStudyComponent, dialogConfig);
    copyStudyDialogRef.componentInstance.studyCode = this.studyCode;
    copyStudyDialogRef.componentInstance.sponsorCode = this.headerNavbarData.SessionData.SponsorName;
    copyStudyDialogRef.componentInstance.additionalInfo = window['getAdditionalInfo']('');
    copyStudyDialogRef.componentInstance.checkIfCPPToolOrSMSLPage = this.checkIfCPPToolOrSMSLPage();
    copyStudyDialogRef.componentInstance.copyStudyModalAccept.subscribe(() => this.beforeUnloadStateService.notify(false));
    copyStudyDialogRef.componentInstance.copyStudyModalClose.subscribe(() => copyStudyDialogRef.close());
  }

  switchEnvironments() {
    const switchEnviromentsSubscriberObject = this.getSwitchEnviromentsSubscriberObject();

    this.navigationService.validateUnSavedChanges().pipe(
      filter(response => !!response),
      mergeMap(() => this.switchEnvironmentService.switchEnvironments())
    ).subscribe(switchEnviromentsSubscriberObject);
  }

  private getSwitchEnviromentsSubscriberObject = () => ({
    next: (location: any) => window.location = location,
    error: (error) => console.log('Unable to switch environments.  ' + error.message)
  })

  checkIfCPPToolOrSMSLPage() {
    return window.location.href.includes('CppTool') || window.location.href.includes('MasterSiteList');
  }

  subscribeToStudyCodeChanges() {
    this.studyCodeService.getStudyCode().subscribe(studyCode => {
      if (studyCode) {
        this.studyCode = studyCode;
      }
    });
  }

  logOff() {
    this.navigationService.validateUnSavedChanges().subscribe(response => !!response && this.closeSession());
  }

  closeSession() {
    localStorage.removeItem('sessionID');
    sessionStorage.removeItem('loginTab');
    sessionStorage.removeItem('urlPermission');
    sessionStorage.removeItem('lastSearch');
    sessionStorage.removeItem('showAll');
    this.session.CloseSession().subscribe(() => {
      window.top.location.href = './login';
    });
  }

  redirectStaticPage() {
    this.helpSiteServide.getHelpSiteURL().subscribe(
      (url: string) => window.open(url, "_blank")
    )
  }

  validateTimeApi() {
    if (!!this.inactivityInspector.lastActive) {
      this.inactivityInspector.notifyActivity();
    } else {
      (window as any).notifyActivity();
    }
  }

  public openUserModal(): void {
    this.selectedUserService.castSelectedUser(this.loggedUser.UserId, true, this.loggedUser.IsAdmin);
    this.modalRef = this.modalService.open(UserModalComponent);
    this.modalRef.componentInstance.windowTitle = `User - ${this.headerNavbarData.SessionData.ContactName}`;
    this.modalRef.componentInstance.isCalledFromOptions = true;
    this.modalRef.componentInstance.cancelModal.subscribe(() => this.modalRef.close());
    this.modalRef.result.finally(() => {
      this.selectedUserService.clearSelection();
    });
  }

  private checkCurrentUserRoles(): void {
    this.roleService.GetContactRolesByUser()
      .subscribe(response => {
        this.isSuperOrAdmin = this.headerNavbarData.IsAdmin
          || response.data.some(r => r.ContactRoleName.toUpperCase() === ROLES.SUPER);

        const isSelective = !this.isSuperOrAdmin
          && response.data.some(r => r.ContactRoleName.toUpperCase() === ROLES.SELECTIVE);

        isSelective && this.setUpSelectiveHeaderTabs();
      });
  }

  private initHeaderTabs(): void {
    this.headerTabs.find(t => t.tabId === Tab.COUNTRY_PAYEE_ALLOCATION)
      .isVisible = this.headerNavbarData.SessionData.IsCountryPayeeAllocationVisible;
    this.headerTabs.find(t => t.tabId === Tab.SITE_PAYEE_ALLOCATION)
      .isVisible = this.headerNavbarData.SessionData.IsSitePayeeAllocationVisible;
    this.headerTabs.find(t => t.tabId === Tab.PAYMENT_TERMS)
      .isVisible = this.headerNavbarData.SessionData.IsShowPaymentFlag;
    this.headerTabs.find(t => t.tabId === Tab.INTERFACE)
      .isVisible = this.headerNavbarData.SessionData.IsInterfaceVisible;

    const headerTabsOrder = this.headerTabs.filter(t => t.isVisible).map(t => t.htmlClass);
    headerTabsOrder.push(this.BTN_RETURN_CLASSNAME);
    headerTabsOrder.push(this.BTN_MORE_CLASSNAME);

    // Value used in ResponsiveNavTabs.js
    (<any>window).headerTabsOrder = headerTabsOrder;
  }

  private setUpSelectiveHeaderTabs(): void {
    this.navigationService.subscribeToMapping()
      .subscribe(tabsInfo => {
        this.headerTabs.forEach(headerTab => {
          if (headerTab.isVisible) {
            const tabInfo = tabsInfo.find(t => t.tabId === headerTab.tabId);
            headerTab.isDisabled = !tabInfo.isAccessibleFlag;
          }
        });
      });
  }
}
