export enum Tab {
    LOGIN = -1,
    STUDY_LIST = 0,
    STUDY_DETAILS = 1,
    INIT_STUDY_DETAILS = 2,
    COUNTRIES = 3,
    MASTER_SITE_LIST = 53,
    CPP_TOOL = 54,
    CPP_SUMMARY = 4,
    VISIT_SCHEDULE = 5,
    SITES = 6,
    SITE_BUDGETS = 7,
    EMAIL_NOTIFICATIONS = 8,
    STUDY_OPTIONS = 9,
    STUDY_SECURITY = 10,
    PAYMENT_TERMS = 11,
    ATTACHMENTS = 12,
    COUNTRY_PAYEE_ALLOCATION = 13,
    SITE_PAYEE_ALLOCATION = 14,
    SCREENING_FAILURES_DROPOUTS = 15,
    SCREENING_AND_DROPOUTS_BUDGET = 16,
    STUDY_COMPLEXITY = 17,
    INTERFACE = 18,
    QUICK_LOOKUP_TOOL = 19,
    INSTITUTION_COST_FINDER = 20,
    COST_PER_VISITTOOL = 21,
    STANDARD_OF_CARE_TOOL = 22,
    COMPANY_SPECIFIC_CODES = 23,
    EMPLOYEES = 24,
    OPTIONS = 25,
    SWITCH_SPONSORS = 26,
    SWITCH_INVESTIGATORS = 27,
    SPONSOR_SETTING = 28,
    CRO_RIGHTS = 29,
    REPORTS = 30,
    REPORT_CPP = 31,
    REPORT_GRANTS = 32,
    REPORT_PROJ_NEG = 33,
    REPORT_SITE_NEG = 34,
    COPYSTUDY = 35,
    DOWNLOAD = 36,
    DOWNLOAD_FILES = 37,
    EXPORT_USERS_LIST = 38,
    FAQ = 39,
    GET_ATTACHMENT = 40,
    OVER_HEAD_DOCUMENTS = 41,
    PRINT = 42,
    SNY_TIPS = 43,
    SPONSOR_PRINTER = 44,
    STANDARD_PAYMENT_TERMS = 45,
    STUDY_EXPORT_TO_EXCEL_VIEW = 46,
    STUDYLIST_EXPORT_TO_EXCEL = 47,
    SUMMARY = 48,
    SUPPORT = 49,
    SUPPORT_DOCUMENTS = 50,
    TEST_DOWNLOAD = 51,
    VISIT = 52
}