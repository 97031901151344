import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudyCodeService {

  private subject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  getStudyCode(): BehaviorSubject<string> {
    return this.subject;
  }

  emit(studyCode: string) {
    this.subject.next(studyCode);
  }
}
