import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { GridApi, GridOptions, RowNode } from 'ag-grid-community';
import { ColumnApi } from 'ag-grid-community/dist/lib/columnController/columnApi';
import _ from 'lodash';
import { EventBusService } from 'projects/shared/src/app/common/general/services/event-bus.service';
import { Event, EventType } from 'projects/shared/src/app/common/general/models/events';
import { ISearchComponentTitleRenderer, ISearchInfoComponentOptions, ModalSelectionTypes } from '../../models/search-component-options';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gp-ui-app-search-info',
  templateUrl: './search-info.component.html',
  styleUrls: ['./search-info.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SearchInfoComponent<T> implements OnInit {

  @Input() modalOptions: ISearchInfoComponentOptions<T>;
  @Input()  applySingleLineTitle: boolean = true;
  @Output() saveModalData = new EventEmitter<T[]>();
  @Output() cancelModal = new EventEmitter();
  @ViewChild('titleContainer', {static: true, read: ViewContainerRef}) titleContainer: ViewContainerRef;

  private titleComponentRef: ComponentRef<any>;
  searchText: string = '';
  searchResults: T[] = [];
  searchScreenResults: T[] = [];
  selectedItems: T[] = [];
  previosSelectedItems: T[] = [];
  lastRemovedText: string = '';
  searchGridOptions: GridOptions;
  selectedGridOptions: any;
  searching: boolean = false;
  searchGridHasSelectedRows: boolean = false;
  selectedGridHasSelectedRows: boolean = false;
  showUndo: boolean = false;
  errormessage: { message: string, isShow: boolean } = { message: '', isShow: false };
  timeout: any;

  private searchSubject = new Subject<string>();
  private destroy$ = new Subject<void>();

  constructor(private eventBus: EventBusService, private resolver: ComponentFactoryResolver) {
    this.searchSubject.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.searchKeyHandler();
    });
   }

  onSearchTextChanged() {
    this.searchSubject.next(this.searchText);
  }

  ngOnInit() {
    this.initAgGrids();
    this.selectedItems = _.clone(this.modalOptions.selected);
    if (this.modalOptions.title instanceof Object){
      const titleRenderer = (this.modalOptions.title as ISearchComponentTitleRenderer).component;
      const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(titleRenderer);
      this.titleComponentRef = this.titleContainer.createComponent(factory);
      (this.modalOptions.title as ISearchComponentTitleRenderer).onRendered(this.titleComponentRef.instance)
    }
  }

  updateSelectedItems = (handler: (selectedItems: T[]) => T[]) => {
    this.selectedItems = handler(this.selectedItems);
    this.selectedGridOptions.api.setRowData(this.selectedItems);
    this.refreshGrids();
  }

  setSearchResults = (searchItems: T[]) => {
    this.searchResults = searchItems;
    this.searchGridOptions.api.setRowData(this.searchResults);
    this.refreshGrids();
  }

  searchKeyHandler() {
    if (this.searchText.length >= this.modalOptions.searchKeysToWait) {
      this.searching = true;
      this.search(this.searchText);
    } else {
      this.searchResults = [];
      this.refreshGrids();
    }
  }

  private search(textToSearch: string) {
    this.modalOptions.searchService(textToSearch).subscribe(
      (results: T[]) => {
        this.searchResults = results;
        this.searching = false;
        this.refreshGrids();
      }, error => this.emitError('Unable to retrieve cost per patient indications', error));
  }

  private addRow(rowData: T) {
    this.removeUndoAlert();
    this.selectedItems = this.modalOptions.selectionType === ModalSelectionTypes.SingleSelection
      ? [rowData]
      : this.selectedItems.concat(rowData);
    this.refreshGrids();
  }

  addSelected() {
    const newAddedRows: T[] = this.searchGridOptions.api.getSelectedRows();
    newAddedRows.forEach(rowData => this.addRow(rowData));
  }

  private removeRows(rowIndexes: number[]) {
    this.previosSelectedItems = this.selectedItems;
    this.selectedItems = this.selectedItems.filter((item, index) => !rowIndexes.includes(index));
    this.refreshGrids();
    this.showUndoMessage(this.previosSelectedItems.length - this.selectedItems.length);
    this.selectedGridHasSelectedRows = false;
  }

  undoLastRemove() {
    this.removeUndoAlert();
    this.selectedItems = this.previosSelectedItems;
    this.adjustGridColumns();
    this.refreshGrids();
  }

  private removeUndoAlert () {
    this.showUndo = false;
  }

  private showUndoMessage(removedRows) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.showUndo = true;
    this.lastRemovedText = removedRows + ' ' + this.modalOptions.sectionName + ' have been removed. Click here to undo';
    this.timeout = setTimeout(() => {
      this.showUndo = false;
    }, 6000);
  }

  removeSelected() {
    this.selectedGridHasSelectedRows = false;
    const itemsForRemoving: RowNode[] = this.selectedGridOptions.api.getSelectedNodes();
    const rowIndexes = itemsForRemoving.map(item => item.rowIndex);
    this.removeRows(rowIndexes);
  }

  removeAll() {
    this.previosSelectedItems = this.selectedGridOptions.rowData;
    this.showUndoMessage(this.selectedItems.length);
    this.selectedItems = [];
    this.refreshGrids();
  }

  save() {
    this.saveModalData.emit(this.selectedItems);
  }

  private adjustGridColumns() {
    this.adjustGridSize(this.selectedGridOptions.api);
    this.adjustGridSize(this.searchGridOptions.api);
    this.adjustColumnsHeight(this.searchGridOptions.columnApi);
    this.adjustColumnsHeight(this.selectedGridOptions.columnApi);
  }

  private adjustGridSize(agGrid: GridApi) {
    agGrid.sizeColumnsToFit();
  }

  private adjustColumnsHeight(columnApi: ColumnApi) {
    const allColumns = columnApi.getAllDisplayedColumns();
    const referenceColumn = allColumns[1];
    if (referenceColumn) {
      columnApi.setColumnWidth(referenceColumn, referenceColumn.getActualWidth() - 1);
    }
  }

  private initAgGrids() {
    this.searchGridOptions = {
      columnDefs: [],
      suppressLoadingOverlay: true,
      rowData: [{}],
      rowHeight: 22,
      suppressScrollOnNewData: true,
      rowSelection: this.modalOptions.selectionType === ModalSelectionTypes.SingleSelection ? 'single' : 'multiple',
      rowDeselection: true,
      suppressCellSelection: true,
      onSelectionChanged: this.onGridSelectionChange,
      onRowDoubleClicked: row => this.addRow(row.data),
      defaultColDef:{
        sortable:false,
        filter:false
      }
    };

    this.selectedGridOptions = {
      columnDefs: [],
      rowHeight: 22,
      suppressScrollOnNewData: true,
      rowSelection: this.modalOptions.selectionType === ModalSelectionTypes.SingleSelection ? 'single' : 'multiple',
      onSelectionChanged: this.onGridSelectionChange,
      onRowDoubleClicked: row => this.removeRows([row.rowIndex]),
      defaultColDef:{
        sortable:false,
        filter:false
      }
    };
  }

  private onGridSelectionChange = () => {
    this.searchGridHasSelectedRows = this.searchGridOptions.api &&
      Boolean(this.searchScreenResults.length && this.searchGridOptions.api.getSelectedRows().length);
    this.selectedGridHasSelectedRows = this.selectedGridOptions.api &&
      Boolean(this.selectedItems.length && this.selectedGridOptions.api.getSelectedRows().length);
  }

  private refreshGrids() {
    this.searchScreenResults = this.getScreenResults();
    this.adjustGridColumns();
    this.onGridSelectionChange();
  }

  private getScreenResults(): T[] {
    const filterFunction = (itemFromResult: T) => !this.selectedItems.find((itemSelected: T) =>
      this.modalOptions.compareItemsService(itemSelected, itemFromResult));

      return this.searchResults.filter(filterFunction);
  }

  onGridReady() {
    this.refreshGrids();
  }

  handleCloseClick() {
    this.cancelModal.emit();
  }

  private emitError(description: string, details: any): void {
    this.eventBus.emit(new Event(EventType.UnhandledException, { description, details }));
  }

  get title(): string { return this.modalOptions.title as string }
  get searchMessage(): string { return this.modalOptions.searchMessage; }
  get overlayNoRowsTemplate(): string {
    return '<span style="padding: 15px; border: 1px solid ; border-radius: 10px;">No Rows To Show</span>';
  }
  get searchedRowsDisplayText(): string { return `Search Results (${this.searchScreenResults.length})`; }
  get selectedRowsDisplayText(): string { return `${this.modalOptions.selectedRowsDisplayText} (${this.selectedItems.length})`; }
  get noResults(): boolean { return this.searchResults.length === 0; }
  get saveButtonDisabled(): boolean {
    return this.modalOptions.minimumItemsForSaving && this.selectedItems.length < this.modalOptions.minimumItemsForSaving;
  }
  public isString = (target: any): boolean => typeof target === 'string' ;

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
