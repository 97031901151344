import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gpDecimal'
})
export class GpDecimalPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: any, nDecimals: number = -1): any {

    if(value === null ||  value === undefined) return value;
    
    let formattedValue: string = `${value}`;
    if(nDecimals>=0 && /^-?\d*\.?\d+$/gm.test(formattedValue))
    formattedValue = this.decimalPipe.transform(value, `1.${nDecimals}-${nDecimals}`);
  
    return formattedValue;
  }

}
