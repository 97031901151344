import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from 'projects/shared/src/app/common/general/services/http.service';

import { CountriesResponse } from '../../models/study-level/country.model';
import { CurrenciesResponse } from '../../models/study-level/currency.model';
import { CurrencyType } from '../../models/study-level/currency-type.enum';
import { RegionsResponse } from '../../models/study-level/region.model';
import { StudyLevelChangedResponse } from '../../models/study-level/study-level-changed-response.model';
import { SubStudiesResponse } from '../../models/study-level/sub-study.model';
import { LoadingService } from 'projects/shared/src/app/common/loading/services/loading.service';

@Injectable({
    providedIn: 'root'
})
export class StudyLevelDataFetchService {

    private readonly API_ENDPOINT: string = './Study';

    constructor(private httpService: HttpService,
        private loadingService: LoadingService,
    ) { }

    public getCountries(): Observable<CountriesResponse> {
        const url: string = `${this.API_ENDPOINT}/GetCountries`;

        return this.httpService.safeGet<CountriesResponse>({ url });
    }

    public getCurrencies(): Observable<CurrenciesResponse> {
        const url: string = `${this.API_ENDPOINT}/GetCurrencies`;

        return this.httpService.safeGet<CurrenciesResponse>({ url })
            .pipe(
                map(response => {
                    return response || { Currencies: [], Selected: 0 };
                })
            );
    }

    public getRegions(): Observable<RegionsResponse> {
        const url: string = `${this.API_ENDPOINT}/GetRegions`;

        return this.httpService.safeGet<RegionsResponse>({ url });
    }

    public getSubStudies(): Observable<SubStudiesResponse> {
        const url: string = `${this.API_ENDPOINT}/GetSubStudies`;

        return this.httpService.safeGet<SubStudiesResponse>({ url });
    }

    public switchCountry(newCountryCode: string): Observable<StudyLevelChangedResponse> {
        const url: string = `${this.API_ENDPOINT}/SwitchCountry`;
        const options = {
            params: new HttpParams().set('countryCode', newCountryCode)
        };

        return this.getStudyLevelChangedResponse(url, options);
    }

    public switchCurrency(newCurrencyType: CurrencyType, newCurrencyCode: string): Observable<StudyLevelChangedResponse> {
        const url: string = `${this.API_ENDPOINT}/SwitchCurrency`;
        const options = {
            params: new HttpParams().set('typeCurrency', newCurrencyType.toString()).set('currencyCode', newCurrencyCode)
        };

        return this.getStudyLevelChangedResponse(url, options);
    }

    public switchRegion(newCountryCode: string, newRegionCode: string): Observable<StudyLevelChangedResponse> {
        const url: string = `${this.API_ENDPOINT}/SwitchRegion`;
        const options = {
            params: new HttpParams().set('countryCode', newCountryCode).set('regionCode', newRegionCode)
        };

        return this.getStudyLevelChangedResponse(url, options);
    }

    public switchSubStudy(newSubStudyId: number): Observable<StudyLevelChangedResponse> {
        const url: string = `${this.API_ENDPOINT}/SwitchSubstudy`;
        const options = {
            params: new HttpParams().set('subStudyId', newSubStudyId.toString())
        };

        return this.getStudyLevelChangedResponse(url, options);
    }

    private getStudyLevelChangedResponse(url: string, options?: any): Observable<StudyLevelChangedResponse> {
        return this.loadingService.waitObservableWithSpinner(
            this.httpService.safeGet<StudyLevelChangedResponse>({ url, options })
                .pipe(
                    map((response: any) => {
                        return {
                            CountriesData: (response.CountriesData && response.CountriesData.Data) ? response.CountriesData.Data : { Countries: [], Selected: 0 },
                            CurrenciesData: (response.CurrenciesData && response.CurrenciesData.Data) ? response.CurrenciesData.Data : { Currencies: [], Selected: 0 },
                            CountrySecurityPermission: response.CountrySecurityPermission,
                            RegionsData: (response.RegionsData && response.RegionsData.Data) ? response.RegionsData.Data : { Regions: [], Selected: 0 },
                            SubStudiesData: (response.SubStudiesData && response.SubStudiesData.Data) ? response.SubStudiesData.Data : { Substudies: [], Selected: 0 },
                        };
                    })
                )
        );
    }
}