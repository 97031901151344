import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, } from '@angular/core';
import { UnsavedChangesNotifierStateService } from '../../../common/general/services/unsaved-changes-notifier.service';

@Component({
	selector: 'gp-table-cell-input',
	templateUrl: './table-cell-input.component.html',
	styleUrls: ['./table-cell-input.component.css']
})
export class TableCellInputComponent implements OnInit, OnChanges {

	@Input() isEditing: boolean;
	@Input() type: string;
	@Input() value: string;
	@Input() width: number;
	@Input() decimals: number = -1;
	@Input() admitNull: boolean = false;
	@Input() description: string = '';
	@Input() switchCountry?: boolean = false;
	@Input() isOnlyPositiveNumber: boolean = false;
	public decimalFormat: string = '';
	private changeByTheUser: boolean = false;

	@Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  private originalValue: string;

  constructor(private unsavedChangesNotifier: UnsavedChangesNotifierStateService) {
  }

	ngOnInit(): void {
		this.originalValue = this.value;
		this.decimalFormat = (this.decimals > -1) ? `1.${this.decimals}-${this.decimals}` : '';
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.value && !changes.value.firstChange) {
			//Added to avoid emitting the event multiple times if it is an external or user change
			if(!this.changeByTheUser){
				this.onInputChange();
			}
			this.changeByTheUser = false;
		}
	}

	onInputChange(event?: any): void {
		this.changeByTheUser = event !== undefined;
		let emit: boolean = true;

		if (typeof this.value === 'string') {
			this.value = this.value.trim();
			if (this.value === '' && !this.admitNull) {
				this.value = this.originalValue;
				emit = false;
			}
		}

		let emitValue: any = this.value;
		if (this.type === 'number') {
			emitValue = (this.value === '') ? 0 : parseFloat(this.value);
		}

		if (emit && !this.switchCountry) {
			this.valueChange.emit(emitValue);			
			this.unsavedChangesNotifier.notify(true);
		}
	}
}
