import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { modalResults } from 'projects/shared/src/app/models/confirm-modal-result/confirm-modal-result';
import { headerColor } from 'projects/shared/src/app/models/header-color/headerColor';


@Component({
  selector: 'gp-ui-app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  onCancelClick(): void {
    this.dialogRef.close(modalResults.Cancel);    
  }

  onNoClick():void{
    this.dialogRef.close(modalResults.No);    
  }

  onYesClick():void{
    this.dialogRef.close(modalResults.Yes);    
  }

  ngOnInit() {
  }

  getHeaderColor(){
    if(this.data.headerColor == headerColor.Info)
      return 'header-info';
    else  
      return 'header-warning';
  }

}
