import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { tap } from 'rxjs/internal/operators/tap';
import { HttpErrorHandlerService } from '../../general/services/http-error-handler.service'; 
import { EventType } from '../../general/models/events'; 
import { EventBusService } from '../../general/services/event-bus.service'; 

@Injectable(
  {
    providedIn: 'root'
  })
export class LoadingService {
  private isLoading: boolean = false;

  constructor(
    private httpErrorService: HttpErrorHandlerService,
    private eventBus: EventBusService
  ) { }

  get loading(): boolean {
    return this.isLoading;
  }

  show() {
    this.isLoading = true;
  }

  hide() {
    this.isLoading = false;
  }

  showLoadingAndSubscribeToEvent(eventType: EventType) {
    this.show();
    const subscription$ = this.eventBus.on(eventType, () => {
      this.hide();
      subscription$.unsubscribe();
    });
  }

  waitWithSpinner = (process) => {
    this.show();
    try {
      process().add(() => this.hide());
    } catch {
      setTimeout(() => this.hide(), 500);
    }
  }

  waitObservableWithSpinner = (process: Observable<any>) => {
    this.show();
    return process.pipe(
      tap(() => {
        this.hide();
      }),
      catchError(error => {
        this.hide();
        return this.httpErrorService.handleError(error);
      })
    );
  }
}
