import { Type } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs/internal/Observable';
export interface ISearchInfoComponentOptions <T> {
    title: string | ISearchComponentTitleRenderer;
    searchKeysToWait?: number;
    searchService: (searchKeyword: string) => Observable<T[]>;
    searchMessage: string;
    disclaimer?: string;
    selectedRowsDisplayText: string;
    compareItemsService: (current: T, added: T) => boolean;
    searchGridHeaders: ColDef[];
    selectedGridHeaders: ColDef[];
    selected: T[];
    saveButtonLabel: string;
    minimumItemsForSaving: number;
    selectionType: ModalSelectionTypes;
    sectionName: string;
    actions?: ISearchInfoComponentActions[]
}

export enum ModalSelectionTypes {
    SingleSelection = 1,
    MultipleSelection = 2,
    DuplicateSelection = 3,
}

export interface ISearchInfoComponentActions{
    buttonLabel: string,
    action: () => void,
    disabled?: boolean
}

export interface ISearchComponentTitleRenderer{
    component: Type<any>,
    onRendered: (componentInstance: any) => void
}
