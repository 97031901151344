import {
  ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output
} from '@angular/core';
import {
  AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { cloneDeep, isNil } from 'lodash';
import { EventBusService } from 'projects/shared/src/app/common/general/services/event-bus.service';
import { Event, EventType } from 'projects/shared/src/app/common/general/models/events';
import { ExportToExcelOptions } from 'projects/shared/src/app/excel/models/export-to-excel-options';
import { AccountService } from 'projects/shared/src/app/common/security/services/account.service';
import { ConfirmDialogService } from 'projects/shared/src/app/common/confirm/services/confirm-dialog.service';
import { ConfirmModalService } from 'projects/shared/src/app/common/confirm/services/confirm-modal.service';
import { ExportToExcelDialogService } from 'projects/shared/src/app/excel/services/export-to-excel-dialog.service';
import { LoadingService } from 'projects/shared/src/app/common/loading/services/loading.service';
import { SharedDataService } from 'projects/shared/src/app/common/general/services/shared-data.service';
import {
  UnsavedChangesNotifierStateService
} from 'projects/shared/src/app/common/general/services/unsaved-changes-notifier.service';
import { StudyService } from 'projects/study-list/src/app/services/study-service/study.service';
import { Subscription } from 'rxjs';
import { NewStudyDetailsData } from '../../../../../../../study-details/src/app/models/study-details/new-study-details-model';
import { StudyInfo } from '../../../../../../../study-details/src/app/models/study-details/study-info';
import { StudyDetailsService } from '../../../../../../../study-details/src/app/services/study-details-service/study-details.service';
import { ValueNotAllowedValidator } from '../../../../../../../study-details/src/app/utilities/ValueNotAllowedValidator';
import { ResourcePermissionValidator } from 'projects/shared/src/app/services/resource-permission-validator/resource-permission-validator.service';
import { SelectivePrivilegesService } from 'projects/shared/src/app/services/user/selective-privileges/selective-privileges.service';
import { Resource } from 'projects/shared/src/app/constants/Resource';
import { Permission } from 'projects/shared/src/app/constants/Permission';
import { Tab } from 'projects/shared/src/app/constants/Tab';
import { StudyCodeService } from '../../services/study-code.service';
import { take } from 'rxjs/operators';


@Component({
  selector: 'gp-ui-study-details',
  templateUrl: './study-details.component.html',
  styleUrls: ['./study-details.component.css']

})

export class StudyDetailsComponent implements OnInit, OnDestroy {
  showFormError: boolean;
  showBMARCREF: boolean;
  showSVTVersion: boolean;
  showRevision: boolean;
  showClinAdminExport: boolean;
  studyInfo: StudyInfo = {
    budgetTypeId: 0,
    budgetTypeName: '',
    phaseTypeId: 0,
    multiSingleCenterId: 0,
    multiSingleCenterName: '',
    patientTypeId: 0,
    patientTypeName: '',
    lengthOfTimeBetweenVisitsUnitId: 0,
    lengthOfTimeBetweenVisitsUnitName: '',
    lengthOfStudyUnitId: 0,
    lengthOfStudyUnitName: '',
    lengthOfTimeBetweenVisits: 0,
    lengthOfStudyValue: 0,
    studyTypeId: 0,
    studyTypeDetailsID: 0,
    studyTypeDetailsName: '',
    sourceType: '',
    createdOnParsed: new Date,
    lastModifiedParsed: new Date,
    approvalDateParsed: new Date,
    dateOfFinalVersionParsed: new Date,
    isClinAdminExportFlag: '',
    svtVersionId: 0,
    isArchived: false,
    isAgreed: false,
    phaseGroupName: '',
    studyCode: '',
    GSKBmarcref: '',
    revision: '',
    studyPopulationTypeIdList: [],
    singlePatientDuration: 0,
    visits: 0,
    totalVisitsPerSubjectPatient: 0,
    dateOfFinalVersion: new Date,
    createOn: new Date,
    modifyName: '',
    approver: '',
    isApproved: false,
    studyShortName: '',
    compoundDrug: '',
    title: '',
    phaseName: '',
    totalSubjectsPatients: 0,
    totalOvernightStaysPerSubjectPatient: 0,
    createName: '',
    studyId: 0,
    pharmacologyStudyManager: '',
    sites: 0,
    studyPopulationTypeId: 0,
    singlePatientDurationUnitId: 0,
    sponsorId: 0,
    masterBidGridBenchmarkVersionId: 0,
    createBySponsorContactId: 0,
    lastModifiedByContactId: 0,
    deleteFlag: false,
    status: 0
  };
  isEditMode: boolean;
  newStudyDetailsData: NewStudyDetailsData = {
    budgetTypes: [],
    lenghtofStudyUnitInfo: [],
    lengthOfTimeBetweenStudyUnits: [],
    multiSingleCenterInfo: [],
    patientTypeInfo: [],
    phaseTypes: [],
    sessionInfo: {
      showAveragePeriodBetweenVisits: false,
      contactName: '',
      isAdmin: false,
      isApprovalFlagVisible: false,
      isGSK: false,
      isEnableDateOfFinalVersion: false,
      isEnableDateOfFinalVersionFlag: false,
      isEnabledSocFlag: false,
      showPaymentTerms: false,
      sponsorName: ''
    },
    sourceTypeInfo: [],
    studyPopulationTypes: [],
    studyTypeDetails: []
  };
  sessionData: any;
  showAveragePeriodBetweenVisits: boolean;
  studyPopulationTypes: any = [];
  isShowApprovalModule: boolean;
  oldStudyCode: string;
  oldGSKBmarcref: string;
  phaseUpdate: boolean;
  multiCenterUpdate: boolean;
  studyInfo_cancelCopy: any;
  studyPopulationTypesAndValues_cancelCopy: any;
  invalidStudyCode: string;
  invalidGSKBmarcref: string;
  studySecurityPermission: any;
  initializedStudyCode: string = '';
  initializedBmarcref: string = '';
  studyDetailsForm: FormGroup;
  phaseUpdateText: string = 'visits';

  updateStudyDetailsSubscription: Subscription;
  hasTabAccessSubscription: Subscription;
  hasEditPermissionSubscription: Subscription;
  hasDeletePermissionSubscription: Subscription;
  hasAgreePermissionSubscription: Subscription;

  countrySecurityPermission: any;

  canEditPermission: boolean = false;
  canDeletePermission: boolean = false;
  canAgreePermission: boolean = false;
  hasPaymentTermsTabAccess: boolean = true;

  @Input() isNewStudy: boolean;
  @Output() newStudyModalCancel = new EventEmitter<any>();
  @Output() newStudyModalAccept = new EventEmitter<any>();

  public paymentTermsCustomColumnName: string;
  // Rollback changes
  get studyCode() {
    return this.studyDetailsForm.get('studyCode');
  }

  get GSKBmarcref() {
    return this.studyDetailsForm.get('GSKBmarcref');
  }

  get revision() {
    return this.studyDetailsForm.get('revision');
  }

  get isClinAdminExportFlag() {
    return this.studyDetailsForm.get('isClinAdminExportFlag');
  }

  get dateOfFinalVersionParsed() {
    return this.studyDetailsForm.get('dateOfFinalVersionParsed');
  }

  get studyShortName() {
    return this.studyDetailsForm.get('studyShortName');
  }

  get svtVersionId() {
    return this.studyDetailsForm.get('svtVersionId');
  }

  get pharmacologyStudyManager() {
    return this.studyDetailsForm.get('pharmacologyStudyManager');
  }

  get compoundDrug() {
    return this.studyDetailsForm.get('compoundDrug');
  }

  get title() {
    return this.studyDetailsForm.get('title');
  }

  get budgetTypeId() {
    return this.studyDetailsForm.get('budgetTypeId');
  }

  get phaseName() {
    return this.studyDetailsForm.get('phaseName');
  }

  get patientTypeId() {
    return this.studyDetailsForm.get('patientTypeId');
  }

  get studyTypeDetailsID() {
    return this.studyDetailsForm.get('studyTypeDetailsID');
  }

  get lengthOfTimeBetweenVisitsUnitId() {
    return this.studyDetailsForm.get('lengthOfTimeBetweenVisitsUnitId');
  }

  get multiSingleCenterId() {
    return this.studyDetailsForm.get('multiSingleCenterId');
  }

  get sites() {
    return this.studyDetailsForm.get('sites');
  }

  get totalSubjectsPatients() {
    return this.studyDetailsForm.get('totalSubjectsPatients');
  }

  get lengthOfStudyValue() {
    return this.studyDetailsForm.get('lengthOfStudyValue');
  }

  get lengthOfStudyUnitId() {
    return this.studyDetailsForm.get('lengthOfStudyUnitId');
  }

  get totalVisitsPerSubjectPatient() {
    return this.studyDetailsForm.get('totalVisitsPerSubjectPatient');
  }

  get totalOvernightStaysPerSubjectPatient() {
    return this.studyDetailsForm.get('totalOvernightStaysPerSubjectPatient');
  }

  get lengthOfTimeBetweenVisits() {
    return this.studyDetailsForm.get('lengthOfTimeBetweenVisits');
  }

  get sourceType() {
    return this.studyDetailsForm.get('sourceType');
  }

  get approver() {
    return this.studyDetailsForm.get('approver');
  }

  get isApproved() {
    return this.studyDetailsForm.get('isApproved');
  }

  constructor(
    private accountService: AccountService,
    private studyDetailsService: StudyDetailsService,
    private sharedDataService: SharedDataService,
    private studyService: StudyService,
    private eventBus: EventBusService,
    private confirmModal: ConfirmModalService,
    private exportToExcelDialog: ExportToExcelDialogService,
    private loadingService: LoadingService,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private dialogService: ConfirmDialogService,
    private resourcePermissionValidator: ResourcePermissionValidator,
    private selectivePrivilegesService: SelectivePrivilegesService,
    private unsavedChangesState: UnsavedChangesNotifierStateService,
    private studyCodeService: StudyCodeService) {

    this.isEditMode = false;
    this.showFormError = false;
    this.paymentTermsCustomColumnName = (<any>window).paymentTermsCustomColumnName;

    this.studyService.getStudySecurityPermission().subscribe((res: any) => {
      this.countrySecurityPermission = res['CountrySecurityPermission'];
    });
  }

  UpdateStudyDetailsInfoSubscription() {
    this.updateStudyDetailsSubscription = this.eventBus.on(EventType.UpdateStudyDetailsInfo, () =>
      this.getStudyDetails());
  }

  validateCanEditPermission() {
    this.hasEditPermissionSubscription = this.resourcePermissionValidator.hasPermissionAsync(Resource.study, Permission.update).subscribe((resp: boolean) => {
      this.canEditPermission = resp;
    });
  }

validateCanDeletePermission() {
  this.hasDeletePermissionSubscription = this.resourcePermissionValidator.hasPermissionAsync(Resource.study, Permission.delete).subscribe((resp: boolean) => {
    this.canDeletePermission = resp;
    });
  }

  validateCanAgreePermission() {
    this.hasAgreePermissionSubscription = this.resourcePermissionValidator.hasPermissionAsync(Resource.study, Permission.agree).subscribe((resp: boolean) => {
      this.canAgreePermission = resp;
      });
    }

evaluateIfUserHasAccessToPaymentTerms(){
  this.hasTabAccessSubscription = this.selectivePrivilegesService.hasTabAccess(Tab.PAYMENT_TERMS).subscribe((resp: boolean) => {
    this.hasPaymentTermsTabAccess = resp;
    });
  }

  setStudyDefaults(data: any) {
    this.studyInfo.budgetTypeId = 1;
    this.studyInfo.phaseTypeId = 1;
    this.studyInfo.multiSingleCenterId = 1;
    this.studyInfo.patientTypeId = 1;
    this.studyInfo.lengthOfTimeBetweenVisitsUnitId = 2;
    this.studyInfo.lengthOfStudyUnitId = 2;
    this.studyInfo.lengthOfTimeBetweenVisits = 1;
    this.studyInfo.studyTypeDetailsID = 0;
    this.studyInfo.sourceType = 'A';
    this.sessionData = this.sessionData === null ? data['SessionInfo'] : this.sessionData;
    this.studyPopulationTypes = data['StudyPopulationTypes'];
  }

  ngOnInit() {
    const context = this;
    this.UpdateStudyDetailsInfoSubscription();
    this.validateCanEditPermission();
    this.validateCanDeletePermission();
    this.validateCanAgreePermission();
    this.evaluateIfUserHasAccessToPaymentTerms();
    this.accountService.getStudySponsorCustomization().subscribe(response => {
      context.showBMARCREF = response['ShowBMARCREF'];
      context.showSVTVersion = response['ShowSVTVersion'];
      context.showRevision = response['ShowRevision'];
      context.showClinAdminExport = response['ShowClinAdminExport'];

      context.setupForm();
    }, error => {
      console.log('An error occurred getting the sponsor customization.  ' + error.message);
    });
  }

  ngOnDestroy() {
    this.updateStudyDetailsSubscription.unsubscribe();
    this.hasTabAccessSubscription.unsubscribe();
    this.hasEditPermissionSubscription.unsubscribe();
    this.hasDeletePermissionSubscription.unsubscribe();
    this.hasAgreePermissionSubscription.unsubscribe();
  }

  setupForm() {
    if (this.isNewStudy) {
      this.isEditMode = true;
      this.initializeStudyDetails(true);
    } else {
      if (!this.studySecurityPermission) {
        this.studyService.getStudySecurityPermission().subscribe(permissions => {
          this.studySecurityPermission = permissions;
        }, error => {
          this.eventBus.emit(new Event(EventType.UnhandledException, {
            description: 'Unable to load study permissions',
            details: error
          }));
        });
      }
      this.changeDetectorRef.detectChanges();
      this.getStudyDetails();
    }

    this.initForm();

    const gSKBmarcrefControl = this.studyDetailsForm.get('GSKBmarcref');
    if (this.showBMARCREF) {
      gSKBmarcrefControl.setValidators(Validators.required);
    } else {
      gSKBmarcrefControl.clearValidators();
    }

    if (!this.isApproved.value) {
      this.approver.disable();
    }

  }

  initForm() {
    this.studyDetailsForm = this.formBuilder.group({
      GSKBmarcref: ['', this.showBMARCREF ? Validators.required : ''],
      approvalDateParsed: new Date,
      approver: '',
      budgetTypeId: [1, [ValueNotAllowedValidator.valueNotAllowed(1)]],
      budgetTypeName: '',
      compoundDrug: ['', Validators.maxLength(200)],
      createBySponsorContactId: 0,
      createName: '',
      createOn: new Date,
      createdOnParsed: new Date,
      dateOfFinalVersion: new Date,
      dateOfFinalVersionParsed: new Date,
      deleteFlag: false,
      isAgreed: false,
      isApproved: false,
      isArchived: false,
      isClinAdminExportFlag: false,
      lastModifiedByContactId: 0,
      lastModifiedParsed: new Date,
      lengthOfStudyUnitId: 2,
      lengthOfStudyUnitName: '',
      lengthOfStudyValue: [null, [Validators.required, Validators.min(1), Validators.maxLength(19)]],
      lengthOfTimeBetweenVisits: [1, [Validators.required, Validators.min(1)]],
      lengthOfTimeBetweenVisitsUnitId: 2,
      lengthOfTimeBetweenVisitsUnitName: '',
      masterBidGridBenchmarkVersionId: 0,
      modifyName: '',
      multiSingleCenterId: 1,
      multiSingleCenterName: '',
      patientTypeId: [1, [ValueNotAllowedValidator.valueNotAllowed(1)]],
      patientTypeName: '',
      pharmacologyStudyManager: ['', Validators.maxLength(200)],
      phaseGroupName: '',
      phaseName: ['', [ValueNotAllowedValidator.valueNotAllowed('')]],
      phaseTypeId: 1,
      revision: '',
      singlePatientDuration: [1, [Validators.required, Validators.min(1), Validators.maxLength(19)]],
      singlePatientDurationUnitId: 0,
      sites: [null, [Validators.maxLength(19), Validators.min(0)]],
      sourceType: 'A',
      sponsorId: 0,
      status: 0,
      studyCode: [null, { validators: [Validators.required, Validators.maxLength(50)], onlySelf: true }],
      studyId: 0,
      studyPopulationTypeId: false,
      studyPopulationTypeIdList: new FormArray([]),
      studyShortName: ['', Validators.maxLength(50)],
      studyTypeDetailsID: 0,
      studyTypeDetailsName: '',
      svtVersionId: [1, Validators.min(1)],
      title: '',
      totalOvernightStaysPerSubjectPatient: [null, Validators.min(0)],
      totalSubjectsPatients: null,
      totalVisitsPerSubjectPatient: [null, [Validators.required, Validators.min(1), Validators.maxLength(19)]],
      visits: 1
    });
  }

  getFormControl(key: string): AbstractControl {
    return this.studyDetailsForm.get(key);
  }

  getStudyDetails() {
    const context = this;
    this.studyDetailsService.GetStudyDetails().subscribe(response => {

      context.studyPopulationTypes = response['StudyPopulationTypes'];
      context.studyInfo.studyShortName = response.Data['StudyShortName'];
      context.studyShortName.setValue(response.Data['StudyShortName']);

      context.studyInfo.compoundDrug = response.Data['CompoundDrug'];
      context.compoundDrug.setValue(response.Data['CompoundDrug']);

      context.studyInfo.title = response.Data['Title'];
      context.title.setValue(response.Data['Title']);

      context.studyInfo.patientTypeName = response.Data['PatientTypeName'];

      context.studyInfo.phaseName = response.Data['PhaseName'];
      context.phaseName.setValue(response.Data['PhaseName']);

      context.studyInfo.budgetTypeName = response.Data['BudgetTypeName'];

      context.studyInfo.totalSubjectsPatients = response.Data['TotalSubjectsPatients'];
      context.totalSubjectsPatients.setValue(response.Data['TotalSubjectsPatients']);

      context.studyInfo.singlePatientDuration = response.Data['SinglePatientDuration'] === 0 ? 1 : response.Data['SinglePatientDuration'];
      context.studyInfo.lengthOfStudyUnitName = response.Data['LengthOFStudyUnitName'];

      context.studyInfo.lengthOfStudyValue = response.Data['LengthOFStudyValue'] === 0 ? 1 : response.Data['LengthOFStudyValue'];
      context.lengthOfStudyValue.setValue(response.Data['LengthOFStudyValue'] === 0 ? 1 : response.Data['LengthOFStudyValue']);

      context.studyInfo.lengthOfStudyUnitId = response.Data['LengthOFStudyUnitId'];
      context.lengthOfStudyUnitId.setValue(response.Data['LengthOFStudyUnitId']);

      context.studyInfo.visits = response.Data['Visits'];

      context.studyInfo.totalVisitsPerSubjectPatient = response.Data['TotalVisitsPERSubjectPatient'];
      context.totalVisitsPerSubjectPatient.setValue(response.Data['TotalVisitsPERSubjectPatient']);

      context.studyInfo.totalOvernightStaysPerSubjectPatient = response.Data['TotalOvernightStaysPERSubjectPatient'];
      context.totalOvernightStaysPerSubjectPatient.setValue(response.Data['TotalOvernightStaysPERSubjectPatient']);

      context.studyInfo.lengthOfTimeBetweenVisits = response.Data['LengthOFTimeBetweenVisits'] === 0
        ? 1 : response.Data['LengthOFTimeBetweenVisits'];
      context.lengthOfTimeBetweenVisits.setValue(response.Data['LengthOFTimeBetweenVisits'] === 0
        ? 1 : response.Data['LengthOFTimeBetweenVisits']);

      context.studyInfo.lengthOfTimeBetweenVisitsUnitName = response.Data['LengthOFTimeBetweenVisitsUnitName'];

      context.studyInfo.sourceType = response.Data['SourceType'];
      context.sourceType.setValue(response.Data['SourceType']);

      context.studyInfo.createName = response.Data['CreateName'];
      context.studyInfo.modifyName = response.Data['ModifyName'];

      context.studyInfo.createdOnParsed = new Date(parseInt(response.Data['CreateON'].substring(6), 10));
      context.studyInfo.lastModifiedParsed = new Date(parseInt(response.Data['LastModified'].substring(6), 10));
      context.studyInfo.approvalDateParsed = new Date(parseInt(response.Data['ApprovalDate'].substring(6), 10));

      context.studyInfo.dateOfFinalVersionParsed = new Date(parseInt(response.Data['DateOFFinalVersion'].substring(6), 10));
      context.dateOfFinalVersionParsed.setValue(new Date(parseInt(response.Data['DateOFFinalVersion'].substring(6), 10)));

      context.studyInfo.isClinAdminExportFlag = response.Data['ISClinadminExportFlag'] ? 'true' : 'false';
      context.isClinAdminExportFlag.setValue(response.Data['ISClinadminExportFlag'] ? 'true' : 'false');
      context.isClinAdminExportFlag.disable();

      context.studyInfo.svtVersionId = response.Data['SvtVersionId'] === 0 ? 1 : response.Data['SvtVersionId'];
      context.svtVersionId.setValue(response.Data['SvtVersionId'] === 0 ? 1 : response.Data['SvtVersionId']);

      context.studyInfo.pharmacologyStudyManager = response.Data['PharmacologyStudyManager'];
      context.pharmacologyStudyManager.setValue(response.Data['PharmacologyStudyManager']);

      context.studyInfo.sites = response.Data['Sites'];
      context.sites.setValue(response.Data['Sites']);

      context.studyInfo.studyPopulationTypeId = response.Data['StudyPopulationTypeId'];
      context.studyInfo.singlePatientDurationUnitId = response.Data['SinglePatientDurationUnitId'];
      context.studyInfo.sponsorId = response.Data['SponsorId'];
      context.studyInfo.masterBidGridBenchmarkVersionId = response.Data['MasterBidGridBenchmarkVersionId'];
      context.studyInfo.createBySponsorContactId = response.Data['CreateBySponsorContactId'];
      context.studyInfo.lastModifiedByContactId = response.Data['LastModifiedByContactId'];
      context.studyInfo.deleteFlag = response.Data['DeleteFlag'];
      context.studyInfo.multiSingleCenterId = response.Data['MultiSingleCenterId'];
      context.multiSingleCenterId.setValue(response.Data['MultiSingleCenterId']);
      context.studyInfo.multiSingleCenterName = response.Data['MultiSingleCenterName'];

      const averagePeriodBetweenVisits = (response['SessionPreferenceInfo'].filter(function (value: { [key: string]: string }) {
        return value['Key'] === 'AveragePeriodBetweenVisits';
      }));
      if (averagePeriodBetweenVisits && averagePeriodBetweenVisits.length > 0) {
        context.showAveragePeriodBetweenVisits = averagePeriodBetweenVisits[0].Value === 'true';
      }

      context.isShowApprovalModule = response['IsShowApprovalModule'];
      context.sessionData = response['SessionInfo'];

      context.studyInfo.studyCode = response.Data['StudyCode'];
      context.studyCode.setValue(response.Data['StudyCode']);

      context.oldStudyCode = response.Data['StudyCode'];
      context.oldGSKBmarcref = response.Data['GSKBmarcref'];
      context.studyInfo.isArchived = response['IsArchivedStudy'];
      context.studyInfo.isAgreed = response['IsAgreedStudy'];
      context.studyInfo.GSKBmarcref = response.Data['GSKBmarcref'];
      context.GSKBmarcref.setValue(response.Data['GSKBmarcref']);

      context.studyInfo.revision = response.Data['Revision'];
      context.revision.setValue(response.Data['Revision']);

      context.studyInfo.phaseGroupName = response.Data['PhaseGroupName'];
      context.studyInfo.lengthOfStudyValue = response.Data['LengthOFStudyValue'];
      context.studyInfo.visits = response.Data['Visits'];

      context.studyInfo.approver = response.Data['Approver'];
      context.approver.setValue(response.Data['Approver']);

      context.studyInfo.isApproved = response.Data['IsApproved'];
      context.isApproved.setValue(response.Data['IsApproved']);
      context.isApproved.value ? context.approver.enable() : context.approver.disable();

      context.studyInfo.budgetTypeId = response.Data['BudgetTypeId'];
      context.budgetTypeId.setValue(response.Data['BudgetTypeId']);

      context.studyInfo.patientTypeId = response.Data['PatientTypeId'];
      context.patientTypeId.setValue(response.Data['PatientTypeId']);

      context.studyInfo.studyTypeId = response.Data['StudyTypeId'];
      context.studyInfo.studyTypeDetailsName = response.Data['StudyTypeDetailsName'];
      context.studyInfo.studyTypeDetailsID = response.Data['StudyTypeDetailsID'];
      context.studyTypeDetailsID.setValue(response.Data['StudyTypeDetailsID']);

      context.studyInfo.lengthOfTimeBetweenVisitsUnitId = response.Data['LengthOFTimeBetweenVisitsUnitId'];
      context.lengthOfTimeBetweenVisitsUnitId.setValue(response.Data['LengthOFTimeBetweenVisitsUnitId']);

      context.studyInfo.studyId = response.Data['StudyId'];
      context.studyInfo.status = response.Data['Status'];
      context.sharedDataService.StudyCode = response.Data['StudyCode'];

      context.sharedDataService.PhaseGroupName = context.studyInfo.phaseGroupName;

      // Set study code and GSKBmarcref values to what comes back from database.
      // These will be used to compare when changed, to run validation if different from original value in db.
      context.initializedStudyCode = response.Data['StudyCode'];
      context.initializedBmarcref = response.Data['GSKBmarcref'];

      context.initPhase();

      (<any>window).additionalInfo = context.studyInfo.GSKBmarcref !== '' ? context.studyInfo.GSKBmarcref : context.studyInfo.revision;
      this.studyCodeService.emit(context.studyInfo.studyCode);
      // if this is the user's first new study and the triggerAppcuesEven from _MasterLayout view exists
      if (response['ContactFirstNewStudy']) { // & typeof) TODO: revisit
        (<any>window).triggerAppcuesEvent('User_First_New_Study');
        context.unSetFirstNewStudyFlag();
      }

      this.eventBus.emit(new Event(EventType.GetStudyDetailsDone));
    });
  }

  emitEventForHandlingSpinner(eventType: EventType) {
    this.eventBus.emit(new Event(eventType));
  }

  initializeStudyDetails(isNew: boolean) {
    const context = this;
    context.loadingService.show();
    this.studyDetailsService.InitStudyDetails(isNew).subscribe(response => {
      this.newStudyDetailsData.budgetTypes = response['BudgetTypes'];
      this.newStudyDetailsData.lenghtofStudyUnitInfo = response['LengthofStudyUnitInfo'];
      this.newStudyDetailsData.lengthOfTimeBetweenStudyUnits = response['LengthOfTimeBetweenVisitsUnitInfo'];
      this.newStudyDetailsData.multiSingleCenterInfo = response['MultiSingleCenterInfo'];
      this.newStudyDetailsData.patientTypeInfo = response['PatientTypeInfo'];
      this.newStudyDetailsData.phaseTypes = response['PhaseTypes'];
      this.newStudyDetailsData.sourceTypeInfo = response['SourceTypeInfo'];
      this.newStudyDetailsData.studyPopulationTypes = response['StudyPopulationTypes'];
      this.newStudyDetailsData.studyTypeDetails = response['StudyTypeDetails'];

      if (this.isNewStudy) {
        this.sessionData = this.sessionData === undefined ? response['SessionInfo'] : this.sessionData;
        this.showAveragePeriodBetweenVisits = response['SessionInfo'].showAveragePeriodBetweenVisits === 'true';
        this.setStudyDefaults(response);
      } else {
        this.studyPopulationTypes = response['StudyPopulationTypes'];
        const studyPopulatioType: FormArray = context.studyDetailsForm.get('studyPopulationTypeIdList') as FormArray;

        for (let i = 0; i < context.studyPopulationTypes.length; i++) {
          if (context.studyPopulationTypes[i].Value.Item2) {
            studyPopulatioType.push(new FormControl(context.studyPopulationTypes[i].Key));
          }
        }
      }
      this.loadingService.hide();
    },
      err => {
        console.log('Unable to save line items: ' + err.message);
        this.loadingService.hide();
      });
  }

  unSetFirstNewStudyFlag() {
    this.studyDetailsService.UnSetFirstNewStudyFlag().subscribe();
  }

  initPhase() {
    const selected = this.phaseName.value;
    // The list of phases that display fields: multi/single center, sites and pharmacology study manager
    const phase = ['0', 'I/II', 'I', 'IIa'];
    if (phase.indexOf(selected) > -1) {
      this.phaseUpdate = true;
      this.initSites();
    } else {
      this.phaseUpdate = false;
    }
    this.phaseUpdateText = this.phaseUpdate ? 'totalVisitsPerSubjectPatient' : 'visits';
  }

  initSites() {
    const selected = parseInt(this.studyDetailsForm.value.multiSingleCenterId, 10);
    // When multi center is selected, sites field is displayed
    this.multiCenterUpdate = (selected === 2);  // id for multicenter
  }

  updateEditFlags(isEditMode: boolean) {
    this.isEditMode = isEditMode;
    this.isNewStudy = false;
    this.sharedDataService.IsEditMode = isEditMode;
    if (isEditMode) {
      this.isClinAdminExportFlag.enable();
    } else {
      this.isClinAdminExportFlag.disable();
    }
  }

  editStudy() {
    this.studyInfo_cancelCopy = cloneDeep(this.studyInfo);
    this.studyPopulationTypesAndValues_cancelCopy = cloneDeep(this.studyPopulationTypes);
    this.updateEditFlags(true);
    this.initializeStudyDetails(false);
  }

  edit() {
    if (this.sharedDataService.IsComplexityApplied) {
      this.confirmModal.show('Study Details Edit Confirmation',
        'Edits to this section may affect study complexity. It is suggested that you undo complexity in this study and re-apply',
        true).then(
          result => {
            if (result === 'Modal closed with OK.') {
              this.editStudy();
            }
          });
    } else {
      this.editStudy();
    }
  }

  cancel() {
    if (!this.isNewStudy) {
      this.studyInfo = this.studyInfo_cancelCopy;
      this.studyDetailsForm.patchValue(this.studyInfo_cancelCopy);
      this.isApproved.value ? this.approver.enable() : this.approver.disable();
      this.studyPopulationTypes = this.studyPopulationTypesAndValues_cancelCopy;
      this.updateEditFlags(false);
      this.unsavedChangesState.notify(false);
    }
  }

  isStudyCodeChanged() {
    return this.oldStudyCode.toLowerCase() !== this.studyInfo.studyCode.toLowerCase();
  }

  isBmarcrefChanged() {
    return this.oldGSKBmarcref.toLowerCase() !== this.studyInfo.GSKBmarcref.toLowerCase();
  }

  submit(form: NgForm) {
    if (form.valid) {
      this.close();
      this.saveStudyDetails();
    } else {
      this.studyDetailsForm.markAllAsTouched();
    }
  }

  onInputFormChanges() {
    this.unsavedChangesState.notify(true);
  }

  onStudyCodeOrBmarcrefChange() {
    if (this.studyCode.value.toLowerCase() !== this.initializedStudyCode.toLowerCase() ||
      (this.GSKBmarcref.value.toLowerCase() !== this.initializedBmarcref.toLowerCase() &&
        this.showBMARCREF && this.sessionData.isGSK)) {
      this.studyDetailsService.IsStudyUnique(this.studyCode.value, this.GSKBmarcref.value).subscribe(res => {
        if (res === false) {
          if (this.studyCode.dirty) {
            this.studyCode.setErrors({ 'unique': true });
          }
          if (this.GSKBmarcref && this.GSKBmarcref.dirty) {
            this.GSKBmarcref.setErrors({ 'unique': true });
          }
        }
      });
    }
  }
  validateFormat(params) {
    if (!this.dateOfFinalVersionParsed.valid) {
      this.dialogService.show(
        'Date of Final Version Error',
        'Please enter the Date of Final Version for this study:(MM/DD/YYYY)',
        false,
        480,
        200);
    }
  }

  saveStudyDetails() {
    this.studyInfo.studyPopulationTypeIdList = this.getSelectedStudyPopulationTypes();

    const studyParm = {
      StudyId: this.studyInfo.studyId,
      StudyCode: this.studyCode.value,
      StudyShortName: this.studyShortName.value,
      CompoundDrug: this.compoundDrug.value,
      Title: this.title.value,
      BudgetTypeId: this.budgetTypeId.value,
      StudyTypeId: this.studyInfo.studyTypeId,
      PhaseName: this.phaseName.value,
      PatientTypeId: this.patientTypeId.value,
      MultiSingleCenterId: this.multiSingleCenterId.value,
      Visits: this.totalVisitsPerSubjectPatient.value,
      TotalSubjectsPatients: this.phaseUpdate ? this.totalSubjectsPatients.value : -1,
      LengthOfStudyValue: this.lengthOfStudyValue.value,
      LengthofStudyUnitId: this.lengthOfStudyUnitId.value,
      SinglePatientDuration: this.studyInfo.singlePatientDuration,
      TotalVisitsPerSubjectPatient: this.totalVisitsPerSubjectPatient.value,
      TotalOvernightStaysPerSubjectPatient: this.phaseUpdate ? this.totalOvernightStaysPerSubjectPatient.value : -1,
      CreateOn: this.studyInfo.createOn,
      LastModified: this.studyInfo.lastModifiedParsed,
      Status: this.studyInfo.status,
      LengthOfTimeBetweenVisits: this.lengthOfTimeBetweenVisits.value,
      DateOFFinalVersion: this.dateOfFinalVersionParsed.value,
      Revision: this.revision.value,
      IsClinAdminExportFlag: this.isClinAdminExportFlag.value,
      GSKBmarcref: this.GSKBmarcref.value,
      LengthOfTimeBetweenVisitsUnitId: this.lengthOfTimeBetweenVisitsUnitId.value,
      SourceType: this.sourceType.value,
      SvtVersionId: this.svtVersionId.value,
      StudyTypeDetailsID: this.studyTypeDetailsID.value,
      StudyPopulationTypeIdList: this.studyInfo.studyPopulationTypeIdList,
      IsApproved: this.isApproved.value,
      Approver: this.approver.value,
      ApprovalDate: this.studyInfo.approvalDateParsed,
      PharmacologyStudyManager: this.pharmacologyStudyManager.value,
      Sites: (this.phaseUpdate && this.multiCenterUpdate) ? this.sites.value : -1,
      StudyPopulationTypeId: this.studyInfo.studyPopulationTypeId,
      SinglePatientDurationUnitId: this.studyInfo.singlePatientDurationUnitId,
      SponsorId: this.studyInfo.sponsorId,
      MasterBidGridBenchmarkVersionId: this.studyInfo.masterBidGridBenchmarkVersionId,
      CreateBySponsorContactId: this.studyInfo.createBySponsorContactId,
      LastModifiedByContactId: this.studyInfo.lastModifiedByContactId,
      DeleteFlag: this.studyInfo.deleteFlag
    };

    this.loadingService.showLoadingAndSubscribeToEvent(EventType.LineItemsEndLoading);

    $('body').trigger('LineItemsBeginLoading');

    const context = this;

    this.studyDetailsService.SaveStudyDetails(studyParm).subscribe(response => {
      if (response['Result'] === true) {
        this.unsavedChangesState.notify(false);
        context.isEditMode = false;
        (<any>window).studyCode = context.studyInfo.studyCode;
        if (this.isNewStudy) {
          this.newStudyModalAccept.emit();
          this.studyDetailsService.InitializeSessionData(response['StudyId']).pipe(take(1)).subscribe();
          window.location.href = response['StudyDetailsUrl'];
        } else {
          context.updateEditFlags(false);
          context.oldStudyCode = context.studyInfo.studyCode;
          context.oldGSKBmarcref = context.studyInfo.studyCode;
          context.studyInfo.modifyName = response['ModifiedByUser'];
          context.studyInfo.lastModifiedParsed = new Date(parseInt(response['ModifiedDate'].substr(6), 10));

          context.getStudyDetails();
          this.eventBus.emit(new Event(EventType.StudyDetailsSaved));
        }
      } else {
        console.log('There was an error while saving information.');
      }
      $('body').trigger('LineItemsFinishedLoading');
    });
  }

  getSelectedStudyPopulationTypes() {
    const studyPopulationTypeIdList = [];
    for (let i = 0; i < this.studyPopulationTypes.length; i++) {
      if (this.studyPopulationTypes[i].Value.Item2) {
        studyPopulationTypeIdList.push(this.studyPopulationTypes[i].Key);
      }
    }
    return studyPopulationTypeIdList;
  }

  updatePhase() {
    this.onInputFormChanges();
    if (!this.isNewStudy) {
      this.dialogService.show('Edit Study', 'Note that applying this change may update the ' +
        'benchmark costs for this study. Do you want to proceed?', true, 600, 200).subscribe((response: any) => {
          if (response) {
            this.initPhase();
          } else {
            this.studyDetailsForm.get('phaseName').patchValue(this.studyInfo.phaseName);
          }
        });
    } else {
      this.initPhase();
    }

  }

  updateMultiCenter() {
    this.initSites();
    this.onInputFormChanges();
    const { multiSingleCenterId } = this.studyDetailsForm.value;
    if (multiSingleCenterId) {
      const multiCenterInfoFound = this.newStudyDetailsData.multiSingleCenterInfo.find(CurrentMultiSingleCenterInfo =>
        CurrentMultiSingleCenterInfo.MultiSingleCenterId === multiSingleCenterId);
      this.studyInfo.multiSingleCenterName = multiCenterInfoFound.MultiSingleCenterName;
    }
  }

  updatePatientType(form: FormGroup) {
    this.onInputFormChanges();
    const id = form.get('patientTypeId').value;
    if (id) {
      const patientInfoFound = this.newStudyDetailsData.patientTypeInfo.find(patientTypeInfo =>
        patientTypeInfo.PatientTypeId && patientTypeInfo.PatientTypeId.toString() === id);
      this.studyInfo.patientTypeName = patientInfoFound.PatientTypeName;
    }
    const control = this.getFormControl('patientTypeId');
    const errorObject = (control.value === '1') ? { required: true } : null;
    control.setErrors(errorObject);
  }

  updateBudgetType() {
    this.onInputFormChanges();
    const { budgetTypeId } = this.studyDetailsForm.value;

    if (budgetTypeId) {
      const budgetType = this.newStudyDetailsData.budgetTypes.find(bt =>
        bt.BudgetTypeId && bt.BudgetTypeId.toString() === budgetTypeId.toString());
      this.studyInfo.budgetTypeName = budgetType.BudgetTypeName;
    }
    const control = this.getFormControl('budgetTypeId');
    const errorObject = (control.value === 1) ? { required: true } : null;
    control.setErrors(errorObject);
  }

  updateStudyTypeDetails() {
    this.onInputFormChanges();
    const { studyTypeDetailsID } = this.studyInfo;
    if (studyTypeDetailsID) {
      const studyTypeDetail = this.newStudyDetailsData.studyTypeDetails.find(detail =>
        detail.StudyTypeDetailsID === studyTypeDetailsID);
      this.studyInfo.studyTypeDetailsName = studyTypeDetail.StudyTypeDetailsName;
    }
  }

  updateLengthOfStudyUnit() {
    this.onInputFormChanges();
    const { lengthOfStudyUnitId } = this.studyInfo;

    if (lengthOfStudyUnitId) {
      const lenghtofStudyUnitFound = this.newStudyDetailsData.lenghtofStudyUnitInfo.find(unitInfo =>
        unitInfo.LengthOFStudyUnitId === lengthOfStudyUnitId);
      this.studyInfo.lengthOfStudyUnitName = lenghtofStudyUnitFound.LengthOFStudyUnitName;
    }
  }

  updateLengthOfTimeBetweenVisits() {
    this.onInputFormChanges();
    const { lengthOfTimeBetweenVisitsUnitId } = this.studyInfo;

    if (lengthOfTimeBetweenVisitsUnitId) {
      const lengthOfTimeBetweenStudyUnitsFound = this.newStudyDetailsData.lengthOfTimeBetweenStudyUnits.find(studyUnit =>
        studyUnit.LengthOFTimeBetweenVisitsUnitId === lengthOfTimeBetweenVisitsUnitId);
      this.studyInfo.lengthOfTimeBetweenVisitsUnitName = lengthOfTimeBetweenStudyUnitsFound.LengthOFTimeBetweenVisitsUnitName;
    }
  }

  approveStudy() {
    const contactname = this.sessionData.ContactName;

    if (this.approver.value === '' && this.isApproved.value === true) {
      if (contactname != null) {
        this.approver.setValue(contactname);
      }
      this.approver.setValidators(Validators.required);
      this.studyInfo.approvalDateParsed = new Date();
      this.approver.enable();
    }

    if (this.isApproved.value === false) {
      this.approver.clearValidators();
      this.approver.setValue('');
      this.studyInfo.approver = '';
      this.approver.disable();
    }
    this.onInputFormChanges();
    this.approver.updateValueAndValidity();
  }

  onApproverChange() {
    const approverFormControl = this.approver;
    if (approverFormControl.value === '') {
      approverFormControl.setValidators(Validators.required);
    } else {
      approverFormControl.clearValidators();
    }

    approverFormControl.updateValueAndValidity();
  }

  saveDetails(form: NgForm) {
    this.submit(form);
  }

  showExportToExcelModal() {
    const sponsorName: string = this.sessionData.SponsorName;
    const exportOptions: ExportToExcelOptions = {
      id: 'export-to-excel',
      name: 'Study Details',
      shortName: 'Study Details',
      tabName: 'studydetail',
      origin: 'sponsor',
      showCountryInfo: true,
      showInvestigatorInfo: false,
      selectedCountry: (!isNil(this.sharedDataService.SelectedCountry)) ? this.sharedDataService.SelectedCountry.CountryShortId : 'ALL'
    };

    this.exportToExcelDialog.showDialog(sponsorName, exportOptions);
  }

  private confirmToggleAction(toggleState, trueOptions, falseOptions) {
    const options = toggleState === true ? trueOptions : falseOptions;
    this.confirmModal.show(options.title, options.message, true)
      .then(options.handler)
      .catch(options.cancel);
  }

  public toggleArchiveStudy($event) {
    this.studyInfo.isArchived = $event;
    this.confirmToggleAction(
      $event,
      {
        title: 'Archive Study Confirmation',
        message: 'Click OK to Archive this study. You can search all Archived studies by selecting Open Archived Studies in the Study menu',
        handler: this.archiveStudy,
        cancel: this.undoArchiveStateChange
      },
      {
        title: 'Unarchive Study Confirmation',
        message: 'Click OK to Un-archive this study so it appears in the regular Study List',
        handler: this.unarchiveStudy,
        cancel: this.undoArchiveStateChange
      });
  }

  archiveStudy = () => {
    this.loadingService.showLoadingAndSubscribeToEvent(EventType.GetStudyDetailsDone);
    this.studyDetailsService.ArchiveStudy().subscribe(response => {
      if (response) {
        this.getStudyDetails();
      }
    });
  }

  unarchiveStudy = () => {
    this.loadingService.showLoadingAndSubscribeToEvent(EventType.GetStudyDetailsDone);
    this.studyDetailsService.UnArchiveStudy().subscribe(response => {
      if (response) {
        this.getStudyDetails();
      }
    });
  }

  undoArchiveStateChange = () => {
    this.studyInfo.isArchived = !this.studyInfo.isArchived;
  }

  toggleAgreeStudy($event) {
    this.studyInfo.isAgreed = $event;
    this.confirmToggleAction(
      $event,
      {
        title: 'Agree Study Confirmation',
        message: 'Click OK to set this study to Agreed',
        handler: this.agreeStudy,
        cancel: this.undoAgreeStateChange
      },
      {
        title: 'Disagree Study Confirmation',
        message: 'Click OK to set this study to not agreed',
        handler: this.disagreedStudy,
        cancel: this.undoAgreeStateChange
      });
  }

  agreeStudy = () => {
    this.loadingService.showLoadingAndSubscribeToEvent(EventType.GetStudyDetailsDone);
    this.studyDetailsService.AgreeStudy().subscribe(response => {
      if (response['Result'] === 1) {
        this.getStudyDetails();
      }
    });
  }

  disagreedStudy = () => {
    this.loadingService.showLoadingAndSubscribeToEvent(EventType.GetStudyDetailsDone);
    this.studyDetailsService.DisagreeStudy().subscribe(response => {
      if (response['Result'] === 1) {
        this.getStudyDetails();
      }
    });
  }

  undoAgreeStateChange = () => {
    this.studyInfo.isAgreed = !this.studyInfo.isAgreed;
  }

  setDateToToday() {
    this.studyInfo.dateOfFinalVersionParsed = new Date();
  }

  deleteStudy() {
    this.confirmModal.show('Delete Study Confirmation', 'Click OK to permanently delete this study from GrantPlan', true).then(
      () => {
        this.loadingService.show();
        this.studyDetailsService.DeleteStudy().subscribe(response => {
          if (response['Result'] === 'true') {
            window.location.href = response['StudyListUrl'];
          }
        });
      }
    ).catch((res) => {
      console.log(res);
    });
  }

  /*rollback changes*/
  close() {
    this.newStudyModalCancel.emit();
    this.unsavedChangesState.notify(false);
    $('body').removeClass('customScroll');
  }

  studyPopulationTypeChange(e: MatCheckboxChange) {
    this.onInputFormChanges();
    const chkIndex = this.studyPopulationTypes.findIndex(x => x.Key === e.source.value);
    const studyPopulationType: FormArray = this.studyDetailsForm.get('studyPopulationTypeIdList') as FormArray;
    if (e.checked) {
      studyPopulationType.push(new FormControl(e.source.value));
      this.studyPopulationTypes[chkIndex].Value.Item2 = true;
    } else {
      const index = studyPopulationType.controls.findIndex(x => x.value === e.source.value);
      studyPopulationType.removeAt(index);
      this.studyPopulationTypes[chkIndex].Value.Item2 = false;
    }
  }
}
