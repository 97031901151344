import { Injectable } from '@angular/core';
import { Event, EventType } from '../models/events';
import { EventBusService } from '../services/event-bus.service';

@Injectable({
    providedIn: 'root'
})

export class BeforeunloadNotifierStateService {

    constructor(private eventBus: EventBusService) { }

    notify (value: boolean): void {
        this.eventBus.emit(new Event(EventType.ValidateBeforeunload, value));
    }
}
