import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CopyStudyComponent } from './components/copy-study-component/copy-study.component'; 
import { AngularMaterialModule } from '../third-party-components/angular-material/angular-material.module';

@NgModule({
  declarations: [CopyStudyComponent],
  imports:[
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  entryComponents: [CopyStudyComponent],
  exports: [CopyStudyComponent]
})
export class StudyModule { }
