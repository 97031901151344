import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { CountryNode } from '../../../models/country-node'; 
import { CountryFlatNode } from '../../../models/country-flat-node';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
    selector: 'gp-ui-app-countries-tree',
    templateUrl: './countries-tree.component.html',
    styleUrls: ['./countries-tree.component.css']
})
export class CountriesTreeComponent implements OnInit, OnChanges {

    @Input() countries: any;
    @Input() searchText: string;
    @Input() isAvailableComponent: boolean;
    @Input() isUSSelected: boolean;
    @Output() addSelected = new EventEmitter();

    data: any;

    /** Map from flat node to nested node. This helps us finding the nested node to be modified */
    flatNodeMap = new Map<CountryFlatNode, CountryNode>();

    /** Map from nested node to flattened node. This helps us to keep the same object for selection */
    nestedNodeMap = new Map<CountryNode, CountryFlatNode>();

    /** The selection for checklist */
    checklistSelection = new SelectionModel<CountryFlatNode>(true);

    dataSource: MatTreeFlatDataSource<CountryNode, CountryFlatNode>;

    constructor() {
        this.treeFlattener = new MatTreeFlattener(this._transformer, this.getLevel,
            this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl<CountryFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    }

    ngOnInit() {
        this.data = this.countries;
    }

    checkUSInSelected(node: CountryFlatNode) {
        if (node.shorID === 'USA' && this.isUSSelected && this.isAvailableComponent) {
            return true;
        }
        return false;
    }

    private _transformer = (node: CountryNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.name === node.name
            ? existingNode
            : new CountryFlatNode();
        flatNode.name = node.name;
        flatNode.level = level;
        flatNode.shorID = node.shortID;
        flatNode.expandable = !!node.regions;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    }

    getLevel = (node: CountryFlatNode) => node.level;

    getChildren = (node: CountryNode): CountryNode[] => node.regions;

    isExpandable = (node: CountryFlatNode) => node.expandable;

    // tslint:disable-next-line: member-ordering
    treeControl = new FlatTreeControl<CountryFlatNode>(node => node.level, node => node.expandable);

    // tslint:disable-next-line: member-ordering
    treeFlattener = new MatTreeFlattener(this._transformer, node => node.level, node => node.expandable, node => node.regions);

    hasChild = (_: number, node: CountryFlatNode) => node.expandable;

    addSelectedDoubleClick() {
        if (this.isAvailableComponent) {
            this.addSelected.emit();
        }
    }

    ngOnChanges(change: SimpleChanges) {
        if (change['isUSSelected']) {
            this.isUSSelected = change['isUSSelected'].currentValue;
        }
        if (change['countries']) {
            this.data = this.countries;
            this.dataSource.data = this.getFilteredData(this.searchText, JSON.parse(JSON.stringify(change['countries'].currentValue)));
            this.treeControl.expandAll();
            this.checklistSelection = new SelectionModel<CountryFlatNode>(true);
        }
        if (change['searchText']) {
            this.searchText = change['searchText'].currentValue;
            if (this.searchText) {
                let dataCopy = JSON.parse(JSON.stringify(this.data));
                dataCopy = dataCopy.filter(country => {
                    if (country.regions !== undefined) {
                        const regions = country.regions.filter(region => {
                            return region.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1;
                        });
                        country.regions = regions;
                        return country.regions.length > 0 || country.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1;
                    }
                    return country.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1;
                });
                this.dataSource.data = JSON.parse(JSON.stringify(dataCopy));
                this.treeControl.expandAll();
            } else {
                if (this.data !== undefined) {
                    this.dataSource.data = JSON.parse(JSON.stringify(this.data));
                }
                this.treeControl.expandAll();
            }
        }
    }

    getFilteredData(searchText, data) {
        if (searchText && data !== undefined) {
            let dataCopy = JSON.parse(JSON.stringify(data));
            dataCopy = dataCopy.filter(country => {
                if (country.countries !== undefined) {
                    const countries = country.countries.filter(region => {
                        return region.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1;
                    });
                    if (countries.length > 0) {
                        country.regions = countries;
                    }
                    return countries.length > 0;
                }
            });
            return dataCopy;
        }
        return data;
    }

    countryLeafItemSelectionToggle(node: CountryFlatNode, event): void {
        if (event.ctrlKey !== true) {
            this.checklistSelection.clear();
        }
        this.checklistSelection.toggle(node);
        this.checkAllParentsSelection(node);
    }

    descendantsAllSelected(node: CountryFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        return descAllSelected;
    }

    countryItemSelectionToggle(node: CountryFlatNode, event): void {
        if (event.ctrlKey !== true) {
            this.checklistSelection.clear();
        }
        this.checklistSelection.toggle(node);
        /*const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
          ? this.checklistSelection.select(...descendants)
          : this.checklistSelection.deselect(...descendants);*/

        // Force update for the parent
        /*descendants.every(child =>
          this.checklistSelection.isSelected(child)
        );*/
        this.checkAllParentsSelection(node);
    }

    checkAllParentsSelection(node: CountryFlatNode): void {
        let parent: CountryFlatNode | null = this.getParentNode(node);
        if (this.isAvailableComponent) {
        while (parent) {
                this.checkRootNodeSelection(parent);
                parent = this.getParentNode(parent);
            }
        }
    }

    checkRootNodeSelection(node: CountryFlatNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.some(child =>
            this.checklistSelection.isSelected(child)
        );
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }

    getParentNode(node: CountryFlatNode): CountryFlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    public getSelectionList(): Array<any> {
        const regions = new Array<CountryNode>();
        this.checklistSelection.selected.forEach(item => {
            const countryNode: CountryNode = { name: item.name, shortID: item.shorID, isSelected: false, regions: [] };
            this.treeControl.getDescendants(item).forEach(child => {
                if (this.checklistSelection.selected.indexOf(child) > -1) {
                    countryNode.regions.push({
                        name: child.name,
                        shortID: child.shorID,
                        isSelected: false
                    });
                }
            });
            regions.push(countryNode);
        });
        return regions;
    }

    public selectAllCountries(length) {
        this.dataSource.data.forEach(country => {
            const node: CountryFlatNode = new CountryFlatNode();
            node.name = country.name;
            node.shorID = country.shortID;
            node.expandable = country.shortID === 'USA' ? true : false;
            node.level = 0;
            this.checklistSelection.select(node);
            if (country.shortID === 'USA' && length > 0) {
                country.regions.forEach(region => {
                    const nodeRegion: CountryFlatNode = new CountryFlatNode();
                    nodeRegion.name = region.name;
                    nodeRegion.shorID = region.shortID;
                    nodeRegion.level = 1;
                    nodeRegion.expandable = false;
                    this.checklistSelection.select(nodeRegion);
                });
            }
        });
    }

}
