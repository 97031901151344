import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainRoutingModule } from './main-routing.module';

const routes: Routes = [
  {path: 'login',  loadChildren: () => import ("../../projects/login/src/app/app.module").then(m => m.LoginModule) },
  {path: '',  loadChildren: () => import ("src/app/main-routing.module").then(m => m.MainRoutingModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'}), MainRoutingModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }