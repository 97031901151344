import { NgModule } from '@angular/core';
import { ExportToExcelComponent } from './components/export-to-excel-component/export-to-excel.component';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../third-party-components/angular-material/angular-material.module';

@NgModule({
  declarations: [ExportToExcelComponent],
  imports:[
    CommonModule,
    AngularMaterialModule
  ],
  entryComponents: [ExportToExcelComponent],
  exports: [ExportToExcelComponent]
})
export class ExcelModule { }
