import { Injectable } from '@angular/core';
import { HttpService } from '../../../common/general/services/http.service';
import { Observable } from 'rxjs/internal/Observable';
import { Tab } from 'projects/shared/src/app/constants/Tab';

@Injectable({
  providedIn: 'root'
})
export class SelectivePrivilegesService {

  constructor(private httpService: HttpService) { }

  hasTabAccess(tab: Tab): Observable<boolean> {
		const url = `./SelectivePrivileges/HasTabAccess?tab=${tab}`;;
		return this.httpService.safeGet<boolean>({ url });
	}
}
