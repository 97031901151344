import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

import { ExportToExcelOptions } from '../models/export-to-excel-options';
import { ExportToExcelComponent } from '../components/export-to-excel-component/export-to-excel.component'; 

@Injectable(
  {
    providedIn: 'root'
  })
export class ExportToExcelDialogService {
  constructor(private dialog: MatDialog) {}

  showDialog(sponsorName: string, options: ExportToExcelOptions, showDNV = false) {
    const dialogConfig: MatDialogConfig = {
      width: '550px',
      autoFocus: false,
      disableClose: true,
      role: 'dialog',
      panelClass: 'custom-modalbox'
    };

    const exportDialogRef = this.dialog.open(ExportToExcelComponent, dialogConfig);
    exportDialogRef.componentInstance.sponsorName = sponsorName;
    exportDialogRef.componentInstance.exportOptions = options;
    exportDialogRef.componentInstance.showDNV = showDNV;
    exportDialogRef.componentInstance.closeModal.subscribe(() => exportDialogRef.close());
  }
}
