import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component(
  {
    selector: 'gp-ui-substudy-name-edit-renderer',
    templateUrl: './selected-grid-substudy-name-renderer.component.html'
  })
export class SelectedGridSubstudyNameRendererComponent implements ICellRendererAngularComp {
  private rendererParams: ICellRendererParams = undefined;

  agInit(params: ICellRendererParams): void {
    this.rendererParams = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.rendererParams = params;

    return true;
  }

  handleSubstudyNameChange(event: any) {
    this.rendererParams.data[this.rendererParams.colDef.field] = event.target.value;
  }

  get renderedParamsOnScreen(): ICellRendererParams { return this.rendererParams; }
}
