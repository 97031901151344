import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component(
  {
    styleUrls: ['../cell-renderers.css'],
    selector: 'gp-ui-grid-substudy-name-renderer',
    templateUrl: './grid-substudy-name-renderer.component.html'
  })
export class GridSubstudyNameRendererComponent implements ICellRendererAngularComp {
  private rendererParams: ICellRendererParams = undefined;

  agInit(params: ICellRendererParams): void {
    this.rendererParams = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.rendererParams = params;

    return true;
  }

  onSubstudyNameChange(event: any) {
    this.rendererParams.data[this.rendererParams.colDef.field] = event.target.value;
    this.rendererParams.context.parent.updateSubstudyName(this.rendererParams.data['SUBStudyId'], event.target.value);
  }
  get editMode(): boolean {return this.rendererParams.context.isEditMode; }
  get inputValue(): string {return this.rendererParams.value; }
}
