import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from 'projects/shared/src/app/common/general/services/http.service';
import { APIResponse } from 'projects/shared/src/app/models/user/api-response.model';
import { StudyVisit } from '../models/visits/study-visit.model';
import { VisitsInfoModel } from '../models/visits/visits-info.model';
import { ActivityResponse } from '../models/activities/activities-vs-table.model';
import { LoadingService } from 'projects/shared/src/app/common/loading/services/loading.service';
import { transformStringToBoolean } from '../forms-functions/utility-functions';

@Injectable({
  providedIn: 'root'
})
export class VisitScheduleService {

  private readonly API_ENDPOINT: string = './VisitScheduleAPI';

  constructor(
    private httpService: HttpService,
    private loadingService: LoadingService
  ) { }

  public getVisits(countryShortId: string, subStudyId: number): Observable<VisitsInfoModel> {
    const url: string = `${this.API_ENDPOINT}/GetVisits`;
    const options = {
      params: new HttpParams().set('countryShortId', countryShortId).set('subStudyId', subStudyId.toString())
    };

    return this.httpService.safeGet<VisitsInfoModel>({ url, options })
      .pipe(
        map((result: any) => { return result.data; })
      );
  }

  public updateVisits(editedStudyVisits: StudyVisit[], originalStudyVisits: StudyVisit[]): Observable<APIResponse> {
    const url: string = `${this.API_ENDPOINT}/UpdateVisits`;
    const body = { editedStudyVisits, originalStudyVisits };

    return this.httpService.safePost<APIResponse>({ url, body });
  }

  public deleteVisits(visitsToDelete: StudyVisit[], keepDNV: boolean): Observable<APIResponse> {
    const url: string = `${this.API_ENDPOINT}/DeleteVisits`;
    const body = {
      visitsToDelete,
      bankingFlag: keepDNV
    };

    return this.httpService.safePost<APIResponse>({ url, body });
  }

  public saveCopyVisits(
    modifiedStudyVisitList: StudyVisit[],
    countryShortId: string,
    numCopies: number,
    originalStudyVisitList: StudyVisit[]): Observable<VisitsInfoModel> {
    const request = {
      url: `${this.API_ENDPOINT}/SaveCopyVisits`,
      body: { modifiedStudyVisitList, countryShortId, numCopies, originalStudyVisitList}
    }
    return this.loadingService.waitObservableWithSpinner(
      this.httpService.safePost<any[]>(request)
    );
  }

  public getActivities(countryShortId: string, subStudyId: number, currency: string): Observable<ActivityResponse> {
    const url: string = `${this.API_ENDPOINT}/getActivitiesByStudyId`;
    const options = {
      params: new HttpParams().set('countryShortId', countryShortId)
        .set('subStudyId', subStudyId.toString()).set('currency', currency)
    };
    return this.loadingService.waitObservableWithSpinner(
      this.httpService.safeGet<NonNullable<any>>({ url, options }).
        pipe(
          map<NonNullable<any>, ActivityResponse>((response: any) => {
            return {
              ...response.data,
              hasDNV: transformStringToBoolean(response.data.hasDNV)
            }
          })
        )
    );

  }

  public getStudyNote(countryCode: string, subStudyId: number) {
    const url: string = `${this.API_ENDPOINT}/GetStudyNote`;
    const options = {
      params: new HttpParams().set('countryShortId', countryCode).set('subStudyId', subStudyId.toString())
    };
    return this.httpService.safeGet({ url, options });
  }

  public getAllVisits(): Observable<any[]> {
    const url: string = `${this.API_ENDPOINT}/GetALLVisitsBySponsorId`;
    return this.httpService.safeGet<any[]>({ url });
  }

  public getStudyVisits(): Observable<any[]> {
    const url: string = `${this.API_ENDPOINT}/GetStudyVisits`;
    return this.httpService.safeGet<any[]>({ url });
  }

  public saveStudyVisits(
    selectedStudyVisits: Array<any>,
    deletedStudyVisits: Array<any>,
    subStudyId: number,
    countryShortId: string): Observable<any[]> {
    const request = {
      url: `${this.API_ENDPOINT}/SaveStudyVisits`,
      body: {
        selectedStudyVisits,
        deletedStudyVisits,
        subStudyId,
        countryShortId
      }
    };
    return this.httpService.safePost<any[]>(request)

  }

  public getUpperLimitSettings(): Observable<any[]> {
    const url: string = `${this.API_ENDPOINT}/GetUpperLimitSettings`;
    return this.httpService.safeGet<any[]>({ url });
  }

  public saveVisitSchedule(
    sbLst: Array<any>,
    vsLst: Array<any>,
    nowCountryId: string,
    subStudyId: number,
    regionId: string,
    viewType: number,
    jobId: string,
    ulState: boolean): Observable<any[]> {
    const request = {
      url: `${this.API_ENDPOINT}/SaveVisitSchedule`,
      body: {
        sbLst,
        vsLst,
        nowCountryId,
        subStudyId,
        regionId,
        viewType,
        jobId,
        ulState
      }
    };
    return this.loadingService.waitObservableWithSpinner(
      this.httpService.safePost<any[]>(request)
    );
  }
}