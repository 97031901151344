import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { IColumnDefinition, IMultiselectUIOptions } from '../../../models/multiselect-add-remove-component-options/multiselect-options';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.css']
})
export class MultiselectComponent<T> implements OnInit, OnChanges {

  @Input() multiselectOptions: IMultiselectUIOptions<T>;
  @Input() isDisabled: boolean = false;
  @Input() isTableNode: boolean = false;
  @Output() outputDataEvent: EventEmitter<T[]> = new EventEmitter<T[]>();
  @ViewChild('available', { static: false }) availableItemsTree: any;
  @ViewChild('selected', { static: false }) selectedItemsTree: any;

  availableItems: T[] = [];
  selectedItems: T[] = [];
  originalAvailableItems: T[] = [];
  originalSelectedItems: T[] = [];
  searchTextForAvailable: string = '';
  searchTextForSelected: string = '';
  availableDisclaimerText: string;
  selectedDisclaimerText: string;
  compare: (x: T, y: T) => Number;
  search: (x: T[], y: string) => T[];
  columDefs?: IColumnDefinition[] = [];

  constructor() { }

  ngOnInit() {
    this.availableDisclaimerText = this.multiselectOptions.availableDisclaimerText;
    this.selectedDisclaimerText = this.multiselectOptions.selectedDisclaimerText;
    this.availableItems = this.multiselectOptions.data.allAvailableItems;
    this.selectedItems = this.multiselectOptions.data.selectedItems;
    this.isDisabled = this.multiselectOptions.isDisabled ? this.multiselectOptions.isDisabled : this.isDisabled;
    this.compare = this.multiselectOptions.compare;
    this.search = this.multiselectOptions.search;
    this.columDefs = this.multiselectOptions.columDef;
    this.dataOutputChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.multiselectOptions && changes.multiselectOptions.currentValue) {
      const newOptions = changes.multiselectOptions.currentValue;
      this.availableItems = newOptions.data.allAvailableItems;
      this.selectedItems = newOptions.data.selectedItems;
      this.originalAvailableItems = this.availableItems;
      this.originalSelectedItems = this.selectedItems;
    }
    if (changes.isDisabled) {
      this.isDisabled = changes.isDisabled.currentValue;
      this.searchTextForAvailable = "";
      this.searchTextForSelected = "";
    }
  }

  // Buttons container actions
  addAllAvailableItems() {
    this.selectedItems = [...this.selectedItems, ...this.availableItems]
    this.availableItems = []
    this.clearSelectionAndEmitData(this.availableItemsTree.checklistSelection);
  }

  addAvailableItemsToSelectedList() {
    const [newOriginList, newDestinationList] = this.exchangeSelectedItemsFromLists(this.availableItems, this.selectedItems, this.availableItemsTree.checklistSelection);
    this.availableItems = newOriginList;
    this.selectedItems = newDestinationList;
    this.clearSelectionAndEmitData(this.availableItemsTree.checklistSelection);
  }

  removeSelectionFromSelectedList() {
    const [newOriginList, newDestinationList] = this.exchangeSelectedItemsFromLists(this.selectedItems, this.availableItems, this.selectedItemsTree.checklistSelection);
    this.selectedItems = newOriginList;
    this.availableItems = newDestinationList;
    this.clearSelectionAndEmitData(this.selectedItemsTree.checklistSelection);
  }

  removeAllSelectedItems() {
    this.availableItems = [...this.availableItems, ...this.selectedItems]
    this.selectedItems = [];
    this.clearSelectionAndEmitData(this.selectedItemsTree.checklistSelection);
  }

  // Buttons container states
  isAvailableListEmpty() { 
    return this.availableItems.length === 0;
  }

  hasAvailableListAnySelection() { 
    return this.availableItemsTree && this.availableItemsTree.checklistSelection.selected.length > 0;
  }

  hasSelectedListAnySelection() {
    return this.selectedItemsTree && this.selectedItemsTree.checklistSelection.selected.length > 0;
  }

  isSelectedListEmpty() { 
    return this.selectedItems.length === 0;
  }

  public revertChanges(): void {
    this.availableItems = this.originalAvailableItems;
    this.selectedItems = this.originalSelectedItems;

    this.clearSelection(this.availableItemsTree.checklistSelection);
    this.clearSelection(this.selectedItemsTree.checklistSelection);
    this.dataOutputChange();
	}

  private exchangeSelectedItemsFromLists(originList: T[], destination: T[], checklistSelection: SelectionModel<T>) : [T[], T[]]{
    const listWithItemsDeleted = this.deleteNodesFromList(checklistSelection.selected, originList);
    const newDestinationList = [...destination, ...checklistSelection.selected]
    return [listWithItemsDeleted, newDestinationList];
  }

  private clearSelectionAndEmitData(currentSelection: SelectionModel<T>){ 
    this.clearSelection(currentSelection);
    this.dataOutputChange();
  }

  private clearSelection(currentSelection: SelectionModel<T>) { 
    currentSelection.clear();
  }

  private dataOutputChange() {
    this.outputDataEvent.emit(this.selectedItems);
  }

  private deleteNodesFromList(itemsToDelete: T[], nodeList: T[]): T[]{
    return nodeList.filter(node => !itemsToDelete.includes(node));
  }
}
