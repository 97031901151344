import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { NavigationService } from '../header-navbar/services/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {

  constructor(
    private router: Router,
    private navigationService: NavigationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.navigationService.getMappingRedirect(state.url).pipe(map(mapping => {
      let activate = true;
      if (mapping) {
        if (mapping.navigateToClassic && mapping.classicUrl) {
          window.location.href = mapping.classicUrl;
          activate = false;
        } else if (!state.url.includes(mapping.url.replace("/Index", ""))) {
          this.router.navigate([mapping.url]);
          activate = false;
        }
      }
      return activate;
    }));
  }

  canDeactivate( ): Observable<boolean> {
    return this.navigationService.validateUnSavedChanges().pipe(map(response => !!response));
  }

}
