import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

export interface DropdownItem {
	text: string;
	value: any;
}

@Component({
	selector: 'gp-table-cell-select',
	templateUrl: './table-cell-select.component.html',
	styleUrls: ['./table-cell-select.component.css']
})
export class TableCellSelectComponent implements OnInit {

	@Input() isEditing: boolean;
	@Input() options: DropdownItem[] = [];
	@Input() selectedValue: any;
	@Input() width: number;

	@Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

	ngOnInit(): void { }

	onOptionChange(): void {
		this.valueChange.emit(isNaN(this.selectedValue) ? this.selectedValue : parseFloat(this.selectedValue));
	}

	getCurrentText(): string {
		const currentOption = this.options.find(x => x.value === this.selectedValue);
		return (currentOption) ? currentOption.text : '';
	}
}