import {Injectable} from '@angular/core';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../components/confirm-modal-component/confirm-modal.component';

@Injectable(
  {
    providedIn: 'root'
  })
export class ConfirmModalService {
  constructor(private modal: NgbModal) {}

  show(title: string, message: string, showCancel: boolean): Promise<any> {
    const modalOptions: NgbModalOptions = {
      centered: false,
      keyboard: false,
      windowClass: 'newModal',
      size: 'lg'
    };

    const modalRef = this.modal.open(ConfirmModalComponent, modalOptions);
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.showCancel = showCancel;

    return modalRef.result;
  }
}
