import { Injectable } from '@angular/core';
import { HttpService } from 'projects/shared/src/app/common/general/services/http.service'; 

@Injectable({providedIn: 'root'})
export class ClassicService {

  constructor(private httpService: HttpService) { }

  getClassicUrl() {
    const url = './Classic/GetClassicURL';
    return this.httpService.safeGet({url});
  }
}
