import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { CustomMaxDirective } from './general/directives/custom-max-validator.directive'; 
import { CustomMinDirective } from './general/directives/custom-min-validator.directive'; 
import { DigitOnlyDirective } from '../directives/digit-only.directive';
import { PositiveNumberDirective } from '../directives/positive-number.directive';
import { ConfirmDialogComponent } from './confirm/components/confirm-dialog/confirm-dialog.component';
import { ConfirmModalComponent } from './confirm/components/confirm-modal-component/confirm-modal.component';
import { LoadingComponent } from './loading/components/loading-component/loading.component';
import { SearchInfoComponent } from './search-info/components/search-info/search-info.component'; 
import { LineItemsContainerTitleComponent } from './general/components/line-items-container-title/line-items-container-title.component'; 
import { Confirm } from './confirm/decorators/confirm.decorator';

import { NgMultiSelectDropDownModule } from './ng-multiselect-dropdown/src'; 
import { CdkTreeModule } from '@angular/cdk/tree';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { Grid } from '@ag-grid-community/core';
import { AngularMaterialModule } from '../third-party-components/angular-material/angular-material.module';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    ConfirmModalComponent,
    LoadingComponent,
    SearchInfoComponent,
    LineItemsContainerTitleComponent,
    CustomMaxDirective,
    CustomMinDirective,
    DigitOnlyDirective,
    PositiveNumberDirective,
  ],
  imports: [
    AgGridModule.withComponents([ Grid ]),
    AngularCommonModule,
    AngularMaterialModule,
    CdkTreeModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [
    Confirm
  ],
  entryComponents: [ConfirmDialogComponent, SearchInfoComponent, ConfirmModalComponent, LineItemsContainerTitleComponent],
  exports: [
    ConfirmDialogComponent,
    ConfirmModalComponent,
    LoadingComponent,
    SearchInfoComponent,
    LineItemsContainerTitleComponent,
    CustomMaxDirective,
    CustomMinDirective,
    DigitOnlyDirective,
    PositiveNumberDirective,
    NgMultiSelectDropDownModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class CommonModule {
  public constructor(private confirm: Confirm) {}
}
