import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';

import { HttpErrorHandlerService } from '../../common/general/services/http-error-handler.service'; 

@Injectable(
  {
    providedIn: 'root'
  })
export class ExportToExcelService {
  constructor(private http:  HttpClient, private httpErrorHandler: HttpErrorHandlerService) {}

  checkSession() {
    const url: string = './ExportToExcel/AjaxCheckSession';

    return this.http.get(url).pipe(catchError(this.httpErrorHandler.handleError));
  }

  dispatchReport(tab: string, origin: string, data: string, jobId: string, specificData: string) {
    const url: string = './ExportToExcel/DispatchReport';
    const httpOptions = {
      params: new HttpParams()
        .set('tabName', tab)
        .set('rootInfo', origin)
        .set('toExport', data)
        .set('jobID', jobId)
    };

    return this.http.post(url, { specificData }, httpOptions).pipe(catchError(this.httpErrorHandler.handleError));
  }

  getBaseUrl() {
    const url: string = './ExportToExcelSessionSafe/GetBaseUrl';

    return this.http.get(url).pipe(catchError(this.httpErrorHandler.handleError));
  }
}
