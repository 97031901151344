import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';

import { HttpErrorHandlerService } from '../../common/general/services/http-error-handler.service';

@Injectable(
  {
    providedIn: 'root'
  })
  export class SwitchEnvironmentService {
    constructor(private http: HttpClient, private httpErrorService: HttpErrorHandlerService) {}

    switchEnvironments() {
      const url: string = './SwitchEnvironments/Index';
      return this.http.get(url, {params: new HttpParams()
        .set('referrer', window.location.pathname), responseType : 'text'})
        .pipe(catchError(this.httpErrorService.handleError));
    }
  }
