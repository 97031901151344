import { AfterViewChecked, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { IAddIndicationInfo } from '../../../../../study-details/src/app/models/study-indication';
import { StudyDetailsService } from '../../../../../study-details/src/app/services/study-details-service/study-details.service';
import { AccountService } from '../../common/security/services/account.service';
import { IndicationService } from '../../indications/services/indication.service';
import { VerticalScrollService } from 'projects/visit-schedule/src/app/services/page-level/vertical-scroll.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { SelectivePrivilegesService } from './../../../../../shared/src/app/services/user/selective-privileges/selective-privileges.service';
import { Tab } from './../../../../../shared/src/app/constants/Tab';

@Component({
  selector: 'gp-ui-study-details-section',
  templateUrl: './study-details-section.component.html',
  styleUrls: ['./study-details-section.component.css']
})
export class StudyDetailsSectionComponent implements OnInit, AfterViewChecked {

  @ViewChild('studyDetailsSection', { static: false }) studyDetailsSection: ElementRef;
  
  studyInfo: any;

  showBMARCREF: boolean;
  showSVTVersion: boolean;
  showRevision: boolean;
  showClinAdminExport: boolean;
  showAveragePeriodBetweenVisits: boolean;

  studyPopulationTypes: any = [];
  isFirstPhase: boolean = false;
  firstPhase = ["0", "I", "I/II", "IIa"];

  studyIndications: IAddIndicationInfo[] = [];
  respStudyIndication: any;
  master: IAddIndicationInfo[] = [];

  private previousHeight = 0;

  @Output() exportEvent = new EventEmitter<void>();

  hasPaymentTermTabAccess = false;
  hasStudyDetailsTabAccess = false;

  constructor(
    private studyDetailsService: StudyDetailsService,
    private accountService: AccountService,
    private indicationService: IndicationService,
    private verticalScrollService: VerticalScrollService,
    private selectivePrivilegesService: SelectivePrivilegesService
 ) { }

  ngOnInit() {
    this.accountService.getStudySponsorCustomization().subscribe(response => {
      this.showBMARCREF = response.ShowBMARCREF;
      this.showSVTVersion = response.ShowSVTVersion;
      this.showRevision = response.ShowRevision;
      this.showClinAdminExport = response.ShowClinAdminExport;
      this.showAveragePeriodBetweenVisits = response.AveragePeriodBetweenVisits;
    }, error => {
      console.log('An error occurred getting the sponsor customization.  ' + error.message);
    });

    this.getStudyDetails();
    this.getStudyIndications();
  }

  ngAfterViewChecked() {
		const DivHeight = this.studyDetailsSection.nativeElement.offsetHeight;
		DivHeight != this.previousHeight ? this.updateHeightofComponent(DivHeight) : null;
	}

  showExport() {
    this.exportEvent.emit();
  }

  getStudyDetails() {
    this.studyDetailsService.GetStudyDetails().subscribe((response: any) => {
      this.studyPopulationTypes = response.StudyPopulationTypes;
      this.studyInfo = response.Data;

      this.studyInfo.SinglePatientDuration = this.studyInfo.SinglePatientDuration === 0 ? 1 : this.studyInfo.SinglePatientDuration;
      this.studyInfo.LengthOFTimeBetweenVisits = this.studyInfo.LengthOFTimeBetweenVisits === 0
        ? 1 : this.studyInfo.LengthOFTimeBetweenVisits;
      this.studyInfo.DateOfFinalVersionParsed = new Date(parseInt(response.Data['DateOFFinalVersion'].substring(6), 10));

      this.studyInfo.CreatedOnParsed = new Date(parseInt(response.Data['CreateON'].substring(6), 10));
      this.studyInfo.LastModifiedParsed = new Date(parseInt(response.Data['LastModified'].substring(6), 10));
      this.studyInfo.ApprovalDateParsed = new Date(parseInt(response.Data['ApprovalDate'].substring(6), 10));

      this.studyInfo.LengthOfStudyValue = this.studyInfo.LengthOfStudyValue === 0 ? 1 : this.studyInfo.LengthOfStudyValue;
      this.studyInfo.SvtVersionId = this.studyInfo.SvtVersionId === 0 ? 1 : this.studyInfo.SvtVersionId;
 
      this.isFirstPhase = this.firstPhase.includes(this.studyInfo.PhaseName);

      this.evaludateIfUserHasAccessToPaymentTerm();
  });
  }

  private getStudyIndications() {
    this.respStudyIndication = this.indicationService.getStudyIndications().subscribe((data: any) => {
      this.studyIndications = data.Indications;
      this.evaludateIfUserHasAccessToStudyDetails()
    });
  }

  private evaludateIfUserHasAccessToPaymentTerm() {
    this.selectivePrivilegesService.hasTabAccess(Tab.PAYMENT_TERMS).subscribe((resp: boolean) => {
      this.hasPaymentTermTabAccess = resp;
    });
  }

  private updateHeightofComponent(newHeight: number){
		if (this.studyDetailsSection && this.verticalScrollService) {
			this.verticalScrollService.updateStudyDetailsHeight(newHeight)
			this.previousHeight = newHeight;
		}
	}
  
  private evaludateIfUserHasAccessToStudyDetails() {
    this.selectivePrivilegesService.hasTabAccess(Tab.STUDY_DETAILS).subscribe((resp: boolean) => {
      this.hasStudyDetailsTabAccess = resp;
    });
  }
}
