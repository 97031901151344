import { Component, OnInit, OnDestroy } from '@angular/core';
import { cloneDeep, isNil } from 'lodash';
import { Subscription } from 'rxjs/internal/Subscription';

import { StudyNoteService } from '../../services/study-note-service/study-note.service';
import { StudyNoteConstants } from '../../constants/StudyNoteConstants';
import { SharedDataService } from 'projects/shared/src/app/common/general/services/shared-data.service'; 
import { StudyService } from 'projects/study-list/src/app/services/study-service/study.service';
import { EventBusService } from 'projects/shared/src/app/common/general/services/event-bus.service'; 
import { Event, EventType } from 'projects/shared/src/app/common/general/models/events';
import { LoadingService } from 'projects/shared/src/app/common/loading/services/loading.service';
import {
  UnsavedChangesNotifierStateService
} from 'projects/shared/src/app/common/general/services/unsaved-changes-notifier.service';
import { ResourcePermissionValidator } from 'projects/shared/src/app/services/resource-permission-validator/resource-permission-validator.service';
import { Resource } from 'projects/shared/src/app/constants/Resource';
import { Permission } from 'projects/shared/src/app/constants/Permission';

@Component({
  selector: 'gp-ui-study-note',
  templateUrl: './study-note.component.html',
  styleUrls: ['./study-note.component.css']
})
export class StudyNoteComponent implements OnInit, OnDestroy {

  req: any;
  studyNoteInfo: any = {};
  StudyNotesMaxLength: any;
  status: any;
  editMode: boolean;
  focusMode: boolean;
  canEditPermission: boolean = false;
  studyNoteContentCancelCopy: any;
  public hasStudyReadWritePermission: any;
  dropdownChangedSubscription: Subscription;
  hasEditPermissionSubscription: Subscription;

  constructor(
    private studyNoteService: StudyNoteService,
    private sharedDataService: SharedDataService,
    private studyService: StudyService,
    private eventBus: EventBusService,
    private loadingService: LoadingService,
    private resourcePermissionValidator: ResourcePermissionValidator,
    private unsavedChangesState: UnsavedChangesNotifierStateService,
  ) {
    this.editMode = false;
  }

  ngOnInit() {
    this.validateCanEditPermission();
    this.loadStudyNotes();
    this.dropdownChangedSubscription = this.eventBus.on(EventType.DropdownChanged, () => this.loadStudyNotes());
    this.studyService.getStudySecurityPermission().subscribe(res => this.hasStudyReadWritePermission = res['StudySecurityPermission']);
  }

  ngOnDestroy() {
    this.dropdownChangedSubscription.unsubscribe();
    this.hasEditPermissionSubscription.unsubscribe();
  }

  loadStudyNotes() {
    const selectedCountryId = !isNil(this.sharedDataService.SelectedCountry) ?
      this.sharedDataService.SelectedCountry.CountryShortId : StudyNoteConstants.defaultCountryId;
    this.req = this.studyNoteService.pullStudyNote(selectedCountryId).subscribe((data: any) => {
      this.studyNoteInfo = data.StudyNoteInfo;
      this.StudyNotesMaxLength = data.StudyNotesMaxLength;
    }, error => {
      this.eventBus.emit(new Event(EventType.UnhandledException, {
        description: 'Unable to load study notes',
        details: error
      }));
      this.status = 'Unable to fetch study note: ' + error.message;
    });
  }

  edit() {
    this.studyNoteContentCancelCopy = cloneDeep(this.studyNoteInfo.Content);
    this.updateEditFlags(true);
    this.focusMode = true;
  }

  validateCanEditPermission() {
    this.hasEditPermissionSubscription = this.resourcePermissionValidator.hasPermissionAsync(Resource.study, Permission.update).subscribe((resp: boolean) => {
      this.canEditPermission = resp;
    });
  }
  onNotesChange() {
    this.unsavedChangesState.notify(true);
  }

  cancel() {
    this.studyNoteInfo.Content = this.studyNoteContentCancelCopy;
    this.toggleEditStatus(false);
    this.focusMode = false;
  }

  save() {
    this.loadingService.showLoadingAndSubscribeToEvent(EventType.GetStudyDetailsDone);
    this.focusMode = false;
    const selectedCountry = this.sharedDataService.SelectedCountry;
    const selectedSubStudy = this.sharedDataService.SelectedSubstudy;
    const selectedRegion = !isNil(this.sharedDataService.SelectedRegion)
      ? this.sharedDataService.SelectedRegion : StudyNoteConstants.defaultRegionId;
    if (!isNil(selectedCountry) && !isNil(selectedSubStudy)) {
      const content = JSON.stringify(this.studyNoteInfo.Content);
      const { CountryShortId } = selectedCountry;
      const { SUBStudyId } = selectedSubStudy;
      this.req = this.studyNoteService.save(CountryShortId, selectedRegion, content, SUBStudyId).subscribe((data: any) => {
          this.eventBus.emit(new Event(EventType.UpdateStudyDetailsInfo));
          this.toggleEditStatus(false);
      }, error => {
        this.eventBus.emit(new Event(EventType.UnhandledException, {
          description: 'Unable to save study notes',
          details: error
        }));
        this.status = 'Unable to save study note: ' + error.message;
      });
    }
  }

  private toggleEditStatus(value: boolean): void {
    this.updateEditFlags(value);
    this.unsavedChangesState.notify(value);
  }

  private updateEditFlags(isEditMode) {
    this.editMode = isEditMode;
    this.sharedDataService.IsEditMode = isEditMode;
    this.sharedDataService.HideDropdowns = isEditMode;
  }
}
