import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule as GpUICommon } from './common/common.module';
import { CountriesModule } from './countries/countries.module';
import { ExcelModule } from './excel/excel.module';
import { IndicationsModule } from './indications/indications.module';
import { HeaderNavbarModule } from './header-navbar/header-navbar.module';
import { StudyModule } from './study/study.module';

import { AppComponent } from './app.component';
import { StudyDetailsComponent } from './common/general/components/study-details-component/study-details.component';
import { StudyNoteComponent } from 'projects/study-details/src/app/components/study-note/study-note.component';
import { RouterModule } from '@angular/router';
import { SortableHeaderComponent } from './common/general/components/header-component/sortable-header.component';
import { SearchGridGrantTotalRendererComponent, SelectedGridCopyRendererComponent, SelectedGridGrantTotalRendererComponent, SelectedGridSubstudyNameRendererComponent } from './common/general/components/substudy-component/cell-renderers';
import { GridSubstudyNameRendererComponent, GridSubstudyVisitsRendererComponent } from './common/general/components/substudy-table-component/cell-renderers';
import { AgGridModule } from 'ag-grid-angular';
import { Grid } from 'ag-grid-community';
import { CommonModule } from '@angular/common';
import { ToggleButtonComponent } from './common/general/components/toggle-button/toggle-button.component';

import { KeycloakInterceptor } from './utility/keycloak.interceptor';
import { Confirm } from './common/confirm/decorators/confirm.decorator';
import { ConfirmDialogComponent } from './common/confirm/components/confirm-dialog/confirm-dialog.component';
import { AddCountriesModalComponent } from './countries/components/add-countries-modal/add-countries-modal.component';
import { MultiselectModalComponent } from './components/multiselect-add-remove/multiselect-modal.component';
import { TimeoutModalComponent } from './components/timeout-modal-component/timeout-modal.component';
import { StudyDetailsSectionComponent } from './components/study-details-section/study-details-section.component';
import { ItemsTreeComponent } from './components/multiselect-add-remove/items-tree/items-tree.component';
import { MultiselectComponent } from './components/multiselect-add-remove/multiselect/multiselect.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { MultiselectCheckboxComponent } from './components/multiselect-checkbox/multiselect-checkbox.component';
import { UserModalComponent } from './components/user-components/user-modal/user-modal.component';
import { ToolbarTabComponentComponent } from './components/user-components/toolbar-tab-component/toolbar-tab-component.component';
import { UserGroupsTabComponent } from './components/user-components/tabs/user-groups-tab/user-groups-tab.component';
import { DetailsTabComponent } from './components/user-components/tabs/details-tab/details-tab.component';
import { OptionsTabComponent } from './components/user-components/tabs/options-tab/options-tab.component';
import { ItemsTableComponent } from './components/multiselect-add-remove/items-table/items-table.component';
import { AddVisitsModalComponent } from '../../../visit-schedule/src/app/components/add-visits-modal/add-visits-modal.component';
import { StudySecurityTabComponent } from './components/user-components/tabs/study-security-tab/study-security-tab.component';
import { TableCellInputComponent } from './components/table-cell/table-cell-input/table-cell-input.component';
import { TableCellSelectComponent } from './components/table-cell/table-cell-select/table-cell-select.component';
import { DecimalPipe } from '@angular/common';
import { GpDecimalPipe } from './pipes/gp-decimal.pipe';
import { AngularMaterialModule } from './third-party-components/angular-material/angular-material.module';
import { CopyStudyComponent } from './study/components/copy-study-component/copy-study.component';
import { ExportToExcelComponent } from './excel/components/export-to-excel-component/export-to-excel.component';

@NgModule({
  declarations: [
    AppComponent,
    StudyDetailsComponent,
    ToggleButtonComponent,
    StudyNoteComponent,
    SortableHeaderComponent,
    SearchGridGrantTotalRendererComponent,
    SelectedGridCopyRendererComponent,
    SelectedGridGrantTotalRendererComponent,
    SelectedGridSubstudyNameRendererComponent,
    GridSubstudyNameRendererComponent,
    GridSubstudyVisitsRendererComponent,
    MultiselectModalComponent,
    MultiselectComponent,
    ItemsTreeComponent,
    TimeoutModalComponent,
    PaginatorComponent,
    MultiselectCheckboxComponent,
    UserModalComponent,
    ToolbarTabComponentComponent,
    DetailsTabComponent,
    OptionsTabComponent,
    UserGroupsTabComponent,
    TableCellInputComponent,
    TableCellSelectComponent,
    StudyDetailsSectionComponent,
    StudySecurityTabComponent,
    GpDecimalPipe,
    ItemsTableComponent,
    AddVisitsModalComponent
  ],
  imports: [
    GpUICommon,
    CommonModule,
    AngularMaterialModule,
    CountriesModule,
    HttpClientModule,
    NgbModule,
    RouterModule,
    CountriesModule,
    ExcelModule,
    IndicationsModule,
    HeaderNavbarModule,
    AgGridModule.withComponents(
      [
        SortableHeaderComponent,
        SearchGridGrantTotalRendererComponent,
        SelectedGridCopyRendererComponent,
        SelectedGridGrantTotalRendererComponent,
        SelectedGridSubstudyNameRendererComponent,
        GridSubstudyNameRendererComponent,
        GridSubstudyVisitsRendererComponent,
        Grid
      ]
    ),
  ],
  providers: [
    HttpClient,
    Confirm,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    // { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    { provide: HTTP_INTERCEPTORS, useClass: KeycloakInterceptor, multi: true },
    DecimalPipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ConfirmDialogComponent,
    AddCountriesModalComponent,
    StudyDetailsComponent,
    CopyStudyComponent,
    ExportToExcelComponent,
    TimeoutModalComponent,
    UserModalComponent,
    AddVisitsModalComponent
  ],
  exports: [
    StudyDetailsComponent,
    ToggleButtonComponent,
    StudyNoteComponent,
    SortableHeaderComponent,
    SearchGridGrantTotalRendererComponent,
    SelectedGridCopyRendererComponent,
    SelectedGridGrantTotalRendererComponent,
    SelectedGridSubstudyNameRendererComponent,
    GridSubstudyNameRendererComponent,
    GridSubstudyVisitsRendererComponent,
    CommonModule,
    GpUICommon,
    HeaderNavbarModule,
    StudyModule,
    ExcelModule,
    AgGridModule,
    TimeoutModalComponent,
    MultiselectModalComponent,
    MultiselectComponent,
    PaginatorComponent,
    MultiselectCheckboxComponent,
    UserModalComponent,
    StudyDetailsSectionComponent,
    ItemsTableComponent,
    TableCellInputComponent,
    TableCellSelectComponent,
    GpDecimalPipe,
    AngularMaterialModule
  ]
})
export class SharedModule {
  public constructor(private confirm: Confirm) {
  }
}
