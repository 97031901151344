
import { KeycloakService } from 'keycloak-angular';
import { AccountService } from '../common/security/services/account.service';

export function initializeKeycloak(keycloak: KeycloakService, accountService: AccountService): () => Promise<boolean> {

    return () =>
        accountService.getKeycloakEnvironmentInformation().toPromise().then(
            (configuration: any) =>
                keycloak.init({
                    config: configuration,
                    initOptions: {
                        checkLoginIframe: false,
                        checkLoginIframeInterval: 25
                    },
                    loadUserProfileAtStartUp: false
                })
        );
}

