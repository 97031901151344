import { Injectable } from '@angular/core';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { headerColor } from '../../../models/header-color/headerColor';


@Injectable(
  {
    providedIn: 'root'
  })
export class ConfirmDialogService {

  readonly AutoSize = -1;

  constructor(private dialog: MatDialog) { }

  show(title: string, message: string, showCancelButton: boolean, width: number, height: number,
       confirm?: string, deny?: string, headerColorBar?: headerColor) {
    confirm= confirm ? confirm : 'Ok' ;
    headerColorBar= headerColorBar ? headerColorBar : headerColor.Info ;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: width === this.AutoSize ? 'auto' : width + 'px',
      height: height === this.AutoSize ? 'auto' : height + 'px',      
      data: { title: title, text: message, showCancelButton: showCancelButton, confirm: confirm, deny: deny, headerColor: headerColorBar },
      position: { top: '10%' }
    });

    return dialogRef.afterClosed(); 
  }
}
