import { Injectable } from '@angular/core';
import { ConfirmDialogService } from '../services/confirm-dialog.service';
import { IconfirmProps } from '../models/IConfirmProps';
import { defaultConfirmProps } from '../models/DefaultConfirmProps';

@Injectable({
    providedIn: 'root'
})
export class Confirm {

    static dialogService: ConfirmDialogService;
    
    constructor(dialogService: ConfirmDialogService) {
        Confirm.dialogService = dialogService;
    }

    static showModal (descriptor: PropertyDescriptor, confirmProps: IconfirmProps) {
        const actualFunction = descriptor.value;
        descriptor.value = function(...args) {
            const title= typeof(confirmProps.title)== 'string' ? confirmProps.title 
                        : confirmProps.title(...args);
            const message= typeof(confirmProps.message)== 'string' ? confirmProps.message 
                        : confirmProps.message(...args);
            Confirm.dialogService
            .show(title, message, confirmProps.showCancelButton, confirmProps.width, confirmProps.height, 
                confirmProps.confirm, confirmProps.deny, confirmProps.headerColor)
            .subscribe(respDialog => {
                if (respDialog || confirmProps.executeOnCancel === true ) {
                    if (confirmProps.showCancelButton) {
                        args.push(respDialog);
                    }
                    actualFunction.apply(this, args);
                }
            });
        };
    }
}

export function okModal (confirmProps: IconfirmProps) {
    const executeOnCancel = false;
    const finalProps: IconfirmProps = {...defaultConfirmProps, ...confirmProps, executeOnCancel, showCancelButton: false};
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        Confirm.showModal(descriptor, finalProps);
    };
}

export function okCancelModal (confirmProps: IconfirmProps) {
    const executeOnCancel = confirmProps.executeOnCancel === undefined || confirmProps.executeOnCancel;
    const finalProps: IconfirmProps = {...defaultConfirmProps, ...confirmProps, executeOnCancel, showCancelButton: true};
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        Confirm.showModal(descriptor, finalProps);
    };
}

export function YesNoCancelModal (confirmProps: IconfirmProps) {
    const executeOnCancel = confirmProps.executeOnCancel === undefined || confirmProps.executeOnCancel;
    const finalProps: IconfirmProps = {...defaultConfirmProps, ...confirmProps, executeOnCancel, showCancelButton: true};
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        Confirm.showModal(descriptor, finalProps);
    };
}
