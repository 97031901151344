import { Component, OnInit } from '@angular/core';
import { InactivityInspectorService } from 'projects/shared/src/app/services/inactivity-inspector/inactivity-inspector.service';
import { TimeoutModalService } from 'projects/shared/src/app/services/timeout-modal-service/timeout-modal.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  
  constructor(private timeOutModal: TimeoutModalService,
    private inactivityInspector: InactivityInspectorService) { }

  ngOnInit(): void {
    this.inactivityInspector.timeoutSessionFunction = () => this.timeOutModal.show(Date.now());
    this.inactivityInspector.listenActivity();
  }

}
