import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { modalResults } from 'projects/shared/src/app/models/confirm-modal-result/confirm-modal-result';

import { HttpService } from '../../../common/general/services/http.service';
import { Observable } from 'rxjs';
import { APIResponse } from '../../../models/user/api-response.model';
import { UserDetailsAPI } from '../../../models/user/user/user-details.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpService: HttpService) { }

  GetUserBySearch(body) {
    const url: string = './UserAPI/SearchEmployees';

    return this.httpService.safePost({ url, body });
  }

  GetUserCountBySearch(value) {
    const url: string = './UserAPI/GetEmployeesCount';
    const options = {
      params: new HttpParams().set('keyword', value)
    };

    return this.httpService.safeGet({ url, options });
  }

  GetUserDetailsById(userId: number): Observable<APIResponse> {
    const url: string = './UserAPI/GetInfoSponsorContact';
    const options = { params: new HttpParams().set('contactId', userId.toString()) };

    return this.httpService.safeGet<APIResponse>({ url, options });
  }

  SaveUserDetails(userDetails: UserDetailsAPI): Observable<APIResponse> {
    const url = './UserAPI/SaveContact';
    const body = { ...userDetails };

    return this.httpService.safePost<APIResponse>({ url, body });
  }

  GetUserOptionsById(userId: number): Observable<APIResponse> {
    const url: string = './UserAPI/GetInfoUserOption';
    const options = { params: new HttpParams().set('contactId', userId.toString()) };

    return this.httpService.safeGet<APIResponse>({ url, options });
  }

  SaveUserOptions(userOptions: any): Observable<APIResponse> {
    const url = './UserAPI/SaveSponsorContactOption';
    const body = { ...userOptions };

    return this.httpService.safePost<APIResponse>({ url, body });
  }

  GetUserSecurityById(userId: number): Observable<APIResponse> {
    const url: string = './UserAPI/GetInfoUserSecutiry';
    const options = { params: new HttpParams().set('contactId', userId.toString()) };

    return this.httpService.safeGet<APIResponse>({ url, options });
  }

  SaveUserSecurityInfo(userId: number, userSecurityInfo: any[]): Observable<APIResponse> {
    const url: string = './UserAPI/SaveSponsorContactUserSecurities';
    const body = {
      contactId: userId,
      contactUserSecurityList: userSecurityInfo 
    };

    return this.httpService.safePost<APIResponse>({ url, body });
  }

  SaveUserSecurityInfoForAnyStudy(userSecurityInfo: any[], selectedContactId : number ): Observable<APIResponse> {
    const url: string = './UserSecurityDefault/SaveStudyUserSecurityDefault';
    const body = { contactUserSecurityList: userSecurityInfo, contactId: selectedContactId  };

    return this.httpService.safePost<APIResponse>({ url, body });
  }

  DeleteEmployee(contactId: number){
    const url: string = './UserAPI/DeleteEmployee';
    return this.httpService.safePost( {url, body:{contactId} });
  }

  RemoveEmployees(modalResult: modalResults,contactIds: number[]){
    const url: string = './UserAPI/RemoveEmployees';
    return this.httpService.safePost( {url, body:{modalResult, contactIds} });
  }

  DeleteAllEmployeesBySponsor(modalResult: modalResults,keyword: string,exceptedUsers: number[]){
    const url: string = './UserAPI/DeleteAllEmployeesBySponsor';
    return this.httpService.safePost( {url, body:{modalResult,keyword,exceptedUsers} });
  }

  LockUser(contactIds: number[], typeOfSelect:string){
    const url: string = './UserAPI/LockUsers';
    return this.httpService.safePost( {url, body:{users:contactIds, typeOfSelect:typeOfSelect} });
  } 
}
