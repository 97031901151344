import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TreeNestedNode } from '../../models/items-tree-component/items-tree-component.model'
import { IMultiselectModalUIOptions, IMultiselectUIOptions } from '../../models/multiselect-add-remove-component-options/multiselect-options';

@Component({
  selector: 'app-multiselect-modal',
  templateUrl: './multiselect-modal.component.html',
  styleUrls: ['./multiselect-modal.component.css']
})
export class MultiselectModalComponent<T extends TreeNestedNode> implements OnInit {

  @Output() saveModalData: EventEmitter<T[]> = new EventEmitter();
  @Output() cancelModal = new EventEmitter();

  multiselectModalOptions: IMultiselectModalUIOptions<T>;
  multiselectOptions: IMultiselectUIOptions<T>;
  selectedItems: T[] = [];
  title: string;
  okButtonLabel: string;
  cancelButtonLabel: string;

  constructor(public dialogRef: MatDialogRef<T>, @Inject(MAT_DIALOG_DATA) public options: IMultiselectModalUIOptions<T>) {
    this.multiselectModalOptions = options;
  }

  ngOnInit() {
    this.title = this.multiselectModalOptions.title;
    this.okButtonLabel = this.multiselectModalOptions.okButtonLabel;
    this.cancelButtonLabel = this.multiselectModalOptions.cancelButtonLabel;
    this.multiselectOptions = this.multiselectModalOptions.multiselectMetaData;
  }

  onOkClickEvent(){
    this.saveModalData.emit(this.selectedItems);
  }

  onCancelClickEvent(){
   this.cancelModal.emit();
  }

  onValidateMinimumSelected() {
    return this.selectedItems.length >= this.multiselectModalOptions.minimumSelectedItems;
  }

  onDataOutputChangeEvent(items: T[]) { 
    this.selectedItems = items;
  }

}