export class Resource {
  public static get admin(): string {
    return 'Admin';
  }

  public static get user(): string {
    return 'User';
  }

  public static get study(): string {
    return 'Study';
  }

  public static get cpvStudy(): string {
    return 'CpvStudy';
  }

  public static get cppStudy(): string {
    return 'CppStudy';
  }

  public static get socQLTStudy(): string {
    return 'SocQLTStudy';
  }

  public static get qltStudy(): string {
    return 'QLTStudy';
  }

  public static get tabsAccess(): string {
    return 'TabsAccess';
  }

  public static get emailNotification(): string {
    return 'EmailNotification';
  }

  public static get studyOptionsFeatures(): string {
    return 'StudyOptionsFeatures';
  }

  public static get studySecurity(): string {
    return 'StudySecurity';
  }

  public static get site(): string {
    return 'Site';
  }

  public static get countries(): string {
    return 'Countries';
  }

  public static get countryFeatures(): string {
    return 'CountryFeatures';
  }

  public static get studyComplexityFeatures(): string {
    return 'StudyComplexityFeatures';
  }

  public static get selectivePrivilegesTab(): string {
    return 'SelectivePrivilegesTab';
  }

  public static get allCountriesTemplate(): string {
    return 'ALL';
  }

  public static get smslFeatures(): string {
    return 'SMSLFeatures';
  }

}
