export class Permission {
  public static get all(): string {
  return 'All';
  }

  public static get specific(): string {
  return 'Specific';
  }

  public static get add(): string {
  return 'Add';
  }

  public static get remove(): string {
  return 'Remove';
  }

  public static get read(): string {
  return 'Read';
  }

  public static get create(): string {
  return 'Create';
  }

  public static get update(): string {
  return 'Update';
  }

  public static get delete(): string {
  return 'Delete';
  }

  public static get lock(): string {
  return 'Lock';
  }

  public static get send(): string {
  return 'Send';
  }

  public static get updateAdmin(): string {
    return 'updateAdmin';
  }

  public static get navigate(): string {
    return 'Navigate';
  }

  public static get copy(): string {
    return 'Copy';
  }

  //Admin exclusive right, so it is not mapped into database.
  public static get agree(): string {
    return 'Agree';
  }
  }
