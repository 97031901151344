import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AddCountriesModalComponent } from './components/add-countries-modal/add-countries-modal.component'; 
import { CountriesTreeComponent } from './components/add-countries-modal/countries-tree/countries-tree.component'; 
import { CountriesSharedService } from './services/countries-service.service'; 
import { AngularMaterialModule } from '../third-party-components/angular-material/angular-material.module';

@NgModule({
  declarations: [
    AddCountriesModalComponent,
    CountriesTreeComponent,
  ],
  imports: [
    CommonModule,    
    AngularMaterialModule,
    FormsModule
  ],
  providers: [
    CountriesSharedService
  ],
  entryComponents: [
    AddCountriesModalComponent,
  ]
})
export class CountriesModule { }
