import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

import { EventBusService } from 'projects/shared/src/app/common/general/services/event-bus.service'; 
import { Event, EventType } from 'projects/shared/src/app/common/general/models/events';

@Component(
  {
    selector: 'gp-ui-add-to-grant-total-renderer',
    templateUrl: './selected-grid-grant-total-renderer.component.html'
  })
export class SelectedGridGrantTotalRendererComponent implements ICellRendererAngularComp {
  private rendererParams: ICellRendererParams = undefined;

  constructor(private eventBus: EventBusService) {}

  agInit(params: ICellRendererParams): void {
    this.rendererParams = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.rendererParams = params;

    return true;
  }

  onAddToGrantTotalChange(event: any) {
    this.rendererParams.data[this.rendererParams.colDef.field] = event.target.checked;
    this.eventBus.emit(new Event(EventType.AddToGrantTotalChanged, {
      substudyId: this.rendererParams.data['SUBStudyId'],
      addToGrantTotal: event.target.checked
    }));
  }

  get CheckboxId(): string {
    return 'checkbox-' + this.rendererParams.data.SUBStudyname + '-' + this.rendererParams.data.SUBStudyId;
  }

  get renderedParamsValue (): boolean {return this.rendererParams.value; }
}
