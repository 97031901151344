import { Tab } from "../../constants/Tab";

interface HeaderTab {
    htmlTabId: number; // Not used.
    htmlClass: string;
    url: string;
    name: string;
    isVisible: boolean;
    isDisabled: boolean;
    tabId: Tab;
}

const HEADER_TABS: HeaderTab[] = [
    {
        htmlTabId: 1,
        htmlClass: 'STUDYDETAILSNavTab',
        url: '/StudyDetails/Index',
        name: 'Study Details',
        isVisible: true,
        isDisabled: false,
        tabId: Tab.STUDY_DETAILS,
    },
    {
        htmlTabId: 3,
        htmlClass: 'COUNTRIESNavTab',
        url: '/Countries/Index',
        name: 'Countries',
        isVisible: true,
        isDisabled: false,
        tabId: Tab.COUNTRIES,
    },
    {
        htmlTabId: 4,
        htmlClass: 'CPPSUMMARYNavTab',
        url: '/Classic/CPPSummary',
        name: 'CPP Summary',
        isVisible: true,
        isDisabled: false,
        tabId: Tab.CPP_SUMMARY,
    },
    {
        htmlTabId: 5,
        htmlClass: 'VISITSCHEDULENavTab',
        url: '/VisitSchedule/Index',
        name: 'Visit Schedule',
        isVisible: true,
        isDisabled: false,
        tabId: Tab.VISIT_SCHEDULE,
    },
    {
        htmlTabId: 15,
        htmlClass: 'SCREENINGFAILURESDROPOUTSNavTab',
        url: '/Classic/ScreeningFailuresDropouts',
        name: 'SF & Dropouts',
        isVisible: true,
        isDisabled: false,
        tabId: Tab.SCREENING_FAILURES_DROPOUTS,
    },
    {
        htmlTabId: 16,
        htmlClass: 'SCREENINGANDDROPOUTSBUDGETNavTab',
        url: '/Classic/ScreeningAndDropoutsBudget',
        name: 'SF & Dropouts Budget',
        isVisible: true,
        isDisabled: false,
        tabId: Tab.SCREENING_AND_DROPOUTS_BUDGET,
    },
    {
        htmlTabId: 12,
        htmlClass: 'ATTACHMENTSNavTab',
        url: '/Classic/Attachments',
        name: 'Attachments',
        isVisible: true,
        isDisabled: false,
        tabId: Tab.ATTACHMENTS,
    },
    {
        htmlTabId: 13,
        htmlClass: 'COUNTRYPAYEEALLOCATIONNavTab',
        url: '/Classic/CountryPayeeAllocation',
        name: 'Country Payee Allocation',
        isVisible: true,
        isDisabled: false,
        tabId: Tab.COUNTRY_PAYEE_ALLOCATION,
    },
    {
        htmlTabId: 6,
        htmlClass: 'SITESNavTab',
        url: '/Classic/Sites',
        name: 'Sites',
        isVisible: true,
        isDisabled: false,
        tabId: Tab.SITES,
    },
    {
        htmlTabId: 14,
        htmlClass: 'SITEPAYEEALLOCATIONNavTab',
        url: '/Classic/SitePayeeAllocation',
        name: 'Site Payee Allocation',
        isVisible: true,
        isDisabled: false,
        tabId: Tab.SITE_PAYEE_ALLOCATION,
    },
    {
        htmlTabId: 7,
        htmlClass: 'SITEBUDGETSNavTab',
        url: '/Classic/SiteBudgets',
        name: 'Site Budgets',
        isVisible: true,
        isDisabled: false,
        tabId: Tab.SITE_BUDGETS,
    },
    {
        htmlTabId: 8,
        htmlClass: 'EMAILNOTIFICATIONSNavTab',
        url: '/Classic/EmailNotifications',
        name: 'Email Notifications',
        isVisible: true,
        isDisabled: false,
        tabId: Tab.EMAIL_NOTIFICATIONS,
    },
    {
        htmlTabId: 13,
        htmlClass: 'STUDYCOMPLEXITYNavTab',
        url: '/Classic/StudyComplexity',
        name: 'Study Complexity',
        isVisible: true,
        isDisabled: false,
        tabId: Tab.STUDY_COMPLEXITY,
    },
    {
        htmlTabId: 14,
        htmlClass: 'INTERFACENavTab',
        url: '/Classic/Interface',
        name: 'Interface',
        isVisible: true,
        isDisabled: false,
        tabId: Tab.INTERFACE,
    },
    {
        htmlTabId: 9,
        htmlClass: 'STUDYOPTIONSNavTab',
        url: '/Classic/StudyOptions',
        name: 'Study Options',
        isVisible: true,
        isDisabled: false,
        tabId: Tab.STUDY_OPTIONS,
    },
    {
        htmlTabId: 10,
        htmlClass: 'STUDYSECURITYNavTab',
        url: '/Classic/StudySecurity',
        name: 'Study Security',
        isVisible: true,
        isDisabled: false,
        tabId: Tab.STUDY_SECURITY,
    },
    {
        htmlTabId: 11,
        htmlClass: 'PAYMENTTERMSNavTab',
        url: '/Classic/PaymentTerms',
        name: 'Payment Terms',
        isVisible: true,
        isDisabled: false,
        tabId: Tab.PAYMENT_TERMS,
    },
];

export { HeaderTab, HEADER_TABS };