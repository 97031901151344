import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';

// This error handler is just used to log specific, detailed HTTP errors that may arise
// from services that make HTTP calls.  It returns an Observable from the throwError()
// call which consumers of this service can use to display details of the error or
// just be notified that an error has occurred and handle it within the consumer.
@Injectable(
  {
    providedIn: 'root'
  })
export class HttpErrorHandlerService {
  handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('An error occurred with this request:  ' + errorResponse.error.message);
    } else {
      console.error('HTTP error ' + errorResponse.status + '. Response body:\r\n' + errorResponse.error);
    }

    return throwError(errorResponse);
  }
}
