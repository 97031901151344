export class ExportToExcelConstants {
  public static get defaultCountryId(): string {
    return 'ALL';
  }

  public static get showCountryInfo(): string {
    return 'showCountryInfo';
  }

  public static get showInvestigatorInfo(): string {
    return 'showInvestigatorInfo';
  }

  public static get origin(): string {
    return 'origin';
  }

  public static get selectedCountry(): string {
    return 'selectedCountry';
  }

  public static get allMode(): string {
    return 'all';
  }

  public static get targetScope(): string {
    return 'targetScope';
  }

  public static get exportOptions(): string {
    return 'exportoptions';
  }

  public static get emailButton(): string {
    return 'Send by email';
  }

  public static get cancelButton(): string {
    return 'Cancel';
  }

  public static get returnButton(): string {
    return 'Return';
  }

  public static get noNegotiationHistoryMessage(): string {
    return 'No negotiation history is available for this investigator due to one of the following reasons:' +
      '<br/>1) Negotiations have not been started<br/>2) The file is currently being used by another process.';
  }

  public static get timeoutMessage(): string {
    return 'Timeout was exceeded.';
  }

  public static get emptyString(): string {
    return '';
  }
}
