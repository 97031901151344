import { ValidatorFn, FormControl } from '@angular/forms';
export class ValueNotAllowedValidator {
  static valueNotAllowed(value: number | string): ValidatorFn {
    return (control: FormControl) => {
      if (control.value === value) {
        return { valueNotAllowed: true};
      } else {
        return null;
      }
    };
  }
}
