import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { okCancelModal, YesNoCancelModal } from 'projects/shared/src/app/common/confirm/decorators/confirm.decorator';
import { modalResults } from 'projects/shared/src/app/models/confirm-modal-result/confirm-modal-result';
import { headerColor } from 'projects/shared/src/app/models/header-color/headerColor';

interface DeleteConfirmation {
    actionAfterDelete: () => void;
    deletionConfirmed: boolean;
    keepDNV: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class DeleteVisitsService {

    private _deleteConfirmation: DeleteConfirmation;
    private _deleteConfirmationSubject = new Subject<DeleteConfirmation>();

    get onDeleteConfirmation$(): Observable<DeleteConfirmation> {
        return this._deleteConfirmationSubject.asObservable();
    }

    constructor() { }

    public confirmDeletion(keepDNV: boolean, actionAfterDelete: () => void = undefined): void {
        this._deleteConfirmation = {
            actionAfterDelete,
            deletionConfirmed: false,
            keepDNV,
        };

        this.showDeleteConfirmation();
    }

    public confirmDNVAndDelete(actionAfterDelete: () => void = undefined): void {
        this._deleteConfirmation = {
            actionAfterDelete,
            deletionConfirmed: false,
            keepDNV: false,
        };

        this.showDNVConfirmationAndDelete();
    }

    private emitDeleteConfirmation(): void {
        this._deleteConfirmationSubject.next(this._deleteConfirmation);
    }

    @okCancelModal({
        title: '',
        message: 'Deleting any visit may compromise the screen fail/drop out calculations. Do you want to proceed?',
        headerColor: headerColor.Warning,
    })
    private showDeleteConfirmation(modalResult?: modalResults): void {
        this._deleteConfirmation.deletionConfirmed = (modalResult === modalResults.Yes);

        this.emitDeleteConfirmation();
    }

    @YesNoCancelModal({
        title: '',
        message: 'Do you want to remove the duration to next visit data or combine duration to previous visit?',
        confirm: 'Keep',
        deny: 'Remove',
        headerColor: headerColor.Warning,
    })
    private showDNVConfirmationAndDelete(modalResult?: modalResults): void {
        if (modalResult !== modalResults.Cancel) {
            this._deleteConfirmation.keepDNV = (modalResult === modalResults.Yes);
            this.showDeleteConfirmation();
        } else {
            this.emitDeleteConfirmation();
        }
    }
}