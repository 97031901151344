import { Injectable } from '@angular/core';
import { Country } from 'projects/countries/src/app/models/countries-budgets/country.model'; 
import { CopyCountry } from 'projects/countries/src/app/models/countries-card/copy-country.model'; 

@Injectable(
  {
    providedIn: 'root'
  })
export class SharedDataService {
  // SaveNote -----------------------------------------------------------------
  private saveNote: string;
  public get SaveNote(): string {
    return this.saveNote;
  }

  public set SaveNote(note: string) {
    this.saveNote = note;
  }

  // IoulSaveNote -------------------------------------------------------------
  private ioulSaveNote: string;
  public get IoulSaveNote(): string {
    return this.ioulSaveNote;
  }

  public set IoulSaveNote(note: string) {
    this.ioulSaveNote = note;
  }

  // SelectedCountry ----------------------------------------------------------
  private selectedCountry: Country;
  public get SelectedCountry(): Country {
    return this.selectedCountry;
  }

  public set SelectedCountry(country: Country) {
    this.selectedCountry = country;
  }

  // SelectedCurrency ---------------------------------------------------------
  private selectedCurrency: any;
  public get SelectedCurrency(): any {
    return this.selectedCurrency;
  }

  public set SelectedCurrency(currency: any) {
    this.selectedCurrency = currency;
  }

  // IsIoulEnabled ------------------------------------------------------------
  private isIoulEnabled: boolean;
  public get IsIoulEnabled(): boolean {
    return this.isIoulEnabled;
  }

  public set IsIoulEnabled(isEnabled: boolean) {
    this.isIoulEnabled = isEnabled;
  }

  // UpperLimitText -----------------------------------------------------------
  private upperLimitText: string;
  public get UpperLimitText(): string {
    return this.upperLimitText;
  }

  public set UpperLimitText(text: string) {
    this.upperLimitText = text;
  }

  // InitialOfferText ---------------------------------------------------------
  private initialOfferText: string;
  public get InitialOfferText(): string {
    return this.initialOfferText;
  }

  public set InitialOfferText(text: string) {
    this.initialOfferText = text;
  }

  // IsComplexityApplied ------------------------------------------------------
  private isComplexityApplied: boolean;
  public get IsComplexityApplied(): boolean {
    return this.isComplexityApplied;
  }

  public set IsComplexityApplied(isApplied: boolean) {
    this.isComplexityApplied = isApplied;
  }

  // IsEditMode ---------------------------------------------------------------
  private isEditMode: boolean;
  public get IsEditMode(): boolean {
    return this.isEditMode;
  }

  public set IsEditMode(editMode: boolean) {
    this.isEditMode = editMode;
  }

  // HideDropdowns ------------------------------------------------------------
  private hideDropdowns: boolean;
  public get HideDropdowns(): boolean {
    return this.hideDropdowns;
  }

  public set HideDropdowns(hide: boolean) {
    this.hideDropdowns = hide;
  }

  // StudyDecimalDisplay ------------------------------------------------------
  private studyDecimalDisplay: number;
  public get StudyDecimalDisplay(): number {
    return this.studyDecimalDisplay;
  }

  public set StudyDecimalDisplay(decimalDisplay: number) {
    this.studyDecimalDisplay = decimalDisplay;
  }

  // IsReorderMode ------------------------------------------------------------
  private isReorderMode: boolean;
  public get IsReorderMode(): boolean {
    return this.isReorderMode;
  }

  public set IsReorderMode(reorderMode: boolean) {
    this.isReorderMode = reorderMode;
  }

  // PhaseGroupName -----------------------------------------------------------
  private phaseGroupName: string;
  public get PhaseGroupName(): string {
    return this.phaseGroupName;
  }

  public set PhaseGroupName(name: string) {
    this.phaseGroupName = name;
  }

  // SelectedSubstudy ---------------------------------------------------------
  private selectedSubstudy: any;
  public get SelectedSubstudy(): any {
    return this.selectedSubstudy;
  }

  public set SelectedSubstudy(substudy: any) {
    this.selectedSubstudy = substudy;
  }

  // CountrySecurityPermission ------------------------------------------------
  private countrySecurityPermission: boolean;
  public get CountrySecurityPermission(): boolean {
    return this.countrySecurityPermission;
  }

  public set CountrySecurityPermission(securityPermission: boolean) {
    this.countrySecurityPermission = securityPermission;
  }

  // TherapeuticId ------------------------------------------------------------
  private therapeuticId: number;
  public get TherapeuticId(): number {
    return this.therapeuticId;
  }

  public set TherapeuticId(id: number) {
    this.therapeuticId = id;
  }

  // SelectedRegion -----------------------------------------------------------
  private selectedRegion: string;
  public get SelectedRegion(): string {
    return this.selectedRegion;
  }

  public set SelectedRegion(region: string) {
    this.selectedRegion = region;
  }

  // IsRegionVisible ----------------------------------------------------------
  private isRegionVisible: boolean;
  public get IsRegionVisible(): boolean {
    return this.isRegionVisible;
  }

  public set IsRegionVisible(isVisible: boolean) {
    this.isRegionVisible = isVisible;
  }

  // IsSubstudiesUpdated ------------------------------------------------------
  private isSubstudiesUpdated: boolean;
  public get IsSubstudiesUpdated(): boolean {
    return this.isSubstudiesUpdated;
  }

  public set IsSubstudiesUpdated(isUpdated: boolean) {
    this.isSubstudiesUpdated = isUpdated;
  }


  // ModifiedRows -------------------------------------------------------------
  private modifiedRows: any;
  public get ModifiedRows(): any {
    return this.modifiedRows;
  }

  public set ModifiedRows(rows: any) {
    this.modifiedRows = rows;
  }

  // IsSubStudyVisible -------------------------------------------------------------
  private isSubStudyVisible: boolean;
  public get IsSubStudyVisible(): boolean {
    return this.isSubStudyVisible;
  }

  public set IsSubStudyVisible(isVisible: boolean) {
    this.isSubStudyVisible = isVisible;
  }

  // IsCurrencyVisible -------------------------------------------------------------
  private isCurrencyVisible: boolean;
  public get IsCurrencyVisible(): boolean {
    return this.isCurrencyVisible;
  }

  public set IsCurrencyVisible(isVisible: boolean) {
    this.isCurrencyVisible = isVisible;
  }

  // isViewBarLoading -------------------------------------------------------------
  private isViewBarLoading: number;
  public get IsViewBarLoading(): number {
    return this.isViewBarLoading;
  }

  public set IsViewBarLoading(isLoading: number) {
    this.isViewBarLoading = isLoading;
  }

 // BudgetData -------------------------------------------------------------
  private budgetData: any;
  public get BudgetData(): any {
    return this.budgetData;
  }

  public set BudgetData(budgetData: any) {
    this.budgetData = budgetData;
  }

  private budgetcolumnbenchmarks: any[];
  public get BudgetColumnBenchmarks(): any[] {
    return this.budgetcolumnbenchmarks;
  }

  public set BudgetColumnBenchmarks(budgetcolumnbenchmarks: any[]) {
    this.budgetcolumnbenchmarks = budgetcolumnbenchmarks;
  }

  private upperLimitData: any;
  public get UpperLimitData(): any {
    return this.upperLimitData;
  }

  public set UpperLimitData(upperLimitData: any) {
    this.upperLimitData = upperLimitData;
  }

  // StudySecurityPermission --------------------------------------------------
  private studySecurityPermission: any;
  public get StudySecurityPermission(): any {
    return this.studySecurityPermission;
  }

  public set StudySecurityPermission(permission: any) {
    this.studySecurityPermission = permission;
  }

  // study details --------------------------------------------------------------
  private studyCode: string;

  public get StudyCode(): string {
      return this.studyCode;
  }

  public set StudyCode(text: string) {
      this.studyCode = text;
  }

  // DisableTopProcButton ---------------------------------------------------------
  private disableTopProcButton: boolean;
  public get DisableTopProcButton(): boolean {
    return this.disableTopProcButton;
  }

  public set DisableTopProcButton(isDisable: boolean) {
    this.disableTopProcButton = isDisable;
  }

  // countries --------------------------------------------------------------------
  private subStudyId: number;
  public get SubStudyId() {
    return this.subStudyId;
  }

  public set SubStudyId(subStudyId: number) {
    this.subStudyId = subStudyId;
  }

  private studyId: number;
  public get StudyId() {
    return this.studyId;
  }

  public set StudyId(studyId: number) {
    this.studyId = studyId;
  }

  private elementsLoading: number;
  public get ElementsLoading() {
    return this.elementsLoading;
  }

  public set ElementsLoading(elementsLoading: number) {
    this.elementsLoading = elementsLoading;
  }

  private copiedCountry: CopyCountry;
  public get CopiedCountry(): CopyCountry {
    return this.copiedCountry;
  }

  public set CopiedCountry(copiedCountry: CopyCountry) {
    this.copiedCountry = copiedCountry;
  }

  private isPasteDisabled: boolean;
  public get IsPasteDisabled(): boolean {
    return this.isPasteDisabled;
  }

  public set IsPasteDisabled(isPasteDisabled: boolean) {
    this.isPasteDisabled = isPasteDisabled;
  }

  // Contact Href --------------------------------------------------------------
  private contactHref: string;

  public get ContactHref(): string {
    return this.contactHref;
  }

  public set ContactHref(email: string) {
    this.contactHref = email;
  }

  // Classic URL --------------------------------------------------------------
  private classicURL: string;

  public get ClassicURL(): string {
    return this.classicURL;
  }

  public set ClassicURL(url: string) {
    this.classicURL = url;
  } 

  // Sponsor Name  --------------------------------------------------------------
  private sponsorName: string;

  public get SponsorName(): string {
    return this.sponsorName;
  }

  public set SponsorName(sponsorName: string) {
    this.sponsorName = sponsorName;
  } 
}
