import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { RouteGuard } from 'projects/shared/src/app/utility/route-guard';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      { path: 'StudyList', loadChildren: () => import("../../projects/study-list/src/app/app.module").then(m => m.StudyListModule), canActivate: [RouteGuard] },
      { path: 'StudyDetails', loadChildren: () => import("../../projects/study-details/src/app/app.module").then(m => m.StudyDetailsModule), canActivate: [RouteGuard], canDeactivate: [RouteGuard] },
      { path: 'Countries', loadChildren: () => import("../../projects/countries/src/app/app.module").then(m => m.CountriesModule), canActivate: [RouteGuard], canDeactivate: [RouteGuard]},
      { path: 'Classic', loadChildren: () => import("../../projects/classic/src/app/app.module").then(m => m.ClassicModule) },
      { path: 'CppTool', loadChildren: () => import("../../projects/cpp-tool/src/app/app.module").then(m => m.CppModule), canActivate: [RouteGuard], canDeactivate: [RouteGuard] },
      { path: 'MasterSiteList', loadChildren: () => import("../../projects/master-site-list/src/app/app.module").then(m => m.MasterSiteListModule), canActivate: [RouteGuard] },
      { path: 'Users', loadChildren: () => import("../../projects/users/src/app/app.module").then(m => m.UsersModule), canActivate: [RouteGuard], canDeactivate: [RouteGuard] },
      { path: 'VisitSchedule', loadChildren: () => import("../../projects/visit-schedule/src/app/app.module").then(m => m.VisitScheduleModule), canActivate: [RouteGuard], canDeactivate: [RouteGuard] }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
