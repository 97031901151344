export class AntiForgeryToken {
  cookieToken: string;
  formToken: string;

  constructor(token: AntiForgeryToken) {
    this.cookieToken = token.cookieToken;
    this.formToken = token.formToken;
  }

  public toString = (): string => `${this.cookieToken}:${this.formToken}`;

}
