import {Injectable} from '@angular/core';
import {catchError, shareReplay} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {AntiForgeryToken, ForgotPasswordParam, LoginParam, ResetPasswordParam} from '../../security/models';
import {HttpErrorHandlerService} from '../../general/services/http-error-handler.service';
import { SponsorPreferences } from '../models/SponsorPreferences';
import { Observable } from 'rxjs/internal/Observable';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';

@Injectable(
{
  providedIn: 'root'
})
export class AccountService {
  readonly tokenExpiracyTime = 30000;
  private antiForgeryTokenObservable: ReplaySubject<AntiForgeryToken>;
  private tokenLoadedAt: Date;
  private sponsorPreferencesObservable : Observable<SponsorPreferences>;

  constructor(private http: HttpClient, private httpErrorService: HttpErrorHandlerService) { }

  authenticate(param: LoginParam, requestQueryString: string) {
    const url = './Account/Login';
    const data = {
      model: param,
      requestQueryString: requestQueryString
    };

    return this.http.post(url, data).pipe(catchError(this.httpErrorService.handleError));
  }

  forgotPassword(param: ForgotPasswordParam) {
    const url = './Account/forgotPassword';

    return this.http.post(url, param).pipe(catchError(this.httpErrorService.handleError));
  }

  resetPassword(param: ResetPasswordParam) {
    const url = './Account/resetPassword';

    return this.http.post(url, param).pipe(catchError(this.httpErrorService.handleError));
  }

  validateToken(tokenGuid: string) {
    const url = './Account/ValidateToken';
    const data = {
     tokenGuid: tokenGuid
    };

    return this.http.post(url, data).pipe(catchError(this.httpErrorService.handleError));
  }

  validateUser(tokenGuid: string) {
    const url = './Account/ValidateUser';
    const data = {
     tokenGuid: tokenGuid
    };

    return this.http.post(url, data).pipe(catchError(this.httpErrorService.handleError));
  }

  isUserLoggedIn(param: LoginParam, requestQueryString: string) {
    const url = './Account/IsUserLoggedIn';
    const data = {
      user: param,
      requestQueryString: requestQueryString
    };

    return this.http.post(url, data).pipe(catchError(this.httpErrorService.handleError));
  }

  logOutCurrentUser() {
    const url = './Account/LogOutCurrentUser';

    return this.http.post(url, null).pipe(catchError(this.httpErrorService.handleError));
  }

  getGDACContent() {
    const url = './Account/GetGDACContent';

    return this.http.post(url, null).pipe(catchError(this.httpErrorService.handleError));
  }

  hasAntiForgeryTokenExpired = () => {
    return !this.tokenLoadedAt ||
      (new Date()).getTime() - this.tokenLoadedAt.getTime() > this.tokenExpiracyTime;
  }

  getAntiForgeryToken = () => {
    if (this.hasAntiForgeryTokenExpired()) {
      if (this.antiForgeryTokenObservable) {
        this.antiForgeryTokenObservable.complete();
      }

      this.antiForgeryTokenObservable = this.createAntiforgeryTokenSubscriber();
      this.tokenLoadedAt = new Date();
    }
    return this.antiForgeryTokenObservable;
  }

  createAntiforgeryTokenSubscriber = () => {
    const url = './AccountSessionSafe/GetAntiforgeryToken';
      const antiForgeryTokenObservable = new ReplaySubject<AntiForgeryToken>(1);
      this.http.get<AntiForgeryToken>(url)
      .pipe(catchError(this.httpErrorService.handleError))
      .subscribe((token: AntiForgeryToken) => {
        antiForgeryTokenObservable.next(token);
      });
      return antiForgeryTokenObservable;
  }

  getStudySponsorCustomization() {
    if (!this.sponsorPreferencesObservable){
      const url = './AccountSessionSafe/GetStudySponsorCustomization';
      this.sponsorPreferencesObservable = this.http.get<SponsorPreferences>(url)
      .pipe(
        catchError(this.httpErrorService.handleError), 
        shareReplay(1)
      );
    }
    return this.sponsorPreferencesObservable;
  }

  getKeycloakConfigurationBySponsor(sponsorCode: string) {
    const url = './Account/GetKeycloakConfigurationBySponsor';

    return this.http.get(url, {params: new HttpParams()
      .set('sponsorCode', sponsorCode), responseType : 'json'})
      .pipe(catchError(this.httpErrorService.handleError));
  }

  loginWithToken(keycloakToken: string, refreshToken: string) {
    const url = './Account/LoginFromKeycloak';
    const data = {
      
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Token':  keycloakToken,
        'refresh_token': refreshToken
      })
    };
    return this.http.post(url, data, httpOptions).pipe(catchError(this.httpErrorService.handleError));
  }

  getBaseUrl() {
    return ((<any>window).controllerData.BaseUrl);
  }

  getKeycloakEnvironmentInformation() {
    const url = './AccountSessionSafe/GetKeycloakEnvironmentInformation';
    return this.http.get(url, {responseType : 'json'})
      .pipe(catchError(this.httpErrorService.handleError));
  }
}
