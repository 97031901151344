import { EventBusService } from '../../../common/general/services/event-bus.service';
import {StudyService as SharedStudyService} from 'projects/shared/src/app/services/study-service/study.service';
import { Subscription } from 'rxjs';
import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import { AccountService } from '../../../common/security/services/account.service'; 
import { LoadingService } from '../../../common/loading/services/loading.service'; 
import {CopyStudyAdditionalCaptions} from '../../constants/CopyStudyAdditionalCaptions';
import { CopyStudyParam } from '../../models/copy-study-param'; 
import { EventType, Event } from '../../../common/general/models/events';

@Component(
  {
    selector: 'gp-ui-copy-study-component',
    templateUrl: './copy-study.component.html',
    styleUrls: ['./copy-study.component.css']
  }
)
export class CopyStudyComponent implements OnInit {
  @Input() studyCode: string;
  @Input() sponsorCode: string;
  @Input() additionalInfo: string;
  @Input() checkIfCPPToolOrSMSLPage: boolean;
  @Output() copyStudyModalAccept: EventEmitter<any> = new EventEmitter<any>();
  @Output() copyStudyModalClose: EventEmitter<any> = new EventEmitter<any>();

  showBmarcref: boolean = false;
  showRevision: boolean = false;
  isCopying: boolean = false;
  showExtraField: boolean = false;
  additionalInfoCaption: string = '';
  copyStudyParam: CopyStudyParam = new Object() as CopyStudyParam;

  copyStudyForm: FormGroup;
  destStudyCode: FormControl;
  destAdditionalInfo: FormControl;

  currentTab: string;
  copyProcessEndedSubscription: Subscription;

  constructor(private accountService: AccountService,
    private studyService: SharedStudyService,
    private loadingService: LoadingService,
    private eventBus: EventBusService) {
    }

  ngOnInit() {
    this.accountService.getStudySponsorCustomization().subscribe(
      data => {
        this.showBmarcref = data['ShowBMARCREF'];
        this.showRevision = data['ShowRevision'];
        this.showExtraField = this.showBmarcref || this.showRevision;
        if (this.showExtraField && !this.checkIfCPPToolOrSMSLPage) {
          this.additionalInfoCaption = this.showBmarcref ?
          CopyStudyAdditionalCaptions.gskCopyCaption :
          CopyStudyAdditionalCaptions.nvsCopyCaption;
        }
      },
      error => {
        console.log('An error occurred when retrieving sponsor customization.  ' + error.message);
      });

    this.destStudyCode = new FormControl('', Validators.maxLength(50));

    this.destAdditionalInfo = new FormControl('');

    this.copyStudyForm = new FormGroup(
      {
        destStudyCode: this.destStudyCode,
        destAdditionalInfo: this.destAdditionalInfo
      }
    );
    this.subscribeToCopyProcessEnded();
  }

  onSaveCopiedStudy(formValues: any) {
    if (this.checkIfCPPToolOrSMSLPage) {
      this.eventBus.emit(new Event(EventType.SaveCppCopy, {StudyCode: formValues.destStudyCode}));
    } else {
      this.copyStudy(formValues);
    }
  }

  copyStudy(formValues) {
      this.copyStudyParam.studyCode = formValues.destStudyCode;
      if (this.showBmarcref) {
        this.copyStudyParam.gskBmarcref = formValues.destAdditionalInfo;
      } else if (this.showRevision) {
        this.copyStudyParam.revision = formValues.destAdditionalInfo;
      }
      this.studyService.checkStudyCode(this.copyStudyParam, this.showBmarcref, this.showRevision).subscribe(
        data => {
          if (data['countStudy'] === 0) {
            // this.destStudyCode.setErrors({studyExists: false});
            this.isCopying = true;
            this.loadingService.show();

            this.studyService.copyStudy(this.copyStudyParam).subscribe(
              response => {
                this.loadingService.hide();                 
                this.copyStudyModalAccept.emit();
                this.close();
                this.studyService.navigateToStudy({studyId: response['newStudyId']});
              },
              error => {
                this.loadingService.hide();
                this.isCopying = false;
                console.log('An error occurred while copying the study.  ' + error.message);
              }
            );
          } else {
            this.destStudyCode.setErrors({studyExists: true});
          }
        },
        error => {
          console.log('An error occurred when checking study code.  ' + error.message);
        });
  }

  subscribeToCopyProcessEnded() {
   this.copyProcessEndedSubscription = this.eventBus.on(EventType.CopyStudyProcessEnded , (data: any ) => {
    if (data.IsDuplicated) {
        this.destStudyCode.setErrors({studyExists: true});
     } else {
      this.close();
     }
    });
  }

  close() {
    this.clearForm();
    this.copyStudyModalClose.emit();
  }

  clearForm() {
    this.copyStudyForm.reset();
  }
}
