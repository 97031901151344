import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';

import { HttpService } from 'projects/shared/src/app/common/general/services/http.service'; 

@Injectable(
  {
    providedIn: 'root'
  })
export class StudyNoteService {
  constructor(private httpService: HttpService) {}

  pullStudyNote(countryCode: string) {
    const url: string = './StudyDetailsSessionSafe/StudyNote';
    const options = {
      params: new HttpParams().set('countryId', countryCode)
    };
    return this.httpService.safeGet({url, options});
  }

  save(countryId: string, regionId: string, studyNote: string, subStudyId: string) {
    const url: string = './StudyDetailsAPI/StudyAddNote';
    const body = {
      countryShortId: countryId,
      regionId: regionId,
      content: studyNote,
      ChangesubstudyId: subStudyId
    };
    return this.httpService.safePost({url, body});
  }
}
