import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { VisitNode } from '../../../models/visit/visit-node';
import { IColumnDefinition } from '../../../models/multiselect-add-remove-component-options/multiselect-options';

@Component({
  selector: 'gp-ui-app-items-table',
  templateUrl: './items-table.component.html',
  styleUrls: ['./items-table.component.css']
})
export class ItemsTableComponent<T extends VisitNode> implements OnInit {

  @Input() dataInput: T[];
  @Input() search: (x: T[], y: string) => T[];
  @Input() compare: (x: T, y: T) => number;
  @Input() searchText: string = '';
  @Input() isSelectedItemsTable: boolean;
  @Input() columDefs: IColumnDefinition[] = [];
  @Output() dblClickEvent = new EventEmitter<T>();

  displayedColumns: string[];
  originalData: T[] = [];
  dataSource: T[] = [];
  checklistSelection = new SelectionModel<T>(true);

  ngOnInit(): void {
    this.dataSource = this.dataInput;
    this.originalData = this.dataInput;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["dataInput"]) {
      this.dataSource = this.originalData = (!!this.compare)
        ? (changes["dataInput"].currentValue as T[]).sort(this.compare)
        : (changes["dataInput"].currentValue as T[]);
    }
    this.dataSource = this.filteringData(this.originalData, this.searchText);
  }

  itemSelectionToggle(node: T, event: KeyboardEvent): void {
    (!event.ctrlKey) && this.checklistSelection.clear();
    this.checklistSelection.toggle(node);
  }

  onItemDbClick(): void {
    this.dblClickEvent.emit();
  }

  private filteringData(data: T[], searchText: string): T[] {
    return this.search ? this.search(data, searchText) : data;
  }
}
