const PERMISSIONS = Object.freeze({
    NO_ACCESS: 1,
    READ_ONLY: 2,
    READ_WRITE: 3,
});

const ROLES = Object.freeze({
    COUNTRY: 'COUNTRY',
    MASTER: 'MASTER',
    SELECTIVE: 'SELECTIVE',
    SUPER: 'SUPER',
});

export { PERMISSIONS, ROLES };