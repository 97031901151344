import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {isNil} from 'lodash';
import * as $ from 'jquery';

import { ExportToExcelService } from '../../services/export-to-excel.service';
import {ExportToExcelConstants} from '../../constants/export-to-excel-constants';

import { ConfirmModalService } from '../../../common/confirm/services/confirm-modal.service';
import { ExportToExcelOptions } from '../../models/export-to-excel-options';
import { BeforeunloadNotifierStateService } from '../../../common/general/services/beforeunload-notifier-state.service';
import { ProgressBarService } from '../../../common/general/services/progress-bar.service';

@Component(
  {
    selector: 'gp-ui-export-to-excel-component',
    templateUrl: './export-to-excel.component.html',
    styleUrls: ['./export-to-excel.component.css']
  })
export class ExportToExcelComponent implements OnInit {
  @Input() sponsorName: string;
  @Input() exportOptions: ExportToExcelOptions;
  @Input() showDNV: boolean;
  
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  ims: any = undefined;

  origin: string = ExportToExcelConstants.emptyString;
  tabOverride: string = ExportToExcelConstants.emptyString;
  modalTitle: string = ExportToExcelConstants.emptyString;
  shortName: string = ExportToExcelConstants.emptyString;
  isCountryDisplayed: boolean = false;
  isInvestigatorDisplayed: boolean = false;
  isVisitsDisplayed: boolean = false;
  isSubstudyDisplayed: boolean = false;
  currentCountry: string = ExportToExcelConstants.defaultCountryId;
  tabName: string = ExportToExcelConstants.emptyString;
  specificData: string;

  countryExportOption: string = 'current';
  allSubstudiesChecked: boolean = false;
  turnOffDNVChecked: boolean = false;
  orientationExportOption: string = 'portrait';
  scalingExportOption: string = 'adjustSize';
  adjustSizeValue: string = '100';
  adjustPageWidth: string = '1';
  adjustPageHeight: string = '1';

  constructor(
    private exportToExcel: ExportToExcelService,
    private confirmModal: ConfirmModalService,
    private beforeunloadNotifierStateService: BeforeunloadNotifierStateService,
    private progressBarService : ProgressBarService
  ) {
    this.ims = (<any>window).ims;
  }

  ngOnInit() {
    this.setValues(this.exportOptions);
  }

  close() {
    this.countryExportOption = 'current';
    this.allSubstudiesChecked = false;
    this.orientationExportOption = 'portrait';
    this.scalingExportOption = 'adjustSize';
    this.adjustSizeValue = '100';
    this.adjustPageWidth = '1';
    this.adjustPageHeight = '1';

    this.setValues(null);

    this.closeModal.emit();
  }

  setValues(options: ExportToExcelOptions) {
    this.exportToExcel.getBaseUrl().subscribe(
      (info: any) => {
        this.ims.progressbar.settings(info.JobServiceApplication, info.JobServiceUser, info.JobServiceAuthorization);
        this.ims.progressbar.setBaseUrl = info.JobServiceJavascript;
      },
      error => {
        this.confirmModal.show('Export to Excel - Error', 'An error occurred connecting to the export service.', false);
        console.log('An error occurred connecting to the export service.  ' + error.message);
      }
    );

    if (!isNil(options)) {
      this.ims.exportName = options.name;
      this.origin = options.origin;
      this.tabOverride = ExportToExcelConstants.emptyString;
      this.modalTitle = options.name;
      this.shortName = options.shortName;
      this.isCountryDisplayed = options.showCountryInfo;
      this.isInvestigatorDisplayed = options.showInvestigatorInfo;
      this.isVisitsDisplayed = false;
      this.isSubstudyDisplayed = false;
      this.currentCountry = !isNil(options.selectedCountry) ? options.selectedCountry : ExportToExcelConstants.defaultCountryId;
      this.tabName = options.tabName;
      this.specificData = typeof options.specificData === 'object' ? JSON.stringify(options.specificData) : options.specificData;
    } else {
      this.origin = ExportToExcelConstants.emptyString;
      this.tabOverride = ExportToExcelConstants.emptyString;
      this.modalTitle = ExportToExcelConstants.emptyString;
      this.shortName = ExportToExcelConstants.emptyString;
      this.isCountryDisplayed = false;
      this.isInvestigatorDisplayed = false;
      this.isVisitsDisplayed = false;
      this.isSubstudyDisplayed = false;
      this.currentCountry = ExportToExcelConstants.defaultCountryId;
      this.tabName = 'export-excel-modal';
    }
  }

  // Change events for modal controls
  /////////////////////////////////////////////////////////////////////////////
  onCountryExportOptionChanged(e) {
    this.countryExportOption = e.value;
  }

  onExportAllSubstudiesChanged(e) {
    this.allSubstudiesChecked = e.checked;
  }

  onTurnOffAllDNVChanged(e) {
    this.turnOffDNVChecked = e.checked;
  }

  onOrientationExportOptionChanged(e) {
    this.orientationExportOption = e.value;
  }

  onScalingExportOptionChanged(e) {
    this.scalingExportOption = e.value;
  }

  onAdjustSizeValueChanged(e) {
    this.adjustSizeValue = e.target.value;
  }

  onAdjustPageWidthChanged(e) {
    this.adjustPageWidth = e.target.value;
  }

  onAdjustPageHeightChanged(e) {
    this.adjustPageHeight = e.target.value;
  }

  // Export functions
  /////////////////////////////////////////////////////////////////////////////
  checkSession(callback: Function) {
    this.exportToExcel.checkSession().subscribe(
      (info: any) => {
        if (info === true) {
          callback();
        } else {
          this.confirmModal.show('Export to Excel - Error', ExportToExcelConstants.timeoutMessage, false);
        }
      },
      error => {
        console.log('An error occurred when checking the session.  ' + error.message);
      }
    );
  }

  dispatchReport(jobId: string, callback:  Function) {
    let tabName: string = this.tabName;

    if (this.tabOverride !== ExportToExcelConstants.emptyString) {
      tabName = this.tabOverride;
      this.ims.progressbar.tabOverride = ExportToExcelConstants.emptyString;
    }

    const exportUrl: string = this.origin;
    const postData: string = this.getPostData(tabName);

    this.exportToExcel.dispatchReport(this.escapeString(tabName), exportUrl, postData, jobId, this.specificData).subscribe(
      () => {
        if (callback) {
          callback(this.ims.progressbar);
        }
      },
      error => {
        this.ims.progressbar.stopRefreshing();
        this.ims.progressbar.showButton(ExportToExcelConstants.returnButton);
        this.ims.progressbar.error('An error occurred while starting the Job.');
        console.log('An error occurred while starting the progress bar job.  ' + error.message);
      });
  }

  export(sendEmail: boolean) {
    this.checkSession(() => {
      this.progressBarService.getProgressIntervalInSeconds().subscribe(
        (seconds: any) => {
          this.showProgressBar((jobId: string) => {
            this.dispatchReport(jobId, () => {
              if (sendEmail) {
                this.requestEmail(true);
              } else {
                this.ims.progressbar.showButton(ExportToExcelConstants.emailButton);
                this.ims.progressbar.showButton(ExportToExcelConstants.cancelButton);
              }
            });
          }, seconds.refreshTokenKeycloakTimeInSeconds, this.progressBarService.onProgressFunction);
        });
    });

    this.closeModal.emit();
  }

  requestEmail(isHidden: boolean) {
    this.ims.progressbar.showButtons(false);

    const requestEmailOk = (email: string) => {
      this.ims.progressbar.sendMessage(
        {
          key: 'SendByEmail',
          value: email,
          command: 'RestartIfCompleted',
          callback: () => {
            if (this.ims.progressbar.getJob().percentage === 100) {
              this.ims.progressbar.hide();
            } else {
              this.ims.progressbar.showButtons(false);
              this.ims.progressbar.showButton(ExportToExcelConstants.returnButton);
            }

            $('button:contains(\'Close\')').trigger('click');
            $('div.modal-backdrop.in').each((index, element) => {
              if (index !== 0) {
                element.parentNode.removeChild(element);
              }
            });

            this.returnToPrevious();
          }
        }
      );
    };

    const requestEmailCancel = () => {
      if (isHidden || this.ims.progressbar.getJob().percentage === 100) {
        this.ims.progressbar.hide();
      } else {
        this.ims.progressbar.showButton(ExportToExcelConstants.emailButton);
        this.ims.progressbar.showButton(ExportToExcelConstants.returnButton);
      }
    };

    const emailRegEx: RegExp = /\S+@\S+\.\S+/;
    const emailMessage: string = 'Please enter a valid email address';

    this.ims.progressbar.requestInput('Send by email:',
      this.ims.currentUserEmail,
      requestEmailOk,
      requestEmailCancel,
      emailRegEx,
      emailMessage);
  }

  returnToPrevious() {
    if (this.ims.progressbar.getJob().percentage !== 100) {
      this.ims.progressbar.stopRefreshing();
    }

    this.ims.progressbar.hide();
  }

  showProgressBar(callback: Function, seconds : number, onProgressFunction : Function) {
    const progressBarButtons: any[] = [];

    progressBarButtons.push(
      {
        text: ExportToExcelConstants.emailButton,
        visible: false,
        click: () => {
          this.requestEmail(true);
        }
      });

    progressBarButtons.push(
      {
        text: ExportToExcelConstants.returnButton,
        visible: false,
        click: () => {
          this.returnToPrevious();
        }
      });

    this.ims.progressbar.startJob(
      {
        description: 'Export - ' + this.ims.exportName,
        interval: 1,
        onCreate: (jobId: string) => {
          callback(jobId);
        },
        onComplete: (jobObject: any) => {
          this.beforeunloadNotifierStateService.notify(false);
          this.imsProgressBarOnComplete(jobObject);
          this.beforeunloadNotifierStateService.notify(true);
        },
        onCancel: () => {
          this.ims.progressbar.hide();
        },
        onError: () => {
          this.ims.progressbar.hide();
        },
        onTimeout: () => {
          this.imsProgressBarOnTimeout();
        },
        buttons: progressBarButtons,
        onProgressInterval: seconds,
        onProgress: (callback) => {
          onProgressFunction();
          callback();
        },
      }
    );
  }

  // Helper functions
  /////////////////////////////////////////////////////////////////////////////
  escapeString(data: string) {
    return String(data).replace(/([.*+?^=!:${}()|[\]\/\\])/g, '\\$1');
  }

  getPostData(tab: string) {
    let setting: any = undefined;
    let isCurrentCountry: boolean = false;
    let useMultiThread: boolean = false;
    let checkAllStudies: boolean = false;
    let isOpenInBrowser: boolean = true;
    let result: string = ExportToExcelConstants.emptyString;

    switch (tab) {

      case 'studylist':
        setting = this.getSettings();
        if (setting.RenderMode === 1) {
          isOpenInBrowser = false;
        }

        const keyword: string = this.getWindowHrefParam('filter');
        const therapeuticId: string = this.getWindowHrefParam('therapeuticid');
        const contactId: string = this.getWindowHrefParam('contactid');
        const isWithArchived: number = (this.getWindowHrefParam('witharchived') === 'true') ? 1 : 0;
        const isWithApproved: number = (this.getWindowHrefParam('withapproved') === 'true') ? 1 : 0;
        const isAgreed = this.getWindowHrefParam('isagreed');
        const type = this.getWindowHrefParam('type');

        result = 'tab=' + this.escapeString(tab)
          + '&keyword=' + keyword
          + '&therapeuticid=' + therapeuticId
          + '&contactid=' + contactId
          + '&witharchived=' + isWithArchived
          + '&isagreed=' + isAgreed
          + '&isopeninbrowser=' + isOpenInBrowser
          + '&type=' + type
          + '&withapproved=' + isWithApproved
          + '&Orientation=' + setting.Orientation
          + '&ScalingPct=' + setting.ScalingPct
          + '&ScalingWide=' + setting.ScalingWide
          + '&ScalingTall=' + setting.ScalingTall
          + '&ScalingPctChecked=' + setting.ScalingPctChecked;

        break;

      case 'studydetail':
        setting = this.getSettings();
        if (setting.RenderMode === 1) {
          isOpenInBrowser = false;
        }

        isCurrentCountry = (setting.AllMode === 1);
        checkAllStudies = this.allSubstudiesChecked;
        useMultiThread = (!isCurrentCountry && checkAllStudies);

        result = 'tab=' + this.escapeString(tab)
          + '&iscurrentcountry=' + isCurrentCountry
          + '&isShowAllSubStudy=' + checkAllStudies
          + '&isopeninbrowser=' + isOpenInBrowser
          + '&isUseMultiThreaded=' + useMultiThread
          + '&Orientation=' + setting.Orientation
          + '&ScalingPct=' + setting.ScalingPct
          + '&ScalingWide=' + setting.ScalingWide
          + '&ScalingTall=' + setting.ScalingTall
          + '&ScalingPctChecked=' + setting.ScalingPctChecked;

        break;
      case 'countries - Next':
      case 'Cost Per Patient Planning Tool - Next':
      case 'Master site - Next':
      case 'Users':
        setting = this.getSettings();
        if (setting.RenderMode === 1) {
          isOpenInBrowser = false;
        }

        result = 'tab=' + this.escapeString(tab)
          + '&Orientation=' + setting.Orientation
          + '&ScalingPct=' + setting.ScalingPct
          + '&ScalingWide=' + setting.ScalingWide
          + '&ScalingTall=' + setting.ScalingTall
          + '&ScalingPctChecked=' + setting.ScalingPctChecked;

        break;
      case "visitschedule - Next":
        let currentCountry = this.currentCountry;

        setting = this.getSettings();
        if (setting.RenderMode === 1) {
          isOpenInBrowser = false;
        }
        isCurrentCountry = (setting.AllMode === 1);
        checkAllStudies = this.allSubstudiesChecked;
        let isShowDNV = !this.turnOffDNVChecked;

        result = 'tab=' + this.escapeString(tab)
          + '&iscurrentcountry=' + isCurrentCountry
          + '&isShowAllSubStudy=' + checkAllStudies
          + '&showDNVInExport=' + isShowDNV
          + '&countryid=' + currentCountry
          + '&Orientation=' + setting.Orientation
          + '&ScalingPct=' + setting.ScalingPct
          + '&ScalingWide=' + setting.ScalingWide
          + '&ScalingTall=' + setting.ScalingTall
          + '&ScalingPctChecked=' + setting.ScalingPctChecked;

        break;
      default:
        break;
    }

    return result;
  }

  getSettings() {
    const settings: any = {};

    settings.RenderMode = 1;
    settings.AllMode = (this.countryExportOption === ExportToExcelConstants.allMode) ? 2 : 1;
    settings.Orientation = (this.orientationExportOption === 'portrait') ? 0 : 1;
    settings.ScalingPctChecked = (this.scalingExportOption === 'adjustSize') ? 1 : 0;

    if (settings.ScalingPctChecked === 1) {
      settings.ScalingPct = Number.parseInt(this.adjustSizeValue, 10);
      settings.ScalingWide = 0;
      settings.ScalingTall = 0;
    } else {
      settings.ScalingPct = 0;
      settings.ScalingWide = Number.parseInt(this.adjustPageWidth, 10);
      settings.ScalingTall = Number.parseInt(this.adjustPageHeight, 10);
    }

    return settings;
  }

  getWindowHrefParam(param: string, url?: string) {
    if (isNil(url) || (url === ExportToExcelConstants.emptyString)) {
      url = window.location.href;
    }

    const queryString: string = url.substring(url.indexOf('?') + 1);
    const substrings: string[] = queryString.split('&');
    const hrefParams: Object = new Object();

    if (isNil(hrefParams[param])) {
      for (let i = 0; i < substrings.length; ++i) {
        const kvPair = substrings[i].split('=');
        hrefParams[kvPair[0]] = kvPair[1];
      }
    }

    return hrefParams[param];
  }

  // IMS Progress Bar events
  /////////////////////////////////////////////////////////////////////////////
  imsProgressBarOnComplete(jobObject: any) {
    if (this.ims.progressbar.getMessage('NegotiationHistoryEmpty') === 'true') {
      this.ims.progressbar.setStatusColor('green');
      this.ims.progressbar.status(ExportToExcelConstants.noNegotiationHistoryMessage);
      this.ims.progressbar.showButtons(false);
      this.ims.progressbar.showButton(ExportToExcelConstants.returnButton);
    } else {
      let links: string = '<center>';
      let index: number = 0;

      for (let i = 0; i < jobObject.messages.length; ++i) {
        if (jobObject.messages[i].key === 'Filename') {
          const filepath: string = jobObject.messages[i].value;
          let filename: string = filepath.replace(/^.*[\\\/]/, '');
          filename = filename.replace('-' + jobObject.jobId, '');
          links += '<a class=\'a-multiple-export\' href=\'' +
            this.ims.progressbar.getBaseUrl() +
            '/api/exports/get/' + jobObject.jobId +
            '/' + jobObject.messages[i].messageId +
            '\'><div class=\'btn btn-custom-mutliple-export\' style=\'display:inline\'>' +
            filename +
            '</div></a><br /><br />';

          ++index;
        }
      }

      links += '</center>';

      const ims: any = this.ims;
      if (index > 1) {
        $('head').append(
          '<style>' +
          '.a-multiple-export:hover {text-decoration:none;}' +
          '.btn-custom-mutliple-export {color:#fff;background-color:#3ACF93;}' +
          '.btn-custom-mutliple-export:hover {color:#fff;background-color:#138758;}' +
          '</style>');
        $('.progress-modal-footer').prepend('<center><div style=\'width:100%;border:1px solid #e5e5e5;border-radius: 6px;padding: 4px;\'> ' +
          '<div style=\'font-family:Helvetica,Arial;font-size:14px;color:#333;line-height:1.42857143;font-weight:bold;float:left;\'>' +
          '&nbsp;Your exports are ready:</div><center><br><br>' + links + '</center></div></center><br>');

        this.ims.progressbar.showButtons(false);
        this.ims.progressbar.showButton(ExportToExcelConstants.returnButton);
      } else {
        this.ims.progressbar.downloadFile();
        setTimeout(() => {
          if (!this.ims.progressbar.isInputVisible()) {
            this.ims.progressbar.hide();
          }
        }, 1000);
      }
    }
  }

  imsProgressBarOnTimeout() {
    this.ims.progressbar.showButtons(false);
    this.ims.progressbar.showButton(ExportToExcelConstants.returnButton);
    this.ims.progressbar.status(ExportToExcelConstants.timeoutMessage);
  }
}
