import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '../../../common/general/services/http.service';
import { APIResponse } from '../../../models/user/api-response.model';
import { CroAccessInfo, CroAccessInfoToSave } from '../../../models/user/cro-access/cro-access-info.model';
import { ContactRole, ContactRoleToSave } from '../../../models/user/contact-role/contact-role.model';
import { ClassicNextMapping } from '../../../models/tabs/classic-next-mapping';

@Injectable({ providedIn: 'root' })
export class RoleService {

	constructor(private httpService: HttpService) { }

	GetCroAccessPrivileges(contactId: number): Observable<APIResponse<CroAccessInfo[]>> {
		const url = './UserSecurityDefault/GetCroAccessPrivileges';
		const options = { params: new HttpParams().set('contactOwnerId', (!!contactId) ? contactId.toString() : "0") };

		return this.httpService.safeGet<APIResponse<CroAccessInfo[]>>({ url, options });
	}

	SaveCroAccessPrivileges(contactId: number, croParams: CroAccessInfoToSave[]): Observable<APIResponse> {
		const url = './UserSecurityDefault/SaveCroAccessPrivileges';
		const body = {
			contactId,
			croParams
		};

		return this.httpService.safePost<APIResponse>({ url, body });
	}

	GetContactRoles(): Observable<APIResponse<ContactRole[]>> {
		const url = './ContactRoleAPI/GetContactRoles';

		return this.httpService.safeGet<APIResponse<ContactRole[]>>({ url });
	}

	GetContactRolesByUser(contactId?: number): Observable<APIResponse<ContactRole[]>> {
		const url = './PermissionAPI/GetContactRoleByUser';
		const options = { params: new HttpParams().set('contactId', contactId ? contactId.toString() : "0") };

		return this.httpService.safeGet<APIResponse<ContactRole[]>>({ url, options });
	}


	GetTabsMapping(): Observable<APIResponse<ClassicNextMapping[]>> {
		const url = './PermissionAPI/GetTabsMapping';
		return this.httpService.safeGet<APIResponse<ClassicNextMapping[]>>({ url });
	}

	SaveContactRoles(contactId: number, contactRoles: ContactRoleToSave[]): Observable<APIResponse> {
		const url = './ContactRoleAPI/SaveRoleContact';
		const body = {
			contactId,
			contactRoles
		};

		return this.httpService.safePost<APIResponse>({ url, body });
	}
}
