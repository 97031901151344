import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NewStudyDetailsData } from '../../models/study-details/new-study-details-model';
import { catchError } from 'rxjs/operators';
import { ExistingStudyDetailsData } from '../../models/study-details/existing-study-details-data-model';
import { throwError } from 'rxjs';
import { HttpService } from 'projects/shared/src/app/common/general/services/http.service'; 

@Injectable({ providedIn: 'root' })
export class StudyDetailsService {

  constructor(private http: HttpClient, private httpService: HttpService) { }

  InitializeSessionData(studyId: number) {
    const url: string = `./StudyDetails/InitializeSessionData?studyId=${studyId}`;
    return this.httpService.safeGet({url});
  }

  InitStudyDetails(isNew: boolean) {
    const url = './StudyDetailsAPI/InitStudyDetails';
    const options = {params: new HttpParams().set('isNew', isNew.toString())};
    return this.httpService.safeGet<NewStudyDetailsData>({url, options});
  }

  GetStudyDetails() {
    const url = './StudyDetailsSessionSafe/GetStudyDetails';
    return this.httpService.safeGet<ExistingStudyDetailsData>({url});
  }

  UnSetFirstNewStudyFlag() {
    const url = './StudyDetailsAPI/UnSetFirstNewStudyFlag';
    return this.httpService.safePost({url, body: null});
  }

  IsStudyUnique(studyCode, bmarcref) {
    const url = './StudyDetailsAPI/IsStudyUnique';
    const httpOptions = {
      params: new HttpParams().set('studyCode', studyCode).set('bmarcref', bmarcref)
    };

    return this.http.get<boolean>(url, httpOptions).pipe(catchError(errorRes => {
      // $rootScope.$broadcast('UnhandleErrorWasThrown'); TODO:revisit
      console.log('Unable to validate study code: ' + errorRes.message);
      return throwError(errorRes);
    }));
  }

  SaveStudyDetails(body) {
    const url = './StudyDetailsAPI/SaveStudyDetails';
    return this.httpService.safePost({url, body});
  }

  ArchiveStudy() {
    const url = './StudyDetailsAPI/ArchiveStudy';
    return this.httpService.safePost({url, body: ''});
  }

  ArchiveStudyCollection(body) {
    const url = './StudyDetailsAPI/ArchiveStudyCollection';
    return this.httpService.safePost({url, body});
  }

  UnArchiveStudy() {
    const url = './StudyDetailsAPI/UnArchiveStudy';
    return this.httpService.safePost({url, body: ''});
  }

  AgreeStudy() {
    const url = './StudyDetailsAPI/AgreeStudy';
    return this.httpService.safePost({url, body: ''});
  }

  DisagreeStudy() {
    const url = './StudyDetailsAPI/DisagreeStudy';
    return this.httpService.safePost({url, body: ''});
  }

  DeleteStudy() {
    const url = './StudyDetailsAPI/DeleteStudy';
    return this.httpService.safePost({url, body: ''});
  }
  
}
