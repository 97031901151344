import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component(
  {
    styleUrls: ['../cell-renderers.css'],
    selector: 'gp-ui-grid-substudy-visits-renderer',
    templateUrl: './grid-substudy-visits-renderer.component.html'
  })
export class GridSubstudyVisitsRendererComponent implements ICellRendererAngularComp {
  private rendererParams: ICellRendererParams = undefined;

  agInit(params: ICellRendererParams): void {
    this.rendererParams = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.rendererParams = params;

    return true;
  }

  setSubstudyVisits(visitsValue: string) {
    let visits: number = (visitsValue !== '') ? Number.parseInt(visitsValue, 10) : 0;
    if (Number.isNaN(visits)) {
      visits = 0;
    }

    this.rendererParams.data[this.rendererParams.colDef.field] = visits;
    this.rendererParams.context.parent.updateSubstudyVisits(this.rendererParams.data['SUBStudyId'], visits);
  }

  onSubstudyVisitsChange(event: any) {
    this.setSubstudyVisits(event.target.value);
  }

  onSubstudyVisitsKeypress(event: any) {
    this.setSubstudyVisits(event.target.value);
  }

  get editMode(): boolean {return this.rendererParams.context.isEditMode; }
  get inputValue(): number {return this.rendererParams.value; }
}
