import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component(
  {
    selector: 'gp-ui-add-to-grant-total-cell',
    templateUrl: 'search-grid-grant-total-renderer.component.html'
  })
  export class SearchGridGrantTotalRendererComponent implements ICellRendererAngularComp {
    public showCheck: boolean = false;
    private rendererParams: ICellRendererParams = undefined;
  
    agInit(params: ICellRendererParams): void {
      this.rendererParams = params;
      this.showCheck = this.ShowCheck();
    }
  
    refresh(params: ICellRendererParams): boolean {
      this.rendererParams = params;
      this.showCheck = this.ShowCheck();
  
      return true;
    }
  
    private ShowCheck(): boolean {
      return (this.rendererParams.value === true || this.rendererParams.value === 'true' || this.rendererParams.value === 1);
    }
  }  
