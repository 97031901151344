import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoadingService } from 'projects/shared/src/app/common/loading/services/loading.service';
import { UserModalTab } from 'projects/shared/src/app/constants/user/user-modal-tabs';
import { UserDetails, UserDetailsAPI } from 'projects/shared/src/app/models/user/user/user-details.model';
import { CountryService } from 'projects/shared/src/app/services/user/country/country.service';
import { SelectedUserService } from 'projects/shared/src/app/services/user/user/selected-user.service';
import { UserService } from 'projects/shared/src/app/services/user/user/user.service';
import { ERRORS } from 'projects/shared/src/app/constants/user/error-messages';

@Component({
  selector: 'app-details-tab',
  templateUrl: './details-tab.component.html',
  styleUrls: ['./details-tab.component.css']
})
export class DetailsTabComponent implements OnInit {
  TAB = UserModalTab.Details;

  @Output() closeModalEvent = new EventEmitter();

  countriesList: any[];
  detailsForm: FormGroup;
  isEditMode: boolean = false;
  titleList = ["Dr.", "Mr.", "Mrs.", "Ms."];

  existingUser: UserDetails; // User printed in Details Form
  userDetails: UserDetailsAPI; // Full retrieved UserData

  constructor(
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private countryService: CountryService,
    private selectedUserService: SelectedUserService,
    private userService: UserService) {

    this.buildDetailsForm();
    this.toggleEditMode(this.selectedUserService.IsNew);
  }

  get detailsFormControls() {
    return this.detailsForm.controls;
  }

  ngOnInit(): void {
    this.setUpInitialData();
  }

  onCancel(): void {
    if (!this.selectedUserService.UserId) {
      this.closeModalEvent.emit();
    } else {
      this.detailsForm.setValue(this.existingUser);
      this.toggleEditMode(false);
    }
  }

  onSave(): void {
    if (this.detailsForm.valid) {

      const newUser = this.detailsForm.value;

      this.userDetails = {
        ...this.userDetails,
        Title: newUser.title,
        FirstName: newUser.firstName,
        MidName: newUser.midName,
        LastName: newUser.lastName,
        Email: newUser.email,
        CountryShortId: newUser.country,
        Department: newUser.department,
        Position: newUser.position,
        IsLockedAccount: newUser.isAccountLocked.toString(),
        Address: newUser.address,
        City: newUser.city,
        State: newUser.state,
        Zip: newUser.zipCode,
        Phone: newUser.phone,
        Fax: newUser.fax,
      };

      if (!this.selectedUserService.IsNew) {
        this.userDetails.ContactId = this.selectedUserService.UserId;
      }

      this.saveUser(newUser);
    }
  }

  toggleEditMode(enabled: boolean): void {
    this.isEditMode = enabled;
    enabled ? this.detailsForm.enable() : this.detailsForm.disable();
  }

  private buildDetailsForm(): void {
    const namesValidator = Validators.compose([
      Validators.required, Validators.minLength(2), Validators.maxLength(30)
    ]);
    this.detailsForm = this.formBuilder.group({
      title: [''],
      firstName: ['', namesValidator],
      midName: [''],
      lastName: ['', namesValidator],
      email: ['', [Validators.required, Validators.email]],
      country: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
      department: ['', Validators.maxLength(30)],
      position: ['', Validators.maxLength(30)],
      isAccountLocked: [false],
      address: ['', Validators.maxLength(30)],
      city: ['', Validators.maxLength(30)],
      state: ['', Validators.maxLength(10)],
      zipCode: ['', Validators.maxLength(10)],
      phone: ['', Validators.maxLength(20)],
      fax: ['', Validators.maxLength(20)],
    });
  }

  private fillDetailsForm(): void {
    this.existingUser = {
      title: this.userDetails.Title,
      firstName: this.userDetails.FirstName,
      midName: this.userDetails.MidName,
      lastName: this.userDetails.LastName,
      email: this.userDetails.Email,
      country: this.userDetails.CountryShortId,
      department: this.userDetails.Department,
      position: this.userDetails.Position,
      isAccountLocked: this.userDetails.IsLockedAccount.toLowerCase() === "true",
      address: this.userDetails.Address,
      city: this.userDetails.City,
      state: this.userDetails.State,
      zipCode: this.userDetails.Zip,
      phone: this.userDetails.Phone,
      fax: this.userDetails.Fax,
    };

    this.detailsForm.setValue(this.existingUser);
  }

  private saveUser(newUser: UserDetails): void {
    this.loadingService.waitObservableWithSpinner(
      this.userService.SaveUserDetails(this.userDetails))
      .subscribe(response => {
        if (response.data < 0) {
          alert(ERRORS.EMAIL_ALREADY_REGISTERED);
        } else {
          this.existingUser = newUser;
          this.toggleEditMode(false);

          const newUserId = response.data;

          this.selectedUserService.castSavedUser(newUserId);

          if (this.selectedUserService.IsNew) {
            this.selectedUserService.castSelectedUser(newUserId);
          }
        }
      });
  }

  private setUpInitialData(): void {
    const observables: any[] = [];

    observables.push(this.countryService.getAllCountries());
    (!this.selectedUserService.IsNew) &&
      observables.push(this.userService.GetUserDetailsById(this.selectedUserService.UserId));

    const initialData$ = combineLatest(observables).pipe(
      map(([countriesResp, userResp]) => {
        return [countriesResp, userResp];
      })
    );

    this.loadingService.waitObservableWithSpinner(initialData$)
      .subscribe(([countriesResp, userResp]) => {
        this.countriesList = countriesResp.Countries.filter(x => x.CountryShortId !== "ALL");
        if (!this.selectedUserService.IsNew) {
          this.userDetails = userResp.data;
          this.fillDetailsForm();
        }
      });
  }

}