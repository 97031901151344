import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component(
  {
    selector: 'gp-ui-confirm-modal-component',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.css']
  })
export class ConfirmModalComponent {
  @Input() title: string;
  @Input() message: string;
  @Input() showCancel: boolean;

  constructor(public activeModal: NgbActiveModal) {}

  handleOkClick() {
    this.activeModal.close('Modal closed with OK.');
  }

  handleCancelClick() {
    this.activeModal.dismiss('Modal dismissed with Cancel.');
  }

}
