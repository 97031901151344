import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { MatDialogModule } from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from 'projects/shared/src/app/app.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from 'projects/shared/src/app/utility/app.init';
import { AccountService } from 'projects/shared/src/app/common/security/services/account.service';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    SharedModule,
    KeycloakAngularModule,
    AppRoutingModule
  ],
  providers: [
  { provide: APP_INITIALIZER, useFactory: initializeKeycloak, multi: true, deps: [KeycloakService, AccountService] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
