import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { BudgetColumnValidationModel } from 'projects/study-details/src/app/models/budget-column/budget-column-validation.model';

@Injectable({
      providedIn: 'root'
})
export class MessageService {
   private budgetColumnFieldValidityChangedSrc: BehaviorSubject<BudgetColumnValidationModel> =
      new BehaviorSubject<BudgetColumnValidationModel>({fieldName: '', isValid: true, validationText: ''});
   budgetColumnFieldValidityChanged: Observable<BudgetColumnValidationModel> = this.budgetColumnFieldValidityChangedSrc.asObservable();

   private countriesFromModalSavedSrc: BehaviorSubject<any> = new BehaviorSubject<any>({addedCountries: [], deletedCountries: []});
   countriesFromModalSaved: Observable<any> = this.countriesFromModalSavedSrc.asObservable();

   private editModeUpdatedSrc: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
   editModeUpdated: Observable<boolean> = this.editModeUpdatedSrc.asObservable();

   private srcInputHeaderChangedSrc: BehaviorSubject<any> = new BehaviorSubject<any>({});
   srcInputHeaderChanged: Observable<any> = this.srcInputHeaderChangedSrc.asObservable();

   private isNavbarTabsVisibleSrc: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
   isNavbarTabsVisibleObservable: Observable<boolean> = this.isNavbarTabsVisibleSrc.asObservable();

   private updateHeaderNavbarTitleSrc: BehaviorSubject<string> = new BehaviorSubject<string>('');
   updateHeaderNavbarTitleObservable: Observable<string> = this.updateHeaderNavbarTitleSrc.asObservable();

   changeSrcInputHeader(data: any) {
      this.srcInputHeaderChangedSrc.next(data);
   }

   saveCountriesFromModal(data: any) {
      this.countriesFromModalSavedSrc.next(data);
   }

   setBudgetColumnFieldValidity(data: BudgetColumnValidationModel) {
      this.budgetColumnFieldValidityChangedSrc.next(data);
   }

   updateEditMode(editMode: boolean) {
      this.editModeUpdatedSrc.next(editMode);
   }

   isNavbarTabsVisible(showTabs: boolean){
      this.isNavbarTabsVisibleSrc.next(showTabs);
   }

   updateHeaderNavbarTitle(title: string){
      this.updateHeaderNavbarTitleSrc.next(title);
   }
}
