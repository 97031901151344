import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { NestedTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { TreeNestedNode } from '../../../models/items-tree-component/items-tree-component.model';
import { ContactRole } from '../../../models/user/contact-role/contact-role.model';

@Component({
  selector: 'gp-ui-app-items-tree',
  templateUrl: './items-tree.component.html',
  styleUrls: ['./items-tree.component.css']
})
export class ItemsTreeComponent<T extends TreeNestedNode | ContactRole> implements OnInit, OnChanges {

  @Input() dataInput: T[];
  @Input() searchText: string;
  @Input() isDisabled: boolean;
  @Input() compare: (x: T, y: T) => number;
  @Input() search: (x: T[], y: string) => T[];
  @Output() dblClickEvent = new EventEmitter<T>();

  private originalData: T[] = [];
  dataSource = new MatTreeNestedDataSource<T>();
  treeControl = new NestedTreeControl<TreeNestedNode>((node) => node.children);
  checklistSelection = new SelectionModel<T>(true);

  ngOnInit(): void {
    this.dataSource.data = this.dataInput;
    this.originalData = this.dataInput;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["dataInput"]) {
      this.dataSource.data = this.originalData = (!!this.compare)
        ? (changes["dataInput"].currentValue as T[]).sort(this.compare)
        : (changes["dataInput"].currentValue as T[]);
    }
    this.dataSource.data = this.filteringData(this.originalData, this.searchText);
  }

  itemSelectionToggle(node: T, event: any): void {
    if (!this.isDisabled) {
      (!event.ctrlKey) && this.checklistSelection.clear();
      this.checklistSelection.toggle(node);
    }
  }

  hasChildren = (_: number, node: TreeNestedNode) => !!node.children && node.children.length > 0;

  onItemDbClick(): void {
    !this.isDisabled && this.dblClickEvent.emit();
  }

  private filteringData(data: T[], searchText: string): T[] {
    return this.search(data, searchText);
  }
}
