import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'gp-ui-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.css']
})
export class ToggleButtonComponent implements OnInit, OnChanges {

  @Input() trueColor = '#CCCCCC'; // initally disabled color
  @Input() isDisabled = false;
  backgroundColor = '#028CBB';
  stateTextColor = 'black';
  falseColor = '#AAAAAA';
  color = 'black';
  @Input() textInside = false;
  @Input() buttonState = false;
  @Input() labelText = '';
  stateText: string;

  @Output() toggledValue: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.buttonState) {
      this.setStateText();
    }
  }

  ngOnInit() {
    this.setStateText();
  }

  getColorStyle() {
    return this.color;
  }

  getStateTextStyle() {
    return this.stateTextColor;
  }

  get BackgroundColorStyle(): string {
    return this.backgroundColor;
  }

  setStateText() {
    if (this.buttonState === true) {
      this.stateText = 'on';
      this.color = '#00AAE7';
      this.backgroundColor = '#028CBB';
      this.stateTextColor = '#FFF';
    } else {
      this.stateText = 'off';
      this.color = '#999999';
      this.backgroundColor = '#AAAAAA';
      this.stateTextColor = '#000';
    }
  }

  click() {
        this.buttonState = !this.buttonState;
        this.setStateText();
        this.toggledValue.emit(this.buttonState);
  }

}
