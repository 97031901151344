import { Component, OnInit, Inject, ViewChild, ApplicationRef, OnDestroy } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountryNode } from '../../models/country-node';
import { CountriesTreeComponent } from './countries-tree/countries-tree.component';
import { AddCountriesOutput } from '../../models/Add-Countries-Output';
import { ConfirmDialogService } from '../../../common/confirm/services/confirm-dialog.service'; 
@Component({
    selector: 'gp-ui-app-add-countries-modal',
    templateUrl: './add-countries-modal.component.html',
    styleUrls: ['./add-countries-modal.component.css']
})
export class AddCountriesModalComponent implements OnInit, OnDestroy {
    @ViewChild('available', {static: false}) availableCountriesTree: CountriesTreeComponent;
    @ViewChild('selected', {static: false}) selectedCountriesTree: CountriesTreeComponent;

    studyCountries: any;
    allCountries: any;
    regions: any;
    searchText: string;
    studyRegions: Array<CountryNode>;
    availableCountry: Array<CountryNode> = [];
    selectedCountry: Array<CountryNode> = [];
    availableRegions: Array<CountryNode> = [];
    selectedRegions: Array<CountryNode> = [];
    addedCountries: Array<CountryNode> = [];
    removedCountries: Array<CountryNode> = [];
    output: AddCountriesOutput;
    intervalId: number;
    minimumSelectedCountries: number = 0;

    constructor(
        public dialogRef: MatDialogRef<AddCountriesModalComponent>,
        public applicationRef: ApplicationRef,
        @Inject(MAT_DIALOG_DATA) public data: any, public confirmDialog: ConfirmDialogService) {
        this.searchText = '';
        this.studyCountries = data.studyCountries;
        this.allCountries = data.allCountries;
        this.regions = data.regions;
        this.studyRegions = [];
        this.minimumSelectedCountries = data.minimumSelectedCountries || 0;
        this.convertDataStudyCountries();
        this.convertDataAllCountries();
        if (data.forceRefresh) {
            this.applicationRef.tick();
            this.intervalId = window.setInterval(() => { this.applicationRef.tick(); }, 50);
        }
    }

    ngOnInit() { }

    ngOnDestroy(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    addSelectedCountryInAvailable() {
        this.addSelectedCountries();
    }

    convertDataStudyCountries() {
        this.studyCountries.forEach(country => {
            if (country.RegionName === '') {
                const countryNode: CountryNode = {
                    name: country.CountryEquivalentId !== -1 ? country.CountryName + ' (CE)' : country.CountryName,
                    shortID: country.CountryShortId
                };
                this.selectedCountry.push(countryNode);
            } else {
                const countryNode: CountryNode = { name: country.RegionName, shortID: country.CountryShortId };
                this.selectedRegions.push(countryNode);
            }
        });

        this.selectedCountry.forEach(country => {
            if (this.regions.length > 0 && country.shortID === 'USA') {
                country.regions = this.selectedRegions;
                this.studyRegions = JSON.parse(JSON.stringify(this.selectedRegions));
            }
        });
    }

    convertDataAllCountries() {
        this.allCountries.forEach(country => {
            if (country.CountryShortId !== 'ALL') {
                const countryNode: CountryNode = {
                    name: country.RequiresEquivalent ? country.CountryName + ' (CE)' : country.CountryName,
                    shortID: country.CountryShortId
                };
                this.availableCountry.push(countryNode);
            }
        });
        this.regions.forEach(country => {
            const countryNode: CountryNode = { name: country.CountryName, shortID: country.CountryShortId };
            this.availableRegions.push(countryNode);
        });
        this.availableCountry.forEach(country => {
            if (this.regions.length > 0 && country.shortID === 'USA') {
                country.regions = this.availableRegions;
            }
        });
        this.removeFromAvailable(this.selectedCountry);
    }

    addToRemovedList(node: CountryNode) {
        const item: CountryNode = this.addedCountries.find(country => country.shortID === node.shortID);
        if (item === undefined) {
            this.removedCountries.push(node);
        } else {
            const list = this.addedCountries.filter(country => {
                return node.shortID !== country.shortID;
            });
            this.addedCountries = [...list];
        }
        this.validateRemovedRegions();
    }

    addToSelectedList(node: CountryNode) {
        const item: CountryNode = this.removedCountries.find(country => country.shortID === node.shortID);
        if (item === undefined) {
            this.addedCountries.push(node);
        } else {
            const list = this.removedCountries.filter(country => {
                return node.shortID !== country.shortID;
            });
            this.removedCountries = [...list];
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onOkClick() {
        this.addedCountries = Array.from(this.addedCountries.reduce((m, t) => m.set(t.name, t), new Map()).values());
        this.removedCountries = Array.from(this.removedCountries.reduce((m, t) => m.set(t.name, t), new Map()).values());
        if (this.getUSFromSelected() !== undefined) {
            this.removedCountries = this.removedCountries.filter(country => country.shortID !== 'USA');
        }
        this.output = { addedCountries: this.addedCountries, removedCountries: this.removedCountries };
        if (this.removedCountries.length > 0) {
            let message = 'Are you sure you want to delete the following country(s): ';
            message += this.removedCountries.map(x=>x.name).join(", ");
            message += '?';
          this.confirmDialog.show('Delete', message, true, 480, this.confirmDialog.AutoSize).subscribe(response => {
                if (response) {
                    this.dialogRef.close(this.output);
                } else {
                    this.convertDataAllCountries();
                    this.convertDataStudyCountries();
                    this.availableCountry = [...this.availableCountry];
                    this.selectedCountry = [...this.selectedCountry];
                    this.removeFromAvailable(this.selectedCountry);
                    this.addSelectedCountries(this.removedCountries);
                    this.selectedCountry = Array.from(this.selectedCountry.reduce((m, t) => m.set(t.name, t), new Map()).values());
                    if (this.getUSFromSelected()) {
                        this.getUSFromSelected().regions = Array.from(this.getUSFromSelected().regions.reduce((m, t) => m.set(t.name, t),
                            new Map()).values());
                    }
                    this.availableCountry = Array.from(this.availableCountry.reduce((m, t) => m.set(t.name, t), new Map()).values());
                    this.availableCountry.forEach(country => {
                        if (country.shortID === 'USA') {
                            country.regions = Array.from(country.regions.reduce((m, t) => m.set(t.name, t), new Map()).values());
                        }
                    });
                }
            });
        } else {
            this.dialogRef.close(this.output);
        }
    }

    validateRemovedRegions() {
        this.removedCountries = this.removedCountries.filter(country => {
            if (this.checkIfRegion(country)) {
                const regionInStudyRegions = this.studyRegions.find(region => {
                    return region.shortID === country.shortID;
                });
                return regionInStudyRegions !== undefined;
            }
            return true;
        });
    }

    compare(a: CountryNode, b: CountryNode) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    validateMinimumSelected() {
        return this.minimumSelectedCountries > this.selectedCountry.length;
    }

    addSelectedCountries(availableCountries?) {
        const newAddCountries = availableCountries ? availableCountries : this.availableCountriesTree.getSelectionList();
        newAddCountries.forEach(country => {
            const response = this.checkUSAInSelected();
            if (country.shortID === 'USA' && !response) {
                if (this.regions.length > 0) {
                    const countryNode: CountryNode = { name: country.name, shortID: country.shortID, regions: country.regions };
                    this.selectedCountry = [...this.selectedCountry, countryNode];
                    this.selectedCountry.sort(this.compare);
                    this.addToSelectedList(countryNode);
                } else {
                    const countryNode: CountryNode = { name: country.name, shortID: country.shortID };
                    this.selectedCountry = [...this.selectedCountry, countryNode];
                    this.selectedCountry.sort(this.compare);
                    this.addToSelectedList(countryNode);
                }

                return;
            }
            const us: CountryNode = this.getUSFromSelected();
            if (us !== undefined && country.shortID === 'USA') {
                country.regions.forEach(region => {
                    const regionNode: CountryNode = { name: region.name, shortID: region.shortID };
                    us.regions.push(regionNode);
                    this.addToSelectedList(regionNode);
                });
                us.regions.sort(this.compare);
                return;
            }
            if (country.shortID !== 'USA') {
                const countryNode: CountryNode = { name: country.name, shortID: country.shortID };
                this.addToSelectedList(countryNode);
                this.selectedCountry = [...this.selectedCountry, countryNode];
                return;
            }
        });
        const usFromSelected: CountryNode = this.getUSFromSelected();
        if (usFromSelected !== undefined) {
            this.removeFromSelected(usFromSelected.regions);
        }
        this.removeFromAvailable(this.selectedCountry);
        this.selectedCountry = this.selectedCountry.sort(this.compare);
      this.searchText = '';
      this.validateMinimumSelected();
    }

    getUSFromSelected() {
        let countryNode: CountryNode;
        this.selectedCountry.forEach(country => {
            if (country.shortID === 'USA' && this.regions.length > 0) {
                countryNode = country;
            }
        });
        return countryNode;
    }

    checkUSAInSelected() {
        let response: boolean = false;
        this.selectedCountry.forEach(country => {
            if (country.shortID === 'USA') {
                response = true;
            }
        });
        return response;
    }

    checkIfRegion(countryNode: CountryNode): boolean {
        let response: boolean = false;
        this.regions.forEach(region => {
            if (region.CountryShortId === countryNode.shortID) {
                response = true;
            }
        });
        return response;
    }

    addAllCountries() {
        this.availableCountriesTree.selectAllCountries(this.regions.length);
        const avCountries = this.availableCountriesTree.getSelectionList();
        const usAvailable = this.availableCountry.find(country => country.shortID === 'USA');
        avCountries.forEach(country => {
            if (country.shortID === 'USA' && this.regions.length > 0) {
                country.regions = usAvailable.regions;
            }
        });
      this.addSelectedCountries(avCountries);
      this.validateMinimumSelected();
    }

    removeAllCountries() {
        this.selectedCountriesTree.selectAllCountries(this.regions.length);
        this.removeSelectedCountries();
    }

    removeSelectedCountries() {
        this.selectedCountriesTree.getSelectionList().forEach(country => {
            const countryNode: CountryNode = { name: country.name, shortID: country.shortID };
            this.addToRemovedList(countryNode);
            const response = this.checkUSA();
            if (this.checkIfRegion(country)) {
                this.availableCountry.forEach(avCountry => {
                    if (avCountry.shortID === 'USA') {
                        avCountry.regions.push(country);
                    }
                });
                return;
            }
            if ((!response && country.shortID === 'USA') || country.shortID !== 'USA') {
                this.availableCountry = [...this.availableCountry, countryNode];
            }
            if (country.regions.length === 0 && country.shortID === 'USA') {
                const countryFiltered = this.selectedCountry.find(item => {
                    return item.shortID === 'USA';
                });
                if (this.regions.length > 0) {
                    this.addRemovedRegions(countryFiltered);
                    this.removeChildFromSelected(countryFiltered, countryFiltered);
                    this.getUsFromAvailableList().regions = this.availableRegions;
                }
                return;
            }
            if (country.shortID === 'USA' && this.regions.length > 0 ) {
                countryNode.regions = [];
                this.addRemovedRegions(country);
                this.removeFromAvailable(country.regions);
            }
        });
        this.availableCountry.sort(this.compare);
        this.removeFromSelected(this.availableCountry);
        this.removeSubRegionsFlag();
      this.availableCountry = [...this.availableCountry];
      this.validateMinimumSelected();
    }

    removeSubRegionsFlag() {
        const countryNode = this.availableCountry.find(country => {
            return country.shortID === 'USA';
        });
        if (countryNode !== undefined && this.regions.length > 0) {
            countryNode.regions.forEach(region => {
                region.regions = undefined;
            });
            countryNode.regions = Array.from(countryNode.regions.reduce((m, t) => m.set(t.name, t), new Map()).values());
            countryNode.regions.sort(this.compare);
        }
    }

    getUsFromAvailableList() {
        return this.availableCountry.find(country => {
            return country.shortID === 'USA';
        });
    }

    getUsFromSelectionList() {
        return this.selectedCountriesTree.getSelectionList().find(country => {
            return country.shortID === 'USA';
        });
    }

    removeFromSelected(selectedCountries: Array<CountryNode>) {
        selectedCountries.forEach(country => {
            const filterCountries = this.selectedCountry.filter(item => {
                if (country.shortID.toLowerCase().indexOf(item.shortID.toLowerCase()) !== -1
                    && country.shortID === 'USA' && (item.regions !== undefined)) {
                    this.removeChildFromSelected(item, country);
                    if (item.regions.length === 0 && this.getUsFromSelectionList() !== undefined) {
                        return false;
                    }
                    return true;
                }
                return item.shortID.toLowerCase().indexOf(country.shortID.toLowerCase()) === -1;
            });
            this.selectedCountry = filterCountries;
        });
    }

    removeChildFromSelected(countryOne: CountryNode, countryTwo: CountryNode) {
            countryTwo.regions.forEach(region => {
                const regions = countryOne.regions.filter(item => {
                    return item.shortID.toLowerCase().indexOf(region.shortID.toLowerCase()) === -1;
                });
                countryOne.regions = regions;
            });
    }

    checkUSA() {
        let response: boolean = false;
        this.availableCountry.forEach(country => {
            if (country.shortID === 'USA') {
                response = true;
            }
        });
        return response;
    }

    addRemovedRegions(countryNode: CountryNode) {
        this.availableCountry.forEach(country => {
            if (country.shortID === 'USA' && country.regions !== undefined) {
                countryNode.regions.forEach(item => {
                    const region: CountryNode = { name: item.name, shortID: item.shortID };
                    country.regions = [...country.regions, region];
                    this.addToRemovedList(region);
                });
                country.regions.sort(this.compare);
            }
        });
    }

    removeFromAvailable(selectedCountries: Array<CountryNode>) {
        selectedCountries.forEach(country => {
            const filterCountries = this.availableCountry.filter(item => {
                if (country.shortID.toLowerCase().indexOf(item.shortID.toLowerCase()) !== -1
                    && country.shortID === 'USA' && (item.regions !== undefined)) {
                    this.removeChildFromAvailable(item, country);
                    if (item.regions.length === 0) {
                        return false;
                    }
                    return true;
                }
                return item.shortID.toLowerCase().indexOf(country.shortID.toLowerCase()) === -1;
            });
            this.availableCountry = filterCountries;
        });
    }

    removeChildFromAvailable(countryOne: CountryNode, countryTwo: CountryNode) {
        if (countryTwo.regions !== undefined && countryTwo.regions.length > 0) {
            countryTwo.regions.forEach(region => {
                const regions = countryOne.regions.filter(item => {
                    return item.shortID.toLowerCase().indexOf(region.shortID.toLowerCase()) === -1;
                });
                countryOne.regions = regions;
            });
        }
    }

}
