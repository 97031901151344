import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProgressBarService } from '../../common/general/services/progress-bar.service';

@Injectable({
  providedIn: 'root'
})
export class InactivityInspectorService {
  private timeToCheckApi: number=90000;
  public lastActive: Date;
  private timeOutHandle:any;
  private timeOutInvocation:any;
  public timeoutSessionFunction:any;
  private timeToSessionEnds: number=1200000;
  private sessionSubscription: Subscription;
  private lastUserAction: Date = new Date();
  private events: string[] = ['click', 'keypress', 'scroll'];

  private bc : BroadcastChannel;
  private iframe: boolean;

  constructor(private sessionService: ProgressBarService) {  }

  listenActivity(): void {    
    this.events.forEach(e =>
      window.addEventListener(e,
        this.notifyActivity
      )
    );
    this.sessionSubscription = this.sessionService.onProgress.subscribe(
      this.notifyActivity
    );

    this.iframe = window.location !== window.parent.location;
    (window as any).notifyActivity = this.notifyActivity;
    if(!this.iframe) {
      this.bc = new BroadcastChannel('inactivityInspector');
      this.bc.onmessage = ()=> this.validateTimeApi();
      this.getModalTimeOut();      
      this.lastActive =new Date();    
      this.timeOutInvocation= setTimeout(()=>this.invocation(),this.timeToCheckApi);
    }    
  }

  public notifyActivity = () => {
    if(this.iframe) {
      this.notifyActivityIframe();
    } else {
      this.validateTimeApi();
      this.bc.postMessage('activity');
    }    
  };

  private notifyActivityIframe() {
    (window as any).parent.notifyActivity();
  }

  validateTimeApi() {
    this.lastUserAction=new Date(); 
    if (this.calctimeMiliSeconds() > this.timeToCheckApi) {
        clearTimeout(this.timeOutHandle);
        clearTimeout(this.timeOutInvocation);
        this.sessionService.pingSession().subscribe();
        this.timeOutInvocation= setTimeout(()=>this.invocation(),this.timeToCheckApi);
        this.lastActive = new Date();
    }
  }

  calctimeMiliSeconds() {   
    return new Date().getTime() - this.lastActive.getTime();      
  }

  calctimeLastUserAction() {
    let difference = (this.lastUserAction.getTime() - this.lastActive.getTime())
    return difference < 0 ? 0 : difference;
  }

  invocation(){
    this.sessionService.pingSession().subscribe();
    this.timeOutHandle = setTimeout(this.timeoutSessionFunction, (this.timeToSessionEnds - this.timeToCheckApi + this.calctimeLastUserAction()));
  }

  getModalTimeOut(){
    this.sessionService.getModalTimeOut()
    .subscribe((resp: any) => this.timeToSessionEnds=resp.UserLastActivityTimeout*1000*60)
  }

  stopListening(){
    clearTimeout(this.timeOutHandle);
    clearTimeout(this.timeOutInvocation);
    this.events.forEach(e =>
      window.removeEventListener(e,
        this.notifyActivity
      )
    );
    this.sessionSubscription.unsubscribe();
  }
}
