import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MultiselectModalComponent } from '../../components/multiselect-add-remove/multiselect-modal.component';
import { IMultiselectData, IReadingMetaData, IMultiselectModalUIOptions } from '../../models/multiselect-add-remove-component-options/multiselect-options';

@Injectable({
  providedIn: 'root'
})
export class MultiselectService {

  constructor(private dialog: MatDialog) {}

  public openModal<T>(metaData: IMultiselectModalUIOptions<T>, configuration: IReadingMetaData<T>) {
    metaData.multiselectMetaData.data = this.getHomologatedData(metaData.multiselectMetaData.data, configuration)
    const modalRef = this.dialog.open(MultiselectModalComponent, {
      width: '850px',
      height: '550px',
      data: metaData
      });
    return modalRef;
  }

  public getHomologatedData<T>(data: IMultiselectData<T>, configuration: IReadingMetaData<T>): IMultiselectData<T>{
    return {
      allAvailableItems: this.homologateData(data.allAvailableItems, configuration),
      selectedItems: this.homologateData(data.selectedItems, configuration)
    };   
  }

  private homologateData<T>(items: T[] | undefined, configuration: IReadingMetaData<T>) {
    return items && items.map(item => ({
      ...item,
      label: configuration.readLabel(item),
      children: configuration.readChildren && this.homologateData(configuration.readChildren(item), configuration)
    }));

  }

}