import {Component, Input} from '@angular/core';

@Component(
  {
    selector: 'gp-ui-line-items-container-title-component',
    templateUrl: './line-items-container-title.component.html',
    styleUrls: ['./line-items-container-title.component.css']
  })
export class LineItemsContainerTitleComponent {
  
  @Input() countryLogoUrl : string;
  @Input() title : string;
  @Input() complement : string;

  constructor() {}
}
