import { PERMISSIONS } from "projects/shared/src/app/constants/constants";
import { FormOptions } from "../../models/user/user/user-options.model";

export const DEFAULT_USER_OPTIONS_FORM: FormOptions = {
    benchmarksList: [],
    countriesList: [],
    currenciesList: [],
    decimalsList: [0, 1, 2, 3, 4, 5, 6],
    employeeSecurityLevelsList: [
        {
            optionId: PERMISSIONS.NO_ACCESS,
            optionDescription: "No Access",
        },
        {
            optionId: PERMISSIONS.READ_ONLY,
            optionDescription: "Read Only",
        },
        {
            optionId: PERMISSIONS.READ_WRITE,
            optionDescription: "Read & Write",
        },
    ],
    lookupBenchmarksList: [],
    overheadList: [],
    procedureDescriptionsList: [
        {
            optionId: 1,
            optionDescription: "a single line",
        },
        {
            optionId: 2,
            optionDescription: "mutiple line",
        },
    ],
    subStudiesList: [],
    whenAddingProceduresList: [
        {
            optionId: 1,
            optionDescription: "use the Select Procedure screen",
        },
        {
            optionId: 2,
            optionDescription: "use the 'Quick Lookup' feature",
        },
    ]
}