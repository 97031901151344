import { Injectable } from '@angular/core';
import { EventType } from '../../common/general/models/events';
import { EventBusService } from '../../common/general/services/event-bus.service';
import { fromEvent, Observable, of, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ConfirmDialogService } from '../../common/confirm/services/confirm-dialog.service';
import { APIResponse } from '../../models/user/api-response.model';
import { ClassicNextMapping } from '../../models/tabs/classic-next-mapping';
import { HttpService } from '../../common/general/services/http.service';

const CONFIRMATION_TITLE = 'Unsaved Changes';
const CONFIRMATION_MESSAGE = `You have unsaved changes on this page. Click OK to leave this page and discard your changes.
                              Click Cancel to continue working on this page.`;
@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private validateBeforeunload: boolean;
  public unsavedChanges: boolean;
  mappingObservable: ReplaySubject<ClassicNextMapping[]>;

  constructor(private dialogService: ConfirmDialogService,
    private eventBus: EventBusService,
    private httpService: HttpService) {
    this.subscribeUnsavedChanges();
    this.subscribeValidateBeforeunloadChanges();
    this.subscribeBeforeunloadHandler();
    this.validateBeforeunload = true;
    this.unsavedChanges = false;
  }

  subscribeBeforeunloadHandler = () => (fromEvent(window, 'beforeunload').subscribe(this.preventCloseWithoutSave));

  subscribeUnsavedChanges = () =>
    (this.eventBus.on(EventType.UnsavedChanges, (hasUnsavedChanges: boolean) => this.unsavedChanges = hasUnsavedChanges))

  subscribeValidateBeforeunloadChanges = () =>
    (this.eventBus.on(EventType.ValidateBeforeunload, (validateBeforeunload: boolean) => this.validateBeforeunload = validateBeforeunload))

  setValidateBeforeunloadEvent = (payload: boolean) => this.validateBeforeunload = payload;

  preventCloseWithoutSave = (event) => {
    if (this.validateBeforeunload && this.unsavedChanges) {
      event.preventDefault();
      event.returnValue = CONFIRMATION_MESSAGE;
    }
  }


  validateUnSavedChanges = () => {
    let response;

    if (this.unsavedChanges) {
      response = this.showUnsavedChangesModal().pipe( tap(navigate => this.unsavedChanges = !navigate));
    } else {
      response = of(true);
    }
    return response;
  }

  showUnsavedChangesModal = () => (this.dialogService.show(
    CONFIRMATION_TITLE,
    CONFIRMATION_MESSAGE,
    true,
    400,
    220
  ));

  getMappingRedirect(targetUrl: string): Observable<ClassicNextMapping> {
    const currentTab = this.getTabFromRouterUrl(targetUrl);
    return this.subscribeToMapping().pipe(
      map((mappings: ClassicNextMapping[]) => {
        let mapping = mappings.find(mapping => mapping.url.includes(currentTab))
        if (mapping && !mapping.isAccessibleFlag) {
          mapping = mappings.find(c => c.isAccessibleFlag && c.isTab);
        }
        return mapping;
      })
    );
  }

  private getTabFromRouterUrl(url: string): string {
    const urlArray = url.split('/');
    let currentTab = urlArray[1] == "Classic" ? urlArray[2] : urlArray[1];
    currentTab = currentTab.split('?')[0];
    return currentTab;
  }

  subscribeToMapping(): Observable<ClassicNextMapping[]> {
    const url = './PermissionAPI/GetTabsMapping';

    if (!this.mappingObservable) {
      this.mappingObservable = new ReplaySubject<ClassicNextMapping[]>(1);
      this.httpService.safeGet<APIResponse<ClassicNextMapping[]>>({ url }).subscribe((response) => {
        this.mappingObservable.next(response.data);
      });
    }
    return this.mappingObservable;
  }
}
