import { Component, OnInit, Output, EventEmitter, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { IMultiselectCheckboxConfig, IMultiselectCheckboxOptions } from '../../models/multiselect-checkbox/multiselect-checkbox-options';


@Component({
	selector: 'gp-multiselect-checkbox',
	templateUrl: './multiselect-checkbox.component.html',
	styleUrls: ['./multiselect-checkbox.component.css']
})
export class MultiselectCheckboxComponent<T> implements OnInit, OnChanges {

	@Input() editModeEnabled: boolean;
	@Input() options: IMultiselectCheckboxOptions<T>;
	@Output() outputDataEvent: EventEmitter<T[]> = new EventEmitter<T[]>();
	@ViewChild(MatSelectionList, { static: false }) selectionList: MatSelectionList;

	private config: IMultiselectCheckboxConfig<T>;

	allowSelectAll: boolean = true;
	allSelected: boolean = true;
	isEditMode: boolean = true;
	items: T[] = [];
	originalSelectedItems: T[] = [];
	title: string;

	constructor() { }

	ngOnInit(): void { }

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.options && changes.options.currentValue) {
			this.setUpComponent(changes.options.currentValue);
		}
		if (changes.editModeEnabled) {
			this.toggleEditMode(changes.editModeEnabled.currentValue);
		}
	}

	getChecked(item: T): boolean {
		return this.config.readChecked(item);
	}

	getLabel(item: T): string {
		return this.config.readLabel(item);
	}

	onSelectionChange(e): void {
		if (this.allowSelectAll) {
			if (e.option.value === "ALL") {
				this.selectionList.options.forEach(option => option.selected = e.option.selected);
			} else {
				const checkSelectAll = e.option.selected &&
					this.selectionList.selectedOptions.selected.length === this.selectionList.options.length - 1;

				this.selectionList.options.forEach(option => {
					if (option.value === "ALL") {
						option.selected = checkSelectAll;
					}
				});
			}
		}
		this.dataOutputChange(this.getSelectedItems());
	}

	public revertChanges(): void {
		this.selectionList.options.forEach(option =>
			option.selected = this.originalSelectedItems.includes(option.value));

		this.dataOutputChange(this.getSelectedItems());
	}

	private dataOutputChange(data: T[]): void {
		this.outputDataEvent.emit(data);
	}

	private getSelectedItems(): T[] {
		return this.selectionList.selectedOptions.selected
			.filter(option => option.value !== "ALL")
			.map(option => option.value);
	}

	private setUpComponent(options: IMultiselectCheckboxOptions<T>): void {
		this.allowSelectAll = options.allowSelectAll;
		this.config = options.config;
		this.items = options.data;
		this.originalSelectedItems = this.items.filter(item => this.getChecked(item));
		this.items.forEach(x => this.allSelected = this.allSelected && this.getChecked(x));
		this.title = options.title;

		this.dataOutputChange(this.originalSelectedItems);
	}

	private toggleEditMode(isEditMode: boolean): void {
		this.isEditMode = isEditMode;
	}
}