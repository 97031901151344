import { Component, OnInit, ViewChild } from '@angular/core';

import { IMultiselectCheckboxOptions } from 'projects/shared/src/app/models/multiselect-checkbox/multiselect-checkbox-options';
import { IMultiselectUIOptions } from 'projects/shared/src/app/models/multiselect-add-remove-component-options/multiselect-options';
import { LoadingService } from 'projects/shared/src/app/common/loading/services/loading.service';
import { MultiselectComponent } from 'projects/shared/src/app/components/multiselect-add-remove/multiselect/multiselect.component';
import { MultiselectCheckboxComponent } from 'projects/shared/src/app/components/multiselect-checkbox/multiselect-checkbox.component';
import { MultiselectService } from 'projects/shared/src/app/services/multiselect-add-remove-service/multiselect.service';
import { ROLES } from 'projects/shared/src/app/constants/constants';
import { UserModalTab } from 'projects/shared/src/app/constants/user/user-modal-tabs';
import { CroAccessInfo } from 'projects/shared/src/app/models/user/cro-access/cro-access-info.model';
import { ContactRole } from 'projects/shared/src/app/models/user/contact-role/contact-role.model';
import { RoleService } from 'projects/shared/src/app/services/user/role/role.service';
import { SelectedUserService } from 'projects/shared/src/app/services/user/user/selected-user.service';

@Component({
	selector: 'app-user-groups-tab',
	templateUrl: './user-groups-tab.component.html',
	styleUrls: ['./user-groups-tab.component.css']
})
export class UserGroupsTabComponent implements OnInit {
	TAB = UserModalTab.UserGroups;

	private readonly ROLE_CONDITION_FOR_TABS_SELECTOR = ROLES.SELECTIVE;

	@ViewChild(MultiselectCheckboxComponent, { static: false }) multiselectCheckbox: MultiselectCheckboxComponent<CroAccessInfo>;
	@ViewChild(MultiselectComponent, { static: false }) multiselectTree: MultiselectComponent<any>;

	groupsSelectorOptions: IMultiselectUIOptions<ContactRole>;
	tabsSelectorOptions: IMultiselectCheckboxOptions<CroAccessInfo>;

	contactId: number;
	isEditMode: boolean = false;
	isSelective: boolean = false;
	selectedGroups: ContactRole[] = [];
	selectedTabs: CroAccessInfo[] = [];
	showTabsSelector: boolean = false;

	constructor(
		private loadingService: LoadingService,
		private treeMultiselectService: MultiselectService,
		private roleService: RoleService,
		private selectedUserService: SelectedUserService) { }


	ngOnInit(): void {
		this.contactId = this.selectedUserService.UserId;

		this.setUpGroupsSelector();
	}

	onCancel(): void {
		this.showTabsSelector && this.multiselectCheckbox.revertChanges();
		this.multiselectTree.revertChanges();
		this.toggleEditMode(false);
	}

	onSave(): void {
		this.saveRoles();
	}

	onGroupsSelectionChange(data): void {
		this.selectedGroups = data;
		this.checkIfTabsSelectorShouldBeShown();
	}

	onTabsSelectionChange(data): void {
		this.selectedTabs = data;
	}

	toggleEditMode(isEditMode: boolean): void {
		this.isEditMode = isEditMode;
	}

	private checkIfSelectAllTabs(tabsData: CroAccessInfo[]): boolean {
		return tabsData.every(x => x.CROAccessPrivilegeId === 0) && !this.isSelective;
	}

	private checkIfTabsSelectorShouldBeShown() {
		this.showTabsSelector = this.selectedGroups.some(x => x.ContactRoleName.toUpperCase() === this.ROLE_CONDITION_FOR_TABS_SELECTOR);
	}

	private saveRoles(): void {
		const requestBody = this.selectedGroups.map(role => {
			return {
				ContactId: this.contactId,
				RoleContactId: role.ContactRoleId
			}
		});
		this.loadingService.waitObservableWithSpinner(
			this.roleService.SaveContactRoles(this.contactId, requestBody))
			.subscribe(response => {
				if (this.showTabsSelector) {
					this.saveTabs();
				} else {
					this.toggleEditMode(false);
				}
			});
	}

	private saveTabs(): void {
		const requestBody = this.selectedTabs.map(tab => {
			return {
				ContactId: this.contactId,
				IsAccessibleFlag: true,
				TabId: tab.TABId
			};
		});
		this.loadingService.waitObservableWithSpinner(
			this.roleService.SaveCroAccessPrivileges(this.contactId, requestBody))
			.subscribe(response => {
				this.toggleEditMode(false);
			});
	}

	private setUpGroupsSelector(): void {
		const priorityRoles = ['Super', 'Master', 'Selective', 'All Countries'];
		this.groupsSelectorOptions = {
			availableDisclaimerText: "GrantPlan Groups",
			selectedDisclaimerText: "User Groups",
			compare: (x: ContactRole, y: ContactRole) => {
				let indexX = priorityRoles.indexOf(x.ContactRoleName);
				let indexY = priorityRoles.indexOf(y.ContactRoleName);

				if (indexX !== -1 || indexY !== -1) {
					indexX = indexX == -1 ? Number.MAX_SAFE_INTEGER : indexX;
					indexY = indexY == -1 ? Number.MAX_SAFE_INTEGER : indexY;
					return indexX - indexY;
				}

				return x.ContactRoleName.toLowerCase().localeCompare(y.ContactRoleName.toLowerCase());
			},
			search: (x: ContactRole[], y: string) => {
				return x.filter(item => item.ContactRoleName.toLowerCase().includes(y.toLowerCase()));
			},
			data: {
				allAvailableItems: [],
				selectedItems: []
			}
		};

		this.loadingService.waitObservableWithSpinner(
			this.roleService.GetContactRoles())
			.subscribe(rolesResponse => {
				if (this.selectedUserService.IsNew) {
					this.setUpGroupsSelectorData([], rolesResponse.data);
				} else {
					this.loadingService.waitObservableWithSpinner(
						this.roleService.GetContactRolesByUser(this.contactId))
						.subscribe(rolesByUserResponse => {
							this.isSelective = rolesByUserResponse.data
								.some(x => x.ContactRoleName.toUpperCase() === ROLES.SELECTIVE);
							const selectedItems = rolesByUserResponse.data;
							const allAvailableItems = rolesResponse.data.filter(x => {
								return !selectedItems.some(y => y.ContactRoleId === x.ContactRoleId);
							});
							this.setUpGroupsSelectorData(selectedItems, allAvailableItems);

							this.setUpTabsSelector();
						});
				}
			});
	}

	private setUpGroupsSelectorData(selectedItems: ContactRole[], availableItems: ContactRole[]): void {
		this.groupsSelectorOptions = {
			...this.groupsSelectorOptions,
			data: this.treeMultiselectService.getHomologatedData({
				allAvailableItems: availableItems,
				selectedItems
			}, {
				readLabel: (role: ContactRole) => role.ContactRoleName
			})
		};

		this.selectedGroups = selectedItems;
		this.checkIfTabsSelectorShouldBeShown();
	}

	private setUpTabsSelector(): void {
		this.tabsSelectorOptions = {
			allowSelectAll: true,
			config: {
				readChecked: (tab: CroAccessInfo) => tab.ISAccessibleFlag,
				readLabel: (tab: CroAccessInfo) => tab.TABName,
			},
			data: [],
			title: "Select Tabs User can Access"
		};
		this.loadingService.waitObservableWithSpinner(
			this.roleService.GetCroAccessPrivileges(this.contactId))
			.subscribe(response => {
				const tabsData = response.data;

				this.checkIfSelectAllTabs(tabsData) &&
					tabsData.forEach(tab => tab.ISAccessibleFlag = true);

				this.tabsSelectorOptions = {
					...this.tabsSelectorOptions,
					data: tabsData
				};
			});
	}
}
