export class LoggedUser {

	private _canManageUsers: boolean;
	private _isAdmin: boolean;
	private _userId: number;

	constructor(canManageUsers: boolean, isAdmin: boolean, userId: number) {
		this._canManageUsers = canManageUsers;
		this._isAdmin = isAdmin;
		this._userId = userId;
	}

	public get CanManageUsers(): boolean {
		return this._canManageUsers;
	}

	public get IsAdmin(): boolean {
		return this._isAdmin;
	}

	public get UserId(): number {
		return this._userId;
	}
}
