import { Injectable } from '@angular/core';
import { isNil } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  dispatchEvent(eventType: string) {
    // Dispatches an event using the old way for compatibility with IE11
    const resizeEvent = window.document.createEvent('UIEvents');
    resizeEvent.initEvent(eventType, true, false);
    window.dispatchEvent(resizeEvent);
  }

  toBoolean = (value: any) => !isNil(value) && value.toLowerCase() === 'true';


}
