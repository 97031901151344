import {Component} from '@angular/core';
import { LoadingService } from '../../services/loading.service'; 

@Component(
  {
    selector: 'gp-ui-loading-component',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.css']
  })
export class LoadingComponent {
  constructor(private loadingService: LoadingService) {}

  get isLoading(): boolean { return this.loadingService.loading; }
}
