import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { MultiselectComponent } from 'projects/shared/src/app/components/multiselect-add-remove/multiselect/multiselect.component';
import { IMultiselectUIOptions } from 'projects/shared/src/app/models/multiselect-add-remove-component-options/multiselect-options';
import { VisitNode } from 'projects/shared/src/app/models/visit/visit-node';
import { LoadingService } from 'projects/shared/src/app/common/loading/services/loading.service';
import { SharedDataService } from 'projects/shared/src/app/common/general/services/shared-data.service';
import { ClassicService } from 'projects/classic/src/app/services/classic.service';
import { okModal } from 'projects/shared/src/app/common/confirm/decorators/confirm.decorator';

import { SelectedStudyLevelService } from '../../services/study-level/selected-study-level.service';
import { VisitScheduleService } from '../../services/visit-schedule.service';
import { DeleteVisitsService } from '../../services/delete-visits.service';

@Component({
  selector: 'gp-ui-app-add-visits-modal',
  templateUrl: './add-visits-modal.component.html',
  styleUrls: ['./add-visits-modal.component.css']
})
export class AddVisitsModalComponent<T extends VisitNode> implements OnInit {

  @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MultiselectComponent, { static: false }) multiselect: MultiselectComponent<any>;

  countryShortId: string;
  subStudyId: number;
  visitSelectorOptions: IMultiselectUIOptions<VisitNode>;
  selectedVisits: T[] = [];
  originalSelectedVisits: T[] = [];
  originalAvailableVisits: T[] = [];
  columDef = [
    {
      name: 'VisitShortName',
      title: 'Short Name',
      dataType: 'string',
      styles: { 'width': '40%', 'min-width': '40%', 'font-weight': 'normal' }
    },
    {
      name: 'VisitDescription',
      title: 'Visit Description',
      dataType: 'string',
      styles: { 'width': '60%', 'min-width': '60%', 'font-weight': 'normal' }
    }
  ]
  originalData: VisitNode[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    private loadingService: LoadingService,
    private visitScheduleService: VisitScheduleService,
    private dialogRef: MatDialogRef<AddVisitsModalComponent<T>>,
    private selectedStudyLevelService: SelectedStudyLevelService,
    public sharedDataService: SharedDataService,
    private classicService: ClassicService,
    private deleteVisitsService: DeleteVisitsService,
  ) { }

  ngOnInit(): void {
    this.countryShortId = this.selectedStudyLevelService.selectedStudyLevel.Country
    this.subStudyId = this.selectedStudyLevelService.selectedStudyLevel.SubStudy
    this.getClassicRoot();
    this.getVisits();
  }

  onAddCompanyVisitsClick(): void {
    const redirectTo = this.sharedDataService.ClassicURL + 'sponsor/visit.aspx?from=VisitSchedule';
    window.open(redirectTo, '_blank');
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(): void {
    let deletedVisits: T[] = [];
    let addedVisits: T[] = [];
    let order = 1;
    this.selectedVisits = this.selectedVisits.map(c => ({ ...c, Order: order++ }));
    addedVisits = this.selectedVisits.filter(x => { return !this.originalSelectedVisits.some(y => y.VisitId === x.VisitId) })
      .map(c => ({ ...c, SubStudyId: this.subStudyId, CountryShortId: this.countryShortId }));
    deletedVisits = this.originalSelectedVisits.filter(x => { return !this.selectedVisits.some(y => y.VisitId === x.VisitId) });
    if (addedVisits.length > 0 || deletedVisits.length > 0) {

      const saveVisits = () => {
        this.goToSaveVisits(addedVisits, deletedVisits);
      };

      if (deletedVisits.length > 0) {
        if (this.dialogData.shouldConfirmDNV) {
          this.deleteVisitsService.confirmDNVAndDelete();
        } else {
          this.deleteVisitsService.confirmDeletion(false);
        }

        const deleteSubscription = this.deleteVisitsService.onDeleteConfirmation$
          .subscribe(deleteConfirmation => {
            if (deleteConfirmation.deletionConfirmed) {
              saveVisits();
            }
            deleteSubscription.unsubscribe();
          });

      } else {
        saveVisits();
      }
    }
  }

  onDataOutputChangeEvent(items: T[]): void {
    this.selectedVisits = items;
  }

  setUpVisitSelectorData(selectedItems: VisitNode[], availableItems: VisitNode[]): void {
    this.visitSelectorOptions = {
      ...this.visitSelectorOptions,
      data: {
        allAvailableItems: availableItems,
        selectedItems
      }
    }
  }

  private getClassicRoot(): void {
    this.classicService.getClassicUrl().subscribe((url: string) => {
      this.sharedDataService.ClassicURL = url;
    });
  }

  private getVisits(): void {
    this.visitSelectorOptions = {
      availableDisclaimerText: "",
      selectedDisclaimerText: "",
      compare: (x: VisitNode, y: VisitNode) => {
        let indexX = this.originalData.indexOf(x);
        let indexY = this.originalData.indexOf(y);

        if (indexX !== -1 || indexY !== -1) {
          indexX = indexX == -1 ? Number.MAX_SAFE_INTEGER : indexX;
          indexY = indexY == -1 ? Number.MAX_SAFE_INTEGER : indexY;
        }

        return indexX - indexY;
      },
      search: (visitFilter: VisitNode[], searchText: string) => {
        const normalizedSearch = searchText.toLowerCase();
        return visitFilter.filter(item =>
          this.columDef.map(columns => String(item[columns.name]).toLowerCase())
            .some(attribute => attribute.includes(normalizedSearch))
        );
      },
      data: {
        allAvailableItems: [],
        selectedItems: []
      },
      columDef: this.columDef,
    };

    this.loadingService.waitObservableWithSpinner(
      this.visitScheduleService.getAllVisits())
      .subscribe((allVisitsResponse: any) => {
        this.loadingService.waitObservableWithSpinner(
          this.visitScheduleService.getStudyVisits())
          .subscribe((selectedVisitsResponse: any) => {
            this.originalData = allVisitsResponse.data;
            this.selectedVisits = selectedVisitsResponse.data;
            this.originalSelectedVisits = this.selectedVisits;
            let allAvailableVisits = allVisitsResponse.data.filter(x => {
              return !this.selectedVisits.some(y => y.VisitId === x.VisitId)
            });
            this.setUpVisitSelectorData(this.selectedVisits, allAvailableVisits);
          });
      });
  }

  private goToSaveVisits(addedVisits: T[], deletedVisits: T[]): void {
    this.loadingService.waitObservableWithSpinner(
      this.visitScheduleService.saveStudyVisits(addedVisits, deletedVisits, this.subStudyId, this.countryShortId))
      .subscribe(
        (response) => {
          if (response.data && response.data.length > 0) {
            this.showAlert(response.data);
          } else {
            this.saveEvent.emit();
            this.dialogRef.close();
          }
        }
      );
  }

  @okModal({
    title: '',
    message: (alertMessage: string): string => alertMessage
  })
  private showAlert(alertMessage: string): void {
  }
}
