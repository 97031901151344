import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { LoadingService } from 'projects/shared/src/app/common/loading/services/loading.service';

import { DEFAULT_USER_OPTIONS_FORM } from 'projects/shared/src/app/constants/user/user-options-constant';
import { UserModalTab } from 'projects/shared/src/app/constants/user/user-modal-tabs';
import { FormOptions, UserOptions } from 'projects/shared/src/app/models/user/user/user-options.model';
import { CountryService } from 'projects/shared/src/app/services/user/country/country.service';
import { SelectedUserService } from 'projects/shared/src/app/services/user/user/selected-user.service';
import { UserService } from 'projects/shared/src/app/services/user/user/user.service';

@Component({
  selector: 'app-options-tab',
  templateUrl: './options-tab.component.html',
  styleUrls: ['./options-tab.component.css']
})
export class OptionsTabComponent implements OnInit {
  TAB = UserModalTab.Options;

  isEditMode: boolean = false;
  formOptions: FormOptions;
  optionsForm: FormGroup;
  sponsorName: string;
  userOptions: UserOptions;

  constructor(
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private countryService: CountryService,
    private selectedUserService: SelectedUserService,
    private userService: UserService) {

    this.buildOptionsForm();
    this.toggleEditMode(false);
    this.formOptions = DEFAULT_USER_OPTIONS_FORM;
  }

  ngOnInit(): void {
    this.setUpUserOptions();
  }

  onCancel(): void {
    this.fillOptionsForm();
    this.toggleEditMode(false);
  }

  onSave(): void {
    if (this.optionsForm.valid) {
      const newOptions = this.optionsForm.value;
      const newUserOptions: any = {
        ContactId: this.selectedUserService.UserId,
        DefaultEmployeeSecurityLevelId: newOptions.employeeSecurityLevel,
        USEStandardOverheadBenchmarkFlag: newOptions.isStandardOverheadBenchmarks,
        OverheadId: newOptions.overhead,
        DecimalDisplayFORTooltips: this.userOptions.DecimalDisplayFORTooltips,
        DecimalDisplay: newOptions.decimals,
        PlanningCurrencyShortId: newOptions.currency,
        DefaultSUBStudyId: newOptions.subStudy,
        DefaultCountryShortId: newOptions.country,
        AddingProcedureId: newOptions.whenAddingProcedures,
        DisplayProcedureDescriptionId: newOptions.procedureDescription,
        BenchmarkDefaultSettingId: newOptions.benchmark,
        LookUPBenchmarkPriceId: newOptions.lookupBenchmark,
        DefaultCCFORStandardEmail: newOptions.defaultCCStandardEmails,
        DefaultCCFORFinalAgreement: newOptions.defaultCCFinalAgreement
      };

      this.saveUserOptions(newUserOptions);
    }
  }

  toggleEditMode(enabled: boolean): void {
    this.isEditMode = enabled;
    enabled ? this.optionsForm.enable() : this.optionsForm.disable();
  }

  private buildOptionsForm(): void {
    this.optionsForm = this.formBuilder.group({
      lookupBenchmark: [''],
      benchmark: [''],
      procedureDescription: [''],
      whenAddingProcedures: [''],
      country: [''],
      subStudy: [''],
      currency: [''],
      decimals: [''],
      overhead: [''],
      isStandardOverheadBenchmarks: [false],
      employeeSecurityLevel: [''],
      defaultCCStandardEmails: [''],
      defaultCCFinalAgreement: [''],
    });
  }

  private fillOptionsForm(): void {
    const selectedBenchmark = this.formOptions.benchmarksList
      .find(x => x.Formula.toUpperCase() === this.userOptions.Formula.toUpperCase());
    this.optionsForm.setValue({
      lookupBenchmark: this.userOptions.LookUPBenchmarkPriceId,
      benchmark: (selectedBenchmark) ? selectedBenchmark.BenchmarkDefaultSettingId : 0,
      procedureDescription: this.userOptions.DisplayProcedureDescriptionId,
      whenAddingProcedures: this.userOptions.AddingProcedureId,
      country: this.userOptions.DefaultCountryShortId,
      subStudy: this.userOptions.DefaultSUBStudyId,
      currency: this.userOptions.PlanningCurrencyShortId,
      decimals: this.userOptions.DecimalDisplay,
      overhead: this.userOptions.OverheadId,
      isStandardOverheadBenchmarks: this.userOptions.USEStandardOverheadBenchmarkFlag,
      employeeSecurityLevel: this.userOptions.DefaultEmployeeSecurityLevelId,
      defaultCCStandardEmails: this.userOptions.DefaultCCFORStandardEmail,
      defaultCCFinalAgreement: this.userOptions.DefaultCCFORFinalAgreement,
    });
  }

  private saveUserOptions(newUserOptions: any): void {
    this.loadingService.waitObservableWithSpinner(
      this.userService.SaveUserOptions(newUserOptions))
      .subscribe(response => {
        this.userOptions = {
          ...this.userOptions,
          ...newUserOptions
        };
        this.toggleEditMode(false);
      });
  }

  private setUpCountries(): void {
    this.countryService.getAllCountries()
      .subscribe((response: any) => {
        this.formOptions.countriesList = response.Countries;
      });
  }

  private setUpUserOptions(): void {
    this.setUpCountries();
    this.loadingService.waitObservableWithSpinner(
      this.userService.GetUserOptionsById(this.selectedUserService.UserId))
      .subscribe(response => {
        const options = response.data;

        this.formOptions.lookupBenchmarksList = options.LookUpBenchmarkPrice;
        this.formOptions.benchmarksList = options.BenchmarkDefaultSettings;
        this.formOptions.subStudiesList = options.ALLSubStudyInfo;
        this.formOptions.currenciesList = options.Currencies;
        this.formOptions.overheadList = options.OverHead;
        this.sponsorName = options.SponsorName;

        this.userOptions = options.SponsorContactOption;

        this.fillOptionsForm();
      });
  }

}