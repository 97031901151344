import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable } from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import { HttpErrorHandlerService } from './http-error-handler.service';

import { AccountService } from '../../security/services/account.service'; 
import { AntiForgeryToken } from '../../security/models';

interface HttpOptions {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  observe?: 'body';
  params?: HttpParams | {
      [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(private httpClient: HttpClient, private httpErrorService: HttpErrorHandlerService, private accountService: AccountService) {}

  get = <T>({url, options}: {url: string, options?: HttpOptions}): Observable<T> =>
    this.httpClient.get<T>(url, options).pipe(catchError(this.httpErrorService.handleError))

  post = <T>({url, body, options}: {url: string, body: any, options?: HttpOptions}): Observable<T> =>
    this.httpClient.post<T>(url, body, options).pipe(catchError(this.httpErrorService.handleError))

  safeGet = <T>({url, options}: {url: string, options?: HttpOptions}): Observable<T> => {
    return this.accountService.getAntiForgeryToken()
      .pipe(mergeMap(token => {
        const httpOptions = this.getOptionsWithAntiforgeryToken(token, options);
        return this.get<T>({url, options: httpOptions});
      }));
  }

  safePost = <T>({url, body, options}: {url: string, body: any, options?: HttpOptions}): Observable<T> => {
    return this.accountService.getAntiForgeryToken()
      .pipe(mergeMap(token => {
        const httpOptions = this.getOptionsWithAntiforgeryToken(token, options);
        return this.post<T>({url, body, options: httpOptions});
      }));
  }

  private getOptionsWithAntiforgeryToken(token, options?: HttpOptions) {
    const optionsWithToken = options || {};
    if (!optionsWithToken.headers) {
      optionsWithToken.headers = new HttpHeaders();
    }
    const {headers} = optionsWithToken;
    if (headers instanceof HttpHeaders) {
      optionsWithToken.headers = headers.set('X_CSRF_TOKEN', new AntiForgeryToken(token).toString());
    }
    return optionsWithToken;
  }
}
