import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SelectedUserService {

    private _isActive: boolean = true;
    private _isAdmin: boolean = false;
    private _isNew: boolean = true;
    private _userId: number;

    private savedUserSubject = new Subject<any>();
    private selectedUserSubject = new Subject<any>();

    public onUserSaved = this.savedUserSubject.asObservable();
    public onUserSelected = this.selectedUserSubject.asObservable();

    get IsActive(): boolean {
        return this._isActive;
    }

    get IsAdmin(): boolean {
        return this._isAdmin;
    }

    get IsNew(): boolean {
        return this._isNew;
    }

    get UserId(): number {
        return this._userId;
    }

    public castSavedUser(userId: number): void {
        this.savedUserSubject.next({ userId });
    }

    public castSelectedUser(userId: number, isActive: boolean = true, isAdmin: boolean = false): void {
        this._isActive = isActive;
        this._isAdmin = isAdmin;
        this._isNew = false;
        this._userId = userId;

        this.selectedUserSubject.next({ userId });
    }

    public clearSelection(): void {
        this._isActive = true;
        this._isAdmin = false;
        this._isNew = true;
        this._userId = undefined;
    }
}