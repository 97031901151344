import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {CloseSesionWithoutRedirect} from '../../services/close-sesion-without-redirect/close-sesion-without-redirect.service';
import { InactivityInspectorService } from '../../services/inactivity-inspector/inactivity-inspector.service';
@Component(
  {
    selector: 'gp-ui-timeout-modal-component',
    templateUrl: './timeout-modal.component.html',
    styleUrls: ['./timeout-modal.component.css']
  })
export class TimeoutModalComponent implements OnInit, OnDestroy {

  private intervalSeconds = 120;
  private seconds = 0;
  private timer;

  public timeout = false;
  public secondsToShow: string;
  public minutesToShow: string;


  constructor(public dialogRef: MatDialogRef<TimeoutModalComponent>,
      public session : CloseSesionWithoutRedirect,
      private inactivityInspector:InactivityInspectorService,
      @Inject(MAT_DIALOG_DATA) public data: any) {
        dialogRef.disableClose=true;
      }

  ngOnInit(): void {
    this.updateTime();
    if (this.seconds<=0){
      this.timeout = true;
      this.logOff();
    }else{
      this.startTimer();
    }
  }
updateTime(){
  this.seconds = this.intervalSeconds - Math.floor((Date.now()-this.data.timeref)/1000);
  this.secondsToShow = this.pad(this.seconds % 60);
  this.minutesToShow = this.pad(Math.floor((this.seconds % 3600) / 60));
}
  public ok() {
    this.closeModal(this.timeout);
  }

  private startTimer() {
    this.timer = setInterval(() => {
      this.updateTime();
      if (this.seconds <= 0) {
        clearInterval(this.timer);
        this.timeout = true;
        this.logOff();
      }
    }, 500);
  }

  private logOff() {
    this.inactivityInspector.stopListening();
    this.session.CloseSession().subscribe();
  }

  private closeModal(timedOut:boolean) {
    clearInterval(this.timer);
    this.dialogRef.close(timedOut);
  }

  private pad(n: number): string {
    return (n < 10) ? `0${n}` : n.toString();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }
}
