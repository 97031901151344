import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountriesSharedService } from '../../../countries/services/countries-service.service';


@Injectable({
	providedIn: 'root'
})
export class CountryService {

	private countries: any;

	constructor(private countriesSharedService: CountriesSharedService) { }

	getAllCountries(): Observable<any> {
		if (this.countries) {
			return of(this.countries);
		} else {
			return this.countriesSharedService.getAllCountries()
				.pipe(
					map((response: any) => {
						this.countries = response;
						return response;
					})
				);
		}
	}
}