import {Injectable} from '@angular/core';

import {TimeoutModalComponent} from '../../components/timeout-modal-component/timeout-modal.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable(
  {
    providedIn: 'root'
  })
export class TimeoutModalService {
  private bc: BroadcastChannel;
  private modalRef: MatDialogRef<TimeoutModalComponent>;

  constructor(private modal: MatDialog) {
    if (window.location == window.parent.location) {
      this.bc = new BroadcastChannel('timer-channel');
      this.bc.onmessage = (message) => message.data == 'closeModal' ? this.modalRef.close() : this.redirectToLogin();
    }
  }

    show(timeref:number): void {
      if(!!this.modalRef){
        return;
      }
      const modalOptions: MatDialogConfig= {
        width: '30%',
        height: '15%',
        data: {timeref: timeref }
      };
      this.modalRef= this.modal.open(TimeoutModalComponent, modalOptions);
      this.modalRef.afterClosed().subscribe((timedOut)=> {
        if(timedOut){
          this.bc.postMessage('redirectToLogin');
          this.redirectToLogin();
        }
        else{
          this.bc.postMessage('closeModal');
        }

        this.modalRef=undefined;

      });
    }

    private redirectToLogin = () => window.top.location.href = './login';

}
