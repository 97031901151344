export enum EventType {
  AddToGrantTotalChanged,
  ChangeCostCell,
  ChangePlannedPatients,
  CopyStudyProcessEnded,
  CountriesListUpdated,
  CountryDetailsBudgetColumnSaved,
  CountryDetailsInitialOfferSaved,
  CountryDetailsSaved,
  CountryDetailsSavedFromModal,
  CountryDetailsUpperLimitSaved,
  CppCodeDuplicated,
  DeletedCountriesListUpdated,
  DeleteSubstudiesClicked,
  DropdownChanged,
  GetCountriesDone,
  GetStudyDetailsDone,
  IndicationsSaved,
  IsShowUndoSocStateUpdated,
  LineItemsEndLoading,
  LineItemsStartLoading,
  OneSectionBeginLoading,
  PullUnselectedSubstudiesDone,
  RefreshHeaders,
  RefreshView,
  ReorderClicked,
  SaveCountries,
  SaveCppCopy,
  SessionStudyCode,
  ShowIncompleteButtonUpdated,
  StudyDetailsSaved,
  SubmitBudgetColumn,
  SubstudiesInitialized,
  SubstudiesSaved,
  SubstudiesSetRowData,
  SubstudyCopyClicked,
  UnhandledException,
  UnsavedChanges,
  UpdateIndications,
  UpdateStudyDetailsInfo,
  ValidateBeforeunload,
  UnsavedChangesSectionStudyDetails,
  PopoverDisplayed,
  IsVisitScheduleInEditMode
}

export class Event {
  constructor(public name: EventType, public value?: any) {}
}
