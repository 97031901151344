import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorHandlerService } from './http-error-handler.service';
import { catchError } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Injectable(
    {
        providedIn: 'root'
    })
export class ProgressBarService {

    constructor(private http: HttpClient,
      private httpErrorService: HttpErrorHandlerService) { }

    public onProgress = new Subject();

    getProgressIntervalInSeconds() {
        const url = './KeycloakHelper/GetProgressIntervalInSeconds';
        return this.http.get(url)
            .pipe(catchError(this.httpErrorService.handleError));
    }

    pingSession() {
        const url = './KeycloakHelper/PingSession';
        return this.http.get(url)
            .pipe(catchError(this.httpErrorService.handleError));
    }

    onProgressFunction: Function = () => {
        this.onProgress.next();
        this.pingSession().subscribe(
          () => {
            // This service activates the keycloak token refresh process thread
          })
      }

    getModalTimeOut(){
        const url = './KeycloakHelper/GetModalTimeOut';
        return this.http.get(url)
            .pipe(catchError(this.httpErrorService.handleError));
    }
}
