import { SimpleChanges } from "@angular/core";

/**
 * Verify if there is a change in a especific input.
 * @param changes changes event.
 * @param inputName name of the input.
 * @returns true if the change is from that input
 */
export const changeVerify = ( changes: SimpleChanges,  inputName: string): boolean => {

    return changes.hasOwnProperty(inputName) && changes[inputName].hasOwnProperty('currentValue');
}

/**
 * Transforms null or undefined to zero
 * @param entryValue value to send to the input in editing mode
 * @returns the number or cero if is null or undefined.
 */
export const evalValueForEdit = (entryValue: any): number => {
    return (entryValue === null || entryValue === undefined || entryValue === '')? 0 : entryValue;
}

/**
 * Transforms 'false'/'true' to false/true
 * @param entryValue value to transform
 * @returns true or false.
 */
export const transformStringToBoolean = (entryValue: string): boolean => {
    return (entryValue.trim().toLowerCase() ==='true');
}

/**
 * Verify if there is a change in a especific input and is not the first change
 * @param changes changes event.
 * @param inputName name of the input.
 * @returns true if the change is from that input and is not the first change
 */
export const changeVerifyNoFirstChange = ( changes: SimpleChanges,  inputName: string): boolean => {

    return changes.hasOwnProperty(inputName) 
        && changes[inputName].hasOwnProperty('currentValue')
        && changes[inputName].hasOwnProperty('firstChange')
        && !changes[inputName].firstChange;
}