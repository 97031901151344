import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { SelectedUserService } from '../../../services/user/user/selected-user.service';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.css']
})
export class UserModalComponent implements OnInit, OnDestroy {

  @Output() cancelModal = new EventEmitter();
  @Input() windowTitle: string;
  @Input() isCalledFromOptions: boolean = false;
  @Input() isAdminUser: boolean;
  @Input() isNewUser: boolean;
  @Input() contactId: number;

  private subscriptions = new Subscription();

  constructor(private selectedUserService: SelectedUserService) { }

  ngOnInit(): void {
    if (!this.isNewUser && this.contactId) {
      this.selectedUserService.castSelectedUser(this.contactId, true, this.isAdminUser);
    } else {
      this.isAdminUser = this.selectedUserService.IsAdmin;
      this.isNewUser = this.selectedUserService.IsNew;
    }
    this.subscriptions.add(
      this.selectedUserService.onUserSelected
        .subscribe((userId) => {
          this.isNewUser = false;
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  closeModal(): void {
    this.cancelModal.emit();
    window.top && window.top.postMessage({ event: "closeUserModal" }, '*');
  }

  getSelectedIndexTab(): number {
    return this.isCalledFromOptions == true ? 1 : 0;
  }
}
