import { Injectable } from '@angular/core';

import { HttpService } from '../../common/general/services/http.service'; 

@Injectable({
  providedIn: 'root'
})
export class HelpSiteService {

  constructor(private httpService: HttpService) { }


  getHelpSiteURL() {
    const url = './HelpSite/GetHelpSiteURL';
    return this.httpService.safeGet({url});
  }
}
