import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appPositiveNumber]'
})
export class PositiveNumberDirective {

  @Input() allowDecimal: boolean = false;

  constructor(private el: ElementRef) { }

  @HostListener('paste', ['$event']) blockPaste(event: ClipboardEvent) {
    event.preventDefault();
    let pasteData = event.clipboardData.getData('text/plain');
    const value = this.formatValue(pasteData);
    document.execCommand('insertHTML', false, value);
  }

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    inputElement.value = this.formatValue(inputElement.value);
  }

  formatValue(value: string): string {
    let positiveValue = '';
    if (this.allowDecimal) {
      positiveValue = value.replace(/[^0-9.]/g, '');
      const matches = positiveValue.match(/\./g);
      if (matches && matches.length > 1) {
        positiveValue = positiveValue.slice(0, positiveValue.lastIndexOf('.'));
      } else if (positiveValue.startsWith('.')) {
        positiveValue = positiveValue.slice(1);
      }
    } else {
      positiveValue = value.replace(/[^0-9]/g, '');
    }

    return positiveValue;
  }
}
