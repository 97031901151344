import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs/internal/Subscription';
import { filter, map } from 'rxjs/operators';

import { Event, EventType } from '../models/events';

@Injectable({
   providedIn: 'root'
})
export class EventBusService {
   private subject: Subject<any> = new Subject<any>();

   on(event: EventType, action: any): Subscription {
      return this.subject.pipe(
         filter((e: Event) => e.name === event),
         map((e: Event) => e.value)
      ).subscribe(action);
   }

   emit(event: Event) {
      this.subject.next(event);
   }
}
