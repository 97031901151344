import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { CopyStudyParam } from '../../study/models/copy-study-param';
import { HttpErrorHandlerService } from 'projects/shared/src/app/common/general/services/http-error-handler.service';
import { Observable } from 'rxjs/internal/Observable';
import { StudySize } from 'projects/shared/src/app/study/models/study-size.model';
import { HttpService } from '../../common/general/services/http.service';
import { CPP_TOOL_STUDY, CPV_TOOL_STUDY } from '../../constants/StudyTypes.constant';
import { StudyReference } from '../../study/models/study-reference';
import { Router } from '@angular/router';
import { StudyDetailsService } from 'projects/study-details/src/app/services/study-details-service/study-details.service';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class StudyService {
  constructor(
    private studyDetailsService: StudyDetailsService,
    private http: HttpClient,
    private httpErrorService: HttpErrorHandlerService,
    private httpService: HttpService,
    private router: Router) { }

  copyStudy(param: CopyStudyParam) {
    const url: string = './Study/CopyStudy';

    return this.http.post(url, param).pipe(catchError(this.httpErrorService.handleError));
  }

  checkStudyCode(param: CopyStudyParam, showBmarcref: boolean, showRevision: boolean) {
    const url: string = './Study/CheckStudyCode';
    const data = {
      param: param,
      showBMARCREF: showBmarcref,
      showRevision: showRevision
    };
    return this.http.post(url, data).pipe(catchError(this.httpErrorService.handleError));
  }

  getStudySize(): Observable<StudySize> {
    const url: string = './LineItemsSessionSafe/GetStudySize';
    return this.httpService.safeGet<StudySize>({ url });
  }

  navigateToStudy(study: StudyReference) {
    if (study.studyTypeId === CPV_TOOL_STUDY) {
      this.router.navigate(['Classic/CostPerVisitTool'], { queryParams: { forecastId: study.studyId, menuTitle: 'Cost per Visit Planning Tool' } });
    } else if (study.studyTypeId === CPP_TOOL_STUDY) {
      this.router.navigate(['CppTool', study.studyId]);
    } else if (study.investigatorId) {
      this.router.navigate(['Classic/SiteBudgets'], { queryParams: { studyId: study.studyId, contactId: study.investigatorId } });
    } else {
      this.studyDetailsService.InitializeSessionData(study.studyId).subscribe(() => {
        // The reload component is used to reload the page when the user is copying studies.
        this.router.navigate(['StudyDetails/Reload']);
      });
    }
  }
}
