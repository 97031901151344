import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoadingService } from 'projects/shared/src/app/common/loading/services/loading.service';
import { StudyLevelDataFetchService } from './study-level-data-fetch.service'
import { CountriesResponse } from '../../models/study-level/country.model';
import { CurrencyType } from '../../models/study-level/currency-type.enum';
import { CurrenciesResponse } from '../../models/study-level/currency.model';
import { RegionsResponse } from '../../models/study-level/region.model';
import { SelectedStudyLevel } from '../../models/study-level/selected-study-level.model';
import { StudyLevelChangedResponse } from '../../models/study-level/study-level-changed-response.model';
import { StudyLevelData } from '../../models/study-level/study-level-data.model';
import { SubStudiesResponse } from '../../models/study-level/sub-study.model';

@Injectable({
    providedIn: 'root'
})
export class StudyLevelDataDeliveryService {

    private _serviceInitialized: boolean = false;
    private _initialStudyLevelChange$: Observable<SelectedStudyLevel>;
    private _initialStudyLevelSubject: Subject<SelectedStudyLevel>;
    private _studyLevelData: StudyLevelData;
    private _studyLevelDataChange$: Observable<StudyLevelData>;
    private _studyLevelDataSubject: BehaviorSubject<StudyLevelData>;

    get studyLevelDataChange$(): Observable<StudyLevelData> {
        return this._studyLevelDataChange$;
    }

    get initialStudyLevelChange$(): Observable<SelectedStudyLevel> {
        return this._initialStudyLevelChange$;
    }

    constructor(private loadingService: LoadingService,
        private fetchService: StudyLevelDataFetchService,
    ) {

        this._studyLevelData = {
            countries: [],
            currencies: [],
            regions: [],
            subStudies: []
        };

        this._initialStudyLevelSubject = new Subject<SelectedStudyLevel>();
        this._initialStudyLevelChange$ = this._initialStudyLevelSubject.asObservable();

        this._studyLevelDataSubject = new BehaviorSubject<StudyLevelData>(this._studyLevelData);
        this._studyLevelDataChange$ = this._studyLevelDataSubject.asObservable();
    }

    public endService(): void {
        this._serviceInitialized = false;
    }

    public setUpStudyLevelData(): void {

        if (this._serviceInitialized) {
            return;
        }

        this._serviceInitialized = true;

        this.loadingService.waitObservableWithSpinner(
            combineLatest([
                this.fetchService.getCountries(),
                this.fetchService.getCurrencies(),
                this.fetchService.getRegions(),
                this.fetchService.getSubStudies(),
            ])).subscribe(([countriesData, currenciesData, regionsData, subStudiesData]) => {
                this._studyLevelData.countries = countriesData.Countries;
                this._studyLevelData.currencies = currenciesData.Currencies;
                this._studyLevelData.regions = regionsData.Regions;
                this._studyLevelData.subStudies = subStudiesData.Substudies;

                this.emitStudyLevelData();

                this._initialStudyLevelSubject.next(
                    this.getSelectedStudyLevelFromResponse(
                        countriesData,
                        currenciesData,
                        regionsData,
                        subStudiesData)
                );
            });
    }

    public switchCountry(newCountryCode: string): Observable<SelectedStudyLevel> {
        return this.fetchService.switchCountry(newCountryCode)
            .pipe(
                map(response => {
                    this._studyLevelData.currencies = response.CurrenciesData.Currencies;
                    this._studyLevelData.regions = response.RegionsData.Regions;
                    this._studyLevelData.subStudies = response.SubStudiesData.Substudies;

                    this.emitStudyLevelData();

                    return this.getSelectedStudyLevelFromResponse(
                        response.CountriesData,
                        response.CurrenciesData,
                        response.RegionsData,
                        response.SubStudiesData);
                })
            );
    }

    public switchCurrency(newCurrencyType: CurrencyType, newCurrencyCode: string): Observable<StudyLevelChangedResponse> {
        return this.fetchService.switchCurrency(newCurrencyType, newCurrencyCode);
    }

    public switchRegion(newCountryCode: string, newRegionCode: string): Observable<StudyLevelChangedResponse> {
        return this.fetchService.switchRegion(newCountryCode, newRegionCode);
    }

    public switchSubstudy(subStudyId: number): Observable<StudyLevelChangedResponse> {
        return this.fetchService.switchSubStudy(subStudyId);
    }

    private emitStudyLevelData(): void {
        this._studyLevelDataSubject.next(this._studyLevelData);
    }

    private getSelectedStudyLevelFromResponse(countriesData: CountriesResponse, currenciesData: CurrenciesResponse,
        regionsData: RegionsResponse, subStudiesData: SubStudiesResponse): SelectedStudyLevel {

        let selectedCountry: string,
            selectedCurrency: number,
            selectedCurrencyCode: string,
            selectedRegion: string,
            selectedSubStudy: number;

        if (countriesData.Countries.length > 0) {
            selectedCountry = countriesData.Countries[countriesData.Selected].CountryShortId;
        }
        if (currenciesData.Currencies.length > 0) {
            selectedCurrency = currenciesData.Currencies[currenciesData.Selected].CurrencyType;
            selectedCurrencyCode = currenciesData.Currencies[currenciesData.Selected].CurrencyCode;
        }
        if (regionsData.Regions.length > 0) {
            selectedRegion = regionsData.Regions[regionsData.Selected].CountryShortId;
        }
        if (subStudiesData.Substudies.length > 0) {
            selectedSubStudy = subStudiesData.Substudies[subStudiesData.Selected].SUBStudyId;
        }

        return {
            Country: selectedCountry,
            Currency: selectedCurrency,
            CurrencyCode: selectedCurrencyCode,
            DataAvailable: true,
            Region: selectedRegion,
            SubStudy: selectedSubStudy
        };
    }
}