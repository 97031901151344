import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

import { EventBusService } from 'projects/shared/src/app/common/general/services/event-bus.service'; 
import { Event, EventType } from 'projects/shared/src/app/common/general/models/events';

@Component(
  {
    selector: 'gp-ui-copy-substudy-cell',
    templateUrl: './selected-grid-copy-renderer.component.html'
  })
  export class SelectedGridCopyRendererComponent implements ICellRendererAngularComp {
    private rendererParams: ICellRendererParams = undefined;

    constructor(private eventBus: EventBusService) {}

    agInit(params: ICellRendererParams): void {
      this.rendererParams = params;
    }

    refresh(params: ICellRendererParams): boolean {
      this.rendererParams = params;

      return true;
    }

    isCloned(): boolean {
      return this.rendererParams.data.Cloned;
    }

    handleCopyClick() {
      this.eventBus.emit(new Event(EventType.SubstudyCopyClicked, this.rendererParams.data));
    }
  }
  