import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../common/general/services/http.service'; 

@Injectable({
  providedIn: 'root'
})
export class CountriesSharedService {
  constructor(private httpService: HttpService) { }

  getCountries(subStudyId?: number): Observable<any[]> {
    const url: string = `./CountriesAPI/GetCountries${subStudyId
      ? `?subStudyId=${subStudyId}` : ''}`;
    return this.httpService.safeGet<any[]>({url});
  }

  getAllCountries() {
    const url = './CountriesAPISessionSafe/GetAllCountries';
    return this.httpService.safeGet({url});
  }

  getAllCountriesNotTakingIntoAccountRoles() {
    const url = './CountriesAPISessionSafe/GetALLCountriesNotTakingIntoAccountRoles';
    return this.httpService.safeGet({url});
  }

  getCountryRegions(countryShortId: string, subStudyId?: number) {
    const url: string = `./CountriesAPISessionSafe/GetCountryRegions?countryShortId=${countryShortId}${subStudyId
      ? `&subStudyId=${subStudyId}` : ''}`;
    return this.httpService.safeGet({url});
  }

  getSelectedCountries(addedCountriesAndRegions: string[], countryEquivalent: string[], parentCountryShortId: string) {
    const request = {
      url: './CountriesAPISessionSafe/GetSelectedCountries',
      body: {
        countryAndRegionShortIds: addedCountriesAndRegions,
        countryEquivalent: countryEquivalent,
        parentCountryShortId: parentCountryShortId
      }
    };
    return this.httpService.safePost(request);
  }

  saveCountries(list: Array<any>,
    deletedCountries: number[],
    newCountries: string[],
    isEditingFlag: boolean,
    parentCountryShortId: string,
    regionShortIdCollection: string[],
    jobId?: string): Observable<any[]> {
    const request = {
      url: './CountriesAPI/SaveCountries',
      body: {
        list: list,
        deletedCountries: deletedCountries,
        newcountries: newCountries,
        isEditingFlag: isEditingFlag,
        parentCountryShortId: parentCountryShortId,
        regionShortIdCollection: regionShortIdCollection,
        jobId
      }
    };
    return this.httpService.safePost<any[]>(request);
  }

  updateSessionSubStudyId(subStudyId?: number): Observable<any[]> {
    const url: string = `./CountriesAPI/UpdateSessionSubStudyId${subStudyId
      ? `?subStudyId=${subStudyId}` : ''}`;
    return this.httpService.safePost<any[]>({ url, body: {subStudyId} });
  }

  getSubStudySelected() {
    const url = './CountriesAPI/GetSubStudySelected';
    return this.httpService.safeGet({ url });
  }
}
