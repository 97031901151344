import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { StudyLevelDataDeliveryService } from './study-level-data-delivery.service';
import { SelectedStudyLevel } from '../../models/study-level/selected-study-level.model';
import { CurrencyType } from '../../models/study-level/currency-type.enum';

@Injectable({
    providedIn: 'root'
})
export class SelectedStudyLevelService {

    private _isEditingChange$: Observable<boolean>;
    private _isEditingSubject: BehaviorSubject<boolean>;
    private _selectedStudyLevel: SelectedStudyLevel;
    private _selectedStudyLevelChange$: Observable<SelectedStudyLevel>;
    private _selectedStudyLevelSubject: BehaviorSubject<SelectedStudyLevel>;

    get isEditingChange$(): Observable<boolean> {
        return this._isEditingChange$;
    }

    get selectedStudyLevel(): SelectedStudyLevel {
        return this._selectedStudyLevel;
    }

    get selectedStudyLevelChange$(): Observable<SelectedStudyLevel> {
        return this._selectedStudyLevelChange$;
    }

    constructor(private deliveryService: StudyLevelDataDeliveryService) {

        this._selectedStudyLevel = {
            Country: null,
            Currency: 0,
            CurrencyCode: null,
            DataAvailable: false,
            Region: null,
            SubStudy: 1
        };

        this._isEditingSubject = new BehaviorSubject<boolean>(false);
        this._isEditingChange$ = this._isEditingSubject.asObservable();

        this._selectedStudyLevelSubject = new BehaviorSubject<SelectedStudyLevel>(this._selectedStudyLevel);
        this._selectedStudyLevelChange$ = this._selectedStudyLevelSubject.asObservable();

        this.setUpInitialStudyLevel();
    }

    public castCountry(newCountryCode: string): void {
        this._selectedStudyLevel.Country = newCountryCode;
        this.deliveryService.switchCountry(newCountryCode)
            .subscribe(newStudyLevel => {
                this._selectedStudyLevel.Currency = newStudyLevel.Currency;
                this._selectedStudyLevel.CurrencyCode = newStudyLevel.CurrencyCode;
                this._selectedStudyLevel.Region = newStudyLevel.Region;
                this._selectedStudyLevel.SubStudy = newStudyLevel.SubStudy;
                this.emitSelectedStudyLevel();
            });
    }

    public castCurrency(newCurrencyType: CurrencyType, newCurrencyCode: string): void {
        this._selectedStudyLevel.Currency = newCurrencyType;
        this._selectedStudyLevel.CurrencyCode = newCurrencyCode;
        this.deliveryService.switchCurrency(newCurrencyType, newCurrencyCode)
            .subscribe(result => {
                this.emitSelectedStudyLevel();
            });
    }

    public castRegion(newRegionCode: string): void {
        this._selectedStudyLevel.Region = newRegionCode;
        this.deliveryService.switchRegion(this._selectedStudyLevel.Country, newRegionCode)
            .subscribe(result => {
                this.emitSelectedStudyLevel();
            });
    }

    public castSubStudy(newSubStudyId: number): void {
        this._selectedStudyLevel.SubStudy = newSubStudyId;
        this.deliveryService.switchSubstudy(newSubStudyId)
            .subscribe(result => {
                this.emitSelectedStudyLevel();
            });
    }

    public switchEditMode(isEditing: boolean): void {
        this._isEditingSubject.next(isEditing);
    }

    private emitSelectedStudyLevel(): void {
        this._selectedStudyLevelSubject.next(this._selectedStudyLevel);
    }

    private setUpInitialStudyLevel(): void {
        this.deliveryService.initialStudyLevelChange$
            .subscribe(data => {
                this._selectedStudyLevel.Country = (data.Country) ? data.Country : this._selectedStudyLevel.Country;
                this._selectedStudyLevel.Currency = (data.Currency) ? data.Currency : this._selectedStudyLevel.Currency;
                this._selectedStudyLevel.CurrencyCode = (data.CurrencyCode) ? data.CurrencyCode : this._selectedStudyLevel.CurrencyCode;
                this._selectedStudyLevel.DataAvailable = data.DataAvailable;
                this._selectedStudyLevel.Region = (data.Region) ? data.Region : this._selectedStudyLevel.Region;
                this._selectedStudyLevel.SubStudy = (data.SubStudy) ? data.SubStudy : this._selectedStudyLevel.SubStudy;

                this.emitSelectedStudyLevel();
            });
    }
}