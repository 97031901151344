import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Permission } from 'projects/shared/src/app/constants/Permission';
import { Resource } from 'projects/shared/src/app/constants/Resource';
import { ResourcePermissionValidator } from 'projects/shared/src/app/services/resource-permission-validator/resource-permission-validator.service';
import { LoggedUser } from '../../../models/user/user/logged-user.model';


@Injectable({
	providedIn: 'root'
})
export class LoggedUserService {

	private _loggedUser$: Observable<LoggedUser>;

	constructor(private resourcePermission: ResourcePermissionValidator) {

		const userId = parseInt((<any>window).headerNavbarData.SessionData.ContactId);

		this._loggedUser$ = combineLatest([
			this.resourcePermission.hasPermissionAsync(Resource.user, Permission.create),
			this.resourcePermission.isAdmin()
		]).pipe(
			map(([canManageUsers, isAdmin]) => {
				return new LoggedUser(canManageUsers, isAdmin, userId);
			})
		);
	}

	public getLoggedUser(context: any): void {
		context.loggedUser = new LoggedUser(false, false, 0);
		this._loggedUser$.subscribe(user => context.loggedUser = user);
	}
}
