import { Injectable } from '@angular/core';
import { EventBusService } from 'projects/shared/src/app/common/general/services/event-bus.service'; 
import { Event, EventType } from 'projects/shared/src/app/common/general/models/events';

@Injectable({
    providedIn: 'root'
})

export class UnsavedChangesNotifierStateService {

  constructor(private eventBus: EventBusService) { }

  notify (value: boolean): void {
    this.eventBus.emit(new Event(EventType.UnsavedChanges, value));
  }
}
