import { Component } from '@angular/core';

@Component({
  selector: 'gp-ui-app-root-shared',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'shared';
}
